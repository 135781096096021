import { Container, TextField, Button, ListItem, List } from "@mui/material"
import { Fade } from "react-reveal"
import { BackToTools } from "../pagecomponents/BackToTools"
import { ADMINAPI, ThemeC } from "../../constants/appbase"
import { useContext, useState } from "react"
import { DataP } from "../../contexts/DataProv"
import { MessageC } from "../../contexts/MessageContext"


export const PhonePage = () => {
    const { AppColors } = useContext(ThemeC)
    const msgC = useContext(MessageC)

    const [ loading, setLoading ] = useState(false)
    const [ number, setNumber ] = useState("")
    const [ result, setResult ] = useState({})
    
    const onChange = (e) => setNumber(e.target.value)
    const checkEnter = (event) => {
        if (event.key === 'Enter') {
            lookupNumber()
        }
    }

    const dataP = useContext(DataP)
    const lookupNumber = () => {
        setLoading(true)
        try {
            var cleanNumber = number.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
            // Add US Country Code
            if (cleanNumber.length === 10) {
                cleanNumber = "1" + cleanNumber;
            }

            dataP.RunPermissionedAPI(ADMINAPI, "/tools/phone", {number: cleanNumber}).then(res => {
                if (typeof res.data !== 'object') {
                    setLoading(false)
                    msgC.showMessage(`${res.data}`, "#f00")
                    return 
                }
                setResult(res.data)
                setLoading(false)
                msgC.showMessage(`Successfully Search Phone Number`, "#0f0")
            })
        }catch (err) {
            console.log(err)
            setLoading(false)
            msgC.showMessage(`${err}`, "#f00")
        }
    }

    return (
        <div>
            <Fade>
            <Container id="htmlImageCont" style={{paddingBottom: '200px', color: AppColors.SecondaryTextColor }}>
                <BackToTools />
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                    <h2 className="lightBottomBord" style={{marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>Phone Number Lookup</h2>
                    <div style={{minHeight: '80px'}}>
                        { loading && (
                            <div className="lds-ripple"><div></div><div></div></div>
                        )}
                    </div>
                </div>

                <div style={{padding: '10px', textAlign: 'center'}}>
                    Lookup Information on phone number provided with Country code
                </div>

                <div
                    style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',

                    }}>
                    <TextField 
                        onKeyDown={checkEnter}
                        rows={1}
                        label="Phone Number" 
                        style={{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                            border: `1px solid ${AppColors.DefaultBorderColor}`
                            
                        }}
                        InputLabelProps={{               
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        inputProps={{
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        value={number}
                        placeholder="12103004444"
                        onChange={onChange}
                        fullWidth
                    />
                    <div style={{padding: '10px'}}>
                        <Button variant="contained" onClick={lookupNumber}>Lookup</Button>
                    </div>
                </div>

                <div style={{
                    padding: '10px',
                    marginTop: '50px',
                    borderRadius: '10px',
                    backgroundColor: AppColors.DefaultBackgroundColor
                }}>
                    { /**
                    Result: 
                    <pre style={{
                        maxWidth: '100%'
                    }}>{ JSON.stringify(result, null, 2) }</pre>
                    */}
                    { Object.keys(result).length !== 0 ? (
                        <List>
                            { Object.keys(result).map(item => (
                                <ListItem key={item} style={{
                                    fontSize: '0.9rem',
                                    borderBottom: `1px solid ${AppColors.DefaultBorderColor}`
                                }}><span style={{
                                    color: AppColors.ButtonSuccessColor
                                }}>{ item }:</span>&nbsp;{result[item]}</ListItem>
                            ))}
                        </List>
                    ) : (
                        <>Lookup a phone number to continue...</>
                    )}
                </div>
            </Container>
            </Fade>
        </div>
    )
}