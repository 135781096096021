import { useContext, useEffect, useState } from 'react'
import { AccountSettings } from '@aws-amplify/ui-react'
import { Alert, Container } from '@mui/material'
import { Fade } from 'react-reveal'
import { ThemeC } from '../constants/appbase'
import { DataP } from '../contexts/DataProv'

export const ProfileSubpage = () => {
    const [ changedStatus, setChanged ] = useState(false)
    const [ userInfo, setUserInfo ] = useState(undefined)
    const { AppColors } = useContext(ThemeC)
    const handleSuccess = () => {
        setChanged(true)
    }

    // new
    const dataP = useContext(DataP)

    useEffect(() => {
        setUserInfo(dataP.state.user)
    }, [dataP.state.user])


    return (
        <Fade>
            <Container style={{textAlign: 'center'}}>
                <h2 style={{color: AppColors.SecondaryTextColor}}>Profile</h2>
                { userInfo === undefined ? (
                    <>Loading</>
                ): (
                    <div>
                        <div style={{padding: '10px', marginBottom: '30px', color: AppColors.SecondaryTextColor}}>
                        { userInfo.attributes.email }
                        </div>
                        <div style={{
                            color: "#000",
                            backgroundColor: "#fff", 
                            maxWidth: '400px', 
                            padding: '10px', 
                            marginLeft: 'auto', 
                            marginRight: 'auto', 
                            borderRadius: '10px',
                            boxShadow: `0 0 3px 4px ${AppColors.SecondaryBorderColor}`, marginBottom: '100px'}}>
                            <AccountSettings.ChangePassword onSuccess={handleSuccess}/>

                            { changedStatus ? (
                                <Alert style={{marginTop: '20px'}} severity='success'>Successfully Changed Password</Alert>
                            ) : null}
                        </div>
                    </div>
                )}
            </Container>
        </Fade>
    )
}