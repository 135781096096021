import { DeleteForever } from "@mui/icons-material"
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, List, ListItem, ListItemText, TextField, Tooltip } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"
import { ThemeC } from "../../constants/appbase"
import { AdminContext } from "../../contexts/AdminProv"

const MandatoryGroups = [
    "Admin",
    "Tools",
    "ToolsAI",
    "ToolsO",
    "Logs"
]

const IsMandatoryGroup = (group) => {
    const res = MandatoryGroups.filter(item => item === group)
    if (res.length > 0) {
        return true 
    }
    return false 
}

export const AdminGroupsManage = () => {
    const [ allGroups, setAllGroups ] = useState([])
    const [ forceR, setForceR ] = useState(false)

    const forceRefresh = () => setForceR(!forceR)
    const { AppColors } = useContext(ThemeC)

    // get list of domains and display
    const adminP = useRef(useContext(AdminContext))

    useEffect(() => {
        const ListAllGroups = async () => {
            return await adminP.current.AdminListGroups()
        }
        ListAllGroups().then(res => {
            setAllGroups(res)
        })
    }, [forceR])

    return (
        <div style={{marginTop: '20px', color: AppColors.DefaultTextColor}}>
            <Container>
                <div style={{
                    borderBottom: '1px solid #333', 
                    padding: '10px', 
                    paddingTop: '20px', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                }}>
                    <div style={{fontSize: '1.4rem', fontWeight: 'bold'}}>Groups</div>
                    <AddGroup forceRefresh={forceRefresh} />
                </div>
                <div>
                    { /* Get Admin Domain List and groups tied */}
                    <List>
                        { allGroups.map((item, i) => (
                            <Fade key={i}>
                            <ListItem
                                style={{borderBottom: '1px solid #222'}}
                                secondaryAction={
                                    <ConfirmDialog item={item} forceRefresh={forceRefresh} />
                                }>
                                <ListItemText>{ item.GroupName }</ListItemText>
                            </ListItem>
                            </Fade>
                        ))}
                    </List>
                </div>
            </Container>
        </div>
    )
}

const ConfirmDialog = ({item, forceRefresh}) => {
    const [ confirmOpen, setConfirmOpen ] = useState(false)
    const handleConfirmClose = () => setConfirmOpen(false)
    const handleConfirmOpen = () => setConfirmOpen(true)

    // confirmed delete group
    const msgC = useContext(MessageC)
    const adminP = useContext(AdminContext)
    const handleDeletion = async () => {
        await adminP.AdminDeleteGroup(item.GroupName)
        msgC.showMessage(`Successfully deleted group: ${item.GroupName}`, "#0f0")
        forceRefresh()
        handleConfirmClose()
    }

    return (
        <div>
            <IconButton onClick={handleConfirmOpen} disabled={IsMandatoryGroup(item.GroupName)}>
                <Tooltip title="Delete Group">
                <DeleteForever style={{color: IsMandatoryGroup(item.GroupName) ? "#333":'#f00'}} />
                </Tooltip>
            </IconButton>
            <Dialog 
                onClose={handleConfirmClose}
                open={confirmOpen}>
                <DialogTitle>Confirm Delete Group: {item.GroupName}</DialogTitle>
                <DialogContent>
                    Verify the group is no longer in use prior to deletion
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} variant="outlined">Close</Button>
                    <Button onClick={handleDeletion} variant="contained" color="error" disabled={IsMandatoryGroup(item.GroupName)}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const AddGroup = ({forceRefresh}) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => {setOpen(false)}
    const handleOpen = () => {setOpen(true)}

    const [ groupName, setGroupName ] = useState('')
    const handleChangeGroup = (e) => setGroupName(e.target.value)

    // admin add domain call 
    const adminP = useContext(AdminContext)
    const msgC = useContext(MessageC)
    const addGroup = async () => {
        await adminP.AdminAddGroup(groupName)
        msgC.showMessage(`Successfully created group: ${groupName}`, "#0f0")
        setGroupName("")
        forceRefresh()

        // close after 
        handleClose()
    }

    return (
        <div>
            <div>
                <Button variant="contained" onClick={handleOpen} color="success">Add</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Add Group</DialogTitle>
                <DialogContent>
                    <div style={{margin: '10px'}}>
                        <FormHelperText style={{margin: '10px'}}>
                            Add a group to the application.  The group can then be tied to a user, and a domain can be added to that group for privacy purposes.
                        </FormHelperText>
                        <TextField 
                            fullWidth
                            value={groupName}
                            onChange={handleChangeGroup}
                            variant="filled"
                            label="Group Name" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <Button variant="contained" color="success" onClick={addGroup}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}