import { Button, Container, TextField } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { Fade } from "react-reveal";
import { BackToTools } from "../pagecomponents/BackToTools";
import Markdown from "react-markdown";
import { ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";

export const ChatGPTPage = () => {
    const [ chatGPTResponse, setChatGPTResponse ] = useState('')
    const [ chatGPTQuestion, setChatGPTQuestion ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ oldPrompts, setOldPrompts ] = useState([])

    const { AppColors } = useContext(ThemeC)
    
    /* AI START */
    const [ ai, setai ] = useState()
    const dataP = useContext(DataP)
    useEffect(() => {
        setai(dataP.state.openAI)
    }, [dataP.state.openAI])
    /* AI END */

    // result ref
    const resultRef = useRef();
    useEffect(() => {
        resultRef.current = chatGPTResponse;
    }, [chatGPTResponse]);


    const createChatCompletion = async () => {
        setLoading(true)
        const stream = await ai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [{"role": "user", "content": chatGPTQuestion}],
            stream: true,
        });

        var allParts = []
        for await (const part of stream) {
            allParts = [...allParts, part.choices[0]?.delta?.content]
            setChatGPTResponse(allParts.join(""))

            if (part.choices[0]?.finish_reason === "stop") {
                // last item 
                const res = allParts.join("")
                setOldPrompts([{prompt: chatGPTQuestion, response: res}, ...oldPrompts])
                setChatGPTResponse(res)
                setChatGPTQuestion("")
            }

        }
        setLoading(false)

    }

    const onChangePrompt = (e) => {
        setChatGPTQuestion(e.target.value)
    }

    return (
        <div>
            <Fade>
            <Container id="htmlImageCont" style={{paddingBottom: '200px', color: AppColors.SecondaryTextColor }}>
                <BackToTools />

                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                    <h2 className="lightBottomBord" style={{marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>ChatGPT</h2>
                    <div style={{minHeight: '80px'}}>
                        { loading && (
                            <div className="lds-ripple"><div></div><div></div></div>
                        )}
                    </div>
                </div>

                <div style={{border: `1px solid #333`}}>
                    <div style={{minHeight: '300px', padding: '10px', overflowY: 'scroll', backgroundColor: AppColors.DefaultBackgroundColor}}>
                        <div className="chatGptResponse">
                            <Markdown>{ chatGPTResponse }</Markdown>
                        </div>
                    </div>
                    <div style={{display: 'flex', paddingTop: '15px', backgroundColor: AppColors.DefaultBackgroundColor}}>
                        <TextField multiline 
                            inputProps={{
                                style: {backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor},
                            }} 
                            style={{backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor}}
                            InputLabelProps={{style: {color: AppColors.DefaultTextColor}}}
                            fullWidth label="Enter prompt here."
                            onChange={onChangePrompt} 
                            value={chatGPTQuestion} />
                        <Button 
                            variant="contained" onClick={() => {createChatCompletion()}}>Enter</Button>
                    </div>
                </div>
            </Container>
            </Fade>
        </div>
    )
}