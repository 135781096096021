import { Button, Container } from "@mui/material"
import { Fade } from "react-reveal";
import { BackToTools } from "../pagecomponents/BackToTools";
import { useContext, useEffect, useState } from "react";
//import { MicOutlined } from "@mui/icons-material";
import { ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";


export const AudioPage = () => {
    const [ file, setFile ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ transcription, setTranscription ] = useState('')

    /* AI START */
    const [ ai, setai ] = useState()
    const { AppColors } = useContext(ThemeC)
    const dataP = useContext(DataP)
    useEffect(() => {
        setai(dataP.state.openAI)
    }, [dataP.state.openAI])
    /* AI END */

    const handleChange = (e) => {
        setFile(e.target.files[0])
    }

    // transcribe audio
    const transcribeAudio = () => {
        // check if file 
        if (file === "") {
            console.log("No File")
        } else {
            // transcribe 
            console.log("transcribe")
            setLoading(true)
            ai.audio.transcriptions.create({model: "whisper-1", file: file}).then(res => {
                setTranscription(res.text)
                setLoading(false)
            })
        }
    }

    const translateAudio = () => {
        if (file === '') {
            console.log("No File")
        } else {
            // translate to english 
            console.log("translate")
            setLoading(true)
            ai.audio.translations.create({model: "whisper-1", file: file}).then(res => {
                setTranscription(res.text)
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <Fade>
            <Container id="htmlImageCont" style={{paddingBottom: '200px', color: AppColors.SecondaryTextColor}}>
                <BackToTools />

                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                    <h2 
                    style={{
                        borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`,
                        marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>AI Audio</h2>
                    <div style={{minHeight: '80px'}}>
                        { loading && (
                            <div className="lds-ripple"><div></div><div></div></div>
                        )}
                    </div>
                </div>
                {/**
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button disabled variant="contained" style={{margin: '10px', backgroundColor: AppColors.ButtonSuccessColor, color: '#fff'}} size="small" startIcon={<MicOutlined />}>
                        Live
                    </Button>
                </div>
                */}
                <div style={{padding: '20px', textAlign: 'center'}}>
                    Select and audio file to transcribe, or translate.
                </div>

                <div>
                    <input 
                        style={{
                            width: '100%', padding: '10px', 
                            color: AppColors.DefaultTextColor,
                            backgroundColor: AppColors.DefaultBackgroundColor, borderRadius: '5px', boxShadow: `0 0 2px 2px ${AppColors.DefaultBoxShadowColor}`}}
                        accept="audio/*" 
                        id="raised-button-file" 
                        type="file" 
                        onChange={handleChange}
                    /> 
                </div>

                <div style={{padding: '15px'}}>
                    <Button onClick={transcribeAudio} variant="contained" style={{margin: '10px'}} size="small">
                        Transcribe
                    </Button>
                    <Button onClick={translateAudio} variant="contained" style={{margin: '10px'}} size="small">
                        Translate
                    </Button>
                </div>

                <div style={{
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor, 
                    minHeight: '250px', 
                    borderRadius: '10px', 
                    padding: '20px', 
                    marginTop: '20px',
                    boxShadow: '0 0 2px 2px #6663'
                    }}>
                    { transcription }
                </div>
            </Container>
            </Fade>
        </div>
    )
}