import { Button, Container, Grid, MenuItem, Select, Slider, Switch, TextField } from '@mui/material';
import { useContext, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { SketchPicker } from 'react-color'
import { Fade } from 'react-reveal';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { BackToTools } from '../pagecomponents/BackToTools';
import { APPMOBILEPATH, APPNAME, ThemeC } from '../../constants/appbase';
import { QrPresets } from './QRPresets';


export const QRGeneratorPage = () => {
    const [ qrStyleSet, setQrStyle ] = useState("dots")
    const [ qrValue, setQrValue ] = useState("")
    const [ qrBgColor, setQrBgColor ] = useState("#000");
    const [ qrFgColor, setQrFgColor ] = useState("#FFF");
    const [ eyeRound, setEyeRound ] = useState(5)
    const [ errorLevel, setErrorLevel ] = useState("L")
    const [ logoImage, setLogoImage ] = useState("")
    const [ logoWidth, setLogoWidth ] = useState(200);
    const [ logoHeight, setLogoHeight ] = useState(50);
    const [ logoOpacity, setLogoOpacity ] = useState(0.7);
    const [ eyeColor, setEyeColor ] = useState("#0f0")
    const [ qrSize, setQRSize ] = useState(320)
    const [ removeQR, setRemoveQr ] = useState(false)
    const [ qrPadding, setQrPadding ] = useState(10)
    const [ filename, setFilename ] = useState('')
    const [ logoStyle, setLogoStyle ] = useState("square")
    const [ logoPadding, setLogoPadding ] = useState(0)

    const { AppColors } = useContext(ThemeC)

    const setPreset = (presetSettings) => {
        setQrStyle(presetSettings.dots)
        setQrBgColor(presetSettings.colors.background)
        setQrFgColor(presetSettings.colors.foreground)
        setEyeColor(presetSettings.colors.eyeColor)
        setEyeRound(presetSettings.qrEyeRounding)
        setErrorLevel(presetSettings.error)
        setLogoHeight(presetSettings.logo.height)
        setLogoWidth(presetSettings.logo.width)
        setLogoOpacity(presetSettings.logo.opacity)
        setQRSize(presetSettings.qrSize)
        setRemoveQr(presetSettings.logo.removeQr)
        setLogoStyle(presetSettings.logo.style)
        setLogoPadding(presetSettings.logo.padding)
        setQrPadding(presetSettings.qrPadding)
        setQrValue(presetSettings.testURL)
        setLogoImage(presetSettings.logo.imageData)
    }

    const changeRemoveQr = () => {
        setRemoveQr(!removeQR)
    }

    const changeQrStyle = () => {
        switch (qrStyleSet) {
            case "dots":
                setQrStyle("squares")
                break
            case "squares":
                setQrStyle("dots")
                break
            default:
                console.log("incorrect style")
        }
    }

    const uploadInputRef = useRef();
    const handleLogoChange = (evt) => {
        try {
            URL.revokeObjectURL(evt.target.files[0])
            setLogoImage(URL.createObjectURL(evt.target.files[0]))
        } catch(e) {
            console.log(e)
        }
    }

    const resetSettings = () => {
        setQrValue("")
        setQrBgColor("#000")
        setQrFgColor("#FFF")
        setEyeRound(5)
        setErrorLevel("L")
        setLogoImage("")
        setEyeColor("#0f0")
        setRemoveQr(false)
        setQRSize(320)
        setQrPadding(10)
        setLogoPadding(0)
        setLogoStyle("square")
        uploadInputRef.current.value = "";
    }

    // Save | Download image
    function downloadImage(data, filename) {
        var a = document.createElement('a');
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    const saveImage = () => {
        var canvas = document.getElementById("react-qrcode-logo"); 
        var image = canvas.toDataURL("image/png", 1);  // here is the most important part because if you dont replace you will get a DOM 18 exception.
    

        const writeQrImage = async (filename, imageD) => {
            if (!filename.endsWith(".png")) {
                filename = filename + ".png"
            }
            await Filesystem.writeFile({
                path: `${APPMOBILEPATH}/${filename}`,
                data: imageD,
                directory: Directory.Documents,
            });
            const genId = Math.random() * 100
                const noti = {
                    title: APPNAME,
                    body: `QR Located in Documents/${APPMOBILEPATH}`,
                    id: genId,
                    largeIcon: "icon",
                    smallIcon: "icon_small",
                }
                LocalNotifications.schedule({
                    notifications: [noti],
                })
        }

        let fn = filename
        if (fn === '') {
            fn = "qrlink.png"
        }
        downloadImage(image, fn); // it will save locally
        if (Capacitor.isNativePlatform()) {
            writeQrImage(fn, image)
        }
      
    }

    return (
        <div style={{paddingBottom: '150px', color: AppColors.SecondaryTextColor}}>
            <Fade>
            <Container>

            <BackToTools />

            <div>
                <h1 style={{textAlign: 'center'}}>QR Code Generator</h1>
            </div>

            <div>
                { /** PRESETS */}
                <h2>Presets</h2>
                <p>You may need to adjust "Logo Padding" after selecting a preset to align with the URL needed.</p>
                <div style={{display: 'flex', padding: '10px'}}>
                    <Button variant='contained' style={{backgroundColor: "#00c85399", margin: "10px"}} onClick={() => setPreset(QrPresets.cashapp)}>Cash App</Button>
                    <Button variant='contained' style={{margin: "10px"}} onClick={() => setPreset(QrPresets.venmo)}>Venmo</Button>
                    <Button variant='contained' style={{backgroundColor: "#6c1cd3", margin: "10px"}} onClick={() => setPreset(QrPresets.zelle)}>Zelle</Button>
                    <Button variant='contained' style={{backgroundColor: "#042b8a", margin: "10px"}} onClick={() => setPreset(QrPresets.paypal)}>Paypal</Button>
                </div> 
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px'}}>
                <Button 
                    color="warning"
                    variant="contained"
                    style={{width: '100px'}} onClick={() => resetSettings()}>Reset</Button>
            </div>
            <div style={{paddingBottom: '40px', display: 'flex', flexDirection: 'column'}}>
                <div>
                    <div>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <div style={{padding: '10px'}}>URL to Generate QR</div>
                                <TextField 
                                    style={{
                                        backgroundColor: AppColors.DefaultBackgroundColor,
                                        color: AppColors.DefaultTextColor,
                                        border: `1px solid ${AppColors.DefaultBorderColor}`
                                        
                                    }}
                                    InputLabelProps={{               
                                        style:{
                                            backgroundColor: AppColors.DefaultBackgroundColor,
                                            color: AppColors.DefaultTextColor,
                                        }
                                    }}
                                    inputProps={{
                                        style:{
                                            backgroundColor: AppColors.DefaultBackgroundColor,
                                            color: AppColors.DefaultTextColor,
                                        }
                                    }}
                                    fullWidth value={qrValue} 
                                    onChange={(e) => {setQrValue(e.target.value)}} 
                                    label="URL" 
                                    variant="filled" className='qrText' />
                            </Grid>
                            <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                <div>
                                    <div style={{padding: '10px'}}>QR Style</div>
                                    <Button onClick={() => changeQrStyle()} variant='contained' className='qrButton'>Change { qrStyleSet }</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                <div style={{padding: '10px'}}>Error Correction Level</div>
                                <Select onChange={(e) => setErrorLevel(e.target.value)} defaultValue={"L"} 
                                    style={{width: '250px', color: '#000', backgroundColor: '#FFF', marginBottom: '20px'}}>
                                    <MenuItem value="L">L</MenuItem>
                                    <MenuItem value="M">M</MenuItem>
                                    <MenuItem value="Q">Q</MenuItem>
                                    <MenuItem value="H">H</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>


                        <Grid container spacing={5} padding={2}>
                            <Grid item xs={12} md={4}>
                                <div style={{padding: '10px'}}>QR Size</div>
                                <Slider defaultValue={256} min={256} step={32} max={512} value={qrSize} onChange={(e) => setQRSize(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div style={{padding: '10px'}}>QR Padding</div>
                                <Slider defaultValue={10} min={10} step={10} max={100} value={qrPadding} onChange={(e) => setQrPadding(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div style={{padding: '10px'}}>Eye Rounding</div>
                                <Slider defaultValue={5} min={0} max={50} value={eyeRound} onChange={(e) => setEyeRound(e.target.value)} />
                            </Grid>
                        </Grid>

                        <div>
        
                        </div>
                        <div style={{borderTop: '1px solid #ccc'}}>
                            <div style={{padding: '10px'}}>Logo Settings</div>
                            <Grid container style={{marginBottom: '30px'}} spacing={2}>
                                <Grid item xs={12} md={4} style={{textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className='qrButton'
                                        >
                                        Upload Logo Image
                                        <input
                                            ref={uploadInputRef}
                                            type="file"
                                            hidden
                                            onChange={handleLogoChange}
                                            multiple={false}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} style={{textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <div style={{padding: '10px'}}>Remove QR Behind Logo (Test QR After)</div>
                                    <Switch value={removeQR} onChange={() => changeRemoveQr()} />
                                </Grid>
                                <Grid item xs={12} md={4} style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <div style={{padding: '10px'}}>Logo Padding Style</div>
                                    <Select onChange={(e) => setLogoStyle(e.target.value)} value={logoStyle}
                                        style={{width: '250px', color: '#000', backgroundColor: '#FFF', marginBottom: '20px'}}>
                                        <MenuItem value="square">Square</MenuItem>
                                        <MenuItem value="circle">Circle</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <TextField 
                                        style={{
                                            backgroundColor: AppColors.DefaultBackgroundColor,
                                            color: AppColors.DefaultTextColor,
                                            border: `1px solid ${AppColors.DefaultBorderColor}`
                                            
                                        }}
                                        InputLabelProps={{               
                                            style:{
                                                backgroundColor: AppColors.DefaultBackgroundColor,
                                                color: AppColors.DefaultTextColor,
                                            }
                                        }}
                                        inputProps={{
                                            style:{
                                                backgroundColor: AppColors.DefaultBackgroundColor,
                                                color: AppColors.DefaultTextColor,
                                            }
                                        }}
                                        value={logoWidth} onChange={(e) => {setLogoWidth(e.target.value)}} 
                                        label="Logo Image Width" variant="filled" className='qrText' type='number' />
                                </Grid>
                                <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                    <TextField 
                                        style={{
                                            backgroundColor: AppColors.DefaultBackgroundColor,
                                            color: AppColors.DefaultTextColor,
                                            border: `1px solid ${AppColors.DefaultBorderColor}`
                                            
                                        }}
                                        InputLabelProps={{               
                                            style:{
                                                backgroundColor: AppColors.DefaultBackgroundColor,
                                                color: AppColors.DefaultTextColor,
                                            }
                                        }}
                                        inputProps={{
                                            style:{
                                                backgroundColor: AppColors.DefaultBackgroundColor,
                                                color: AppColors.DefaultTextColor,
                                            }
                                        }}
                                        value={logoHeight} onChange={(e) => {setLogoHeight(e.target.value)}} 
                                        label="Logo Image Height" variant="filled" className='qrText' type='number'  />
                                </Grid>
                                <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <div style={{padding: '10px'}}>Logo Padding</div>
                                    <Slider defaultValue={10} min={0} max={100} step={1} value={logoPadding} onChange={(e) => setLogoPadding(e.target.value)} />
                                </Grid>
         
                                <Grid item xs={12} md={3} style={{textAlign: 'center', marginTop: '20px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <div style={{padding: '10px'}}>Logo Opacity</div>
                                    <Slider defaultValue={0.7} min={0} max={1} step={0.05} value={logoOpacity} onChange={(e) => setLogoOpacity(e.target.value)} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div style={{borderTop: '1px solid #ccc', padding: '10px'}}>
                        <div>Color Settings</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                        <div>
                            <h3>Foreground Color</h3>
                            <SketchPicker color={qrFgColor} onChangeComplete={(color) => setQrFgColor(color.hex) } />
                        </div>
                        <div>
                            <h3>Background Color</h3>
                            <SketchPicker color={qrBgColor} onChangeComplete={(color) => setQrBgColor(color.hex) } />
                        </div>
                        <div>
                            <h3>Eye Color</h3>
                            <SketchPicker color={eyeColor} onChangeComplete={(color) => setEyeColor(color.hex) } />
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{marginTop: '50px', marginBottom: '50px'}} />

            <h3>QR Code</h3>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div id="qrCodeCont">
                    <QRCode 
                        value={qrValue}
                        size={qrSize}
                        qrStyle={qrStyleSet}
                        eyeRadius={eyeRound}
                        eyeColor={eyeColor}
                        bgColor={qrBgColor} 
                        fgColor={qrFgColor} 
                        ecLevel={errorLevel}
                        removeQrCodeBehindLogo={removeQR}
                        logoImage={logoImage}
                        logoWidth={logoWidth}
                        logoHeight={logoHeight}
                        logoOpacity={logoOpacity}
                        logoPadding={logoPadding}
                        logoPaddingStyle={logoStyle}
                        quietZone={qrPadding}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '20px', flexDirection: 'column', alignItems: 'center'}}>
                <TextField 
                    style={{
                        backgroundColor: AppColors.DefaultBackgroundColor,
                        color: AppColors.DefaultTextColor,
                        border: `1px solid ${AppColors.DefaultBorderColor}`,
                        maxWidth: '250px',
                    }}
                    InputLabelProps={{               
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                    inputProps={{
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                    value={filename} onChange={(e) => {setFilename(e.target.value)}} 
                    label="Image File Name" variant="filled" className='qrText' />
                <Button className='qrButton' onClick={() => saveImage()} style={{maxWidth: '150px', marginTop: '20px'}} variant='contained'>Save QR Image</Button>
            </div>
            </Container>
            </Fade>
        </div>
    )
}

