import { Button, Container, TextField } from "@mui/material"
import { Fade } from "react-reveal";
import { BackToTools } from "../pagecomponents/BackToTools";
import { useContext, useEffect, useState } from "react";
import { ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";

export const TranslationPage = () => {
    const [ loading, setLoading ] = useState(false)
    const [ text, setText ] = useState('')
    const [ resp, setChatGPTResponse ] = useState('')

    /* AI START */
    const [ ai, setai ] = useState()
    const { AppColors } = useContext(ThemeC)
    const dataP = useContext(DataP)
    useEffect(() => {
        setai(dataP.state.openAI)
    }, [dataP.state.openAI])
    /* AI END */

    const handleChangeText = (e) => setText(e.target.value)
    const translateText = async () => {
        setLoading(true)
        const translateText = `Detect the source language and translate the following text to 'English': ${text}`
        const stream = await ai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [{"role": "user", "content": translateText}],
            stream: true,
        });
        var allParts = []
        for await (const part of stream) {
            allParts = [...allParts, part.choices[0]?.delta?.content]
            setChatGPTResponse(allParts.join(""))
            if (part.choices[0]?.finish_reason === "stop") {
                // last item 
                const res = allParts.join("")
                setChatGPTResponse(res)
            }
        }
        setLoading(false)
    }

    return (
        <div>
            <Fade>
            <Container id="htmlImageCont" style={{paddingBottom: '200px', color: AppColors.SecondaryTextColor }}>
                <BackToTools />
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                    <h2 className="lightBottomBord" style={{marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>Translation</h2>
                    <div style={{minHeight: '80px'}}>
                        { loading && (
                            <div className="lds-ripple"><div></div><div></div></div>
                        )}
                    </div>
                </div>

                <div style={{padding: '10px', textAlign: 'center'}}>
                    Detects the language & translates any text into English.
                </div>

                <div>
                    <TextField 
                        value={text} onChange={handleChangeText}
                        multiline rows={5} fullWidth 
                        style={{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                            border: `1px solid ${AppColors.DefaultBorderColor}`
                            
                        }}
                        InputLabelProps={{               
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        inputProps={{
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}/>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '20px'}}>
                        <Button onClick={translateText} variant="contained">Translate</Button>
                    </div>
                </div>

                <div style={{
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor, 
                    minHeight: '250px', 
                    borderRadius: '10px', 
                    padding: '20px', 
                    marginTop: '20px',
                    boxShadow: `0 0 2px 2px ${AppColors.DefaultBoxShadowColor}`
                    }}>
                    { resp }
                </div>
            </Container>
            </Fade>
        </div>
    )
}