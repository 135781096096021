import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Dashboard } from './pages/Dashboard';
import { API, Amplify, Auth, AuthModeStrategyType } from 'aws-amplify';

// aws configuration
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import { components, formFields } from './components/authComponents';
import { ProjectPage } from './pages/Project';
import { ResponsiveAppBar } from './components/Navbar';
import { MsgProv } from './contexts/MessageContext';
import { FooterBar } from './components/Footer';
import { ProfileSubpage } from './pages/Profile';
import { ToolsPage } from './pages/Tools';
import { IPLookupPage } from './pages/tools/IPLookup';
import { ResultPage } from './pages/Results';
import { ExportProv } from './components/generateCSV';
import { QRGeneratorPage } from './pages/tools/QRGenerator';
import { useEffect } from 'react';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Camera } from '@capacitor/camera';
import { LocalNotifications } from '@capacitor/local-notifications';
import { ChatGPTPage } from './pages/tools/ChatGPT';
import { PasswordCheckPage } from './pages/tools/Password';
import { PortScannerPage } from './pages/tools/PortScanner';
import { AudioPage } from './pages/tools/AiAudio';
import { TranslationPage } from './pages/tools/Translations';
import { ContentPolicyPage } from './pages/tools/Sentiment';
import { AdminUserPage } from './pages/admin/AdminUser';
import { AdminGroupsManage } from './pages/admin/AdminGroups';
import { APPMOBILEPATH, CustomThemeProvider } from './constants/appbase';
import { AdminInfraPage } from './pages/admin/AdminInfra';
import { AdminLogsPage } from './pages/admin/AdminLogs';
import { AdminImagesPage } from './pages/admin/AdminImages';
import { DomainLookupPage } from './pages/tools/DomainLookup';
import { OverviewPage } from './pages/Overview';
import { NewDP } from './contexts/DataProv';
import { AdminProv } from './contexts/AdminProv';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PhonePage } from './pages/tools/Phone';

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

API.configure(awsExports)
Auth.configure(awsExports)

function App() {
  useEffect(() => {
    // request permissions for storage if not 
    const requestPermissions = async () => {
      if (Capacitor.isNativePlatform()) {
        // get all permissions needed
        await Filesystem.requestPermissions()
        await Camera.requestPermissions()
        await LocalNotifications.requestPermissions()

        // make report directory
        await Filesystem.mkdir({
          path: APPMOBILEPATH,
          directory: Directory.Documents,
        })
      }
    }
    requestPermissions()
  })


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomThemeProvider>
      <div>
      <BrowserRouter>
          <Authenticator 
          components={components}
          formFields={formFields}
          hideSignUp>
          {({user, signOut}) => (
              <MsgProv>
                <ExportProv>
                  <NewDP user={user}> {/* Entirely New Provider for all data providers below */}
                    <ResponsiveAppBar signOut={signOut} user={user} />
                    <div style={{minHeight: '100vh'}}>
                    <Routes>
                      { /* Base Routes Below */}
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/profile" element={<ProfileSubpage />} />
                      <Route path="/projects/:id" element={<ProjectPage />} />
                      <Route path="/result/:dropId/:id" element={<ResultPage />} />

                      { /* Tools Below  */}
                      <Route path="/tools" element={<ToolsPage />} />
                      <Route path="/tools/qr" element={<QRGeneratorPage />} />
                      <Route path="/tools/ip" element={<IPLookupPage />} />
                      <Route path="/tools/domain" element={<DomainLookupPage />} />
                      <Route path="/tools/chatgpt" element={<ChatGPTPage />} />
                      <Route path="/tools/aiaudio" element={<AudioPage />} />
                      <Route path="/tools/content" element={<ContentPolicyPage />} />
                      <Route path="/tools/translate" element={<TranslationPage />} />
                      <Route path="/tools/password" element={<PasswordCheckPage />} />
                      <Route path="/tools/port" element={<PortScannerPage />} />
                      <Route path="/tools/phone" element={<PhonePage />} />

                      { /* Admin Below */}
                      <Route path="/admin" element={<AdminProv><AdminUserPage /></AdminProv>} />
                      <Route path="/admingroups" element={<AdminProv><AdminGroupsManage /></AdminProv>} />
                      <Route path="/admininfra" element={<AdminProv><AdminInfraPage /></AdminProv>} />
                      <Route path="/adminimages" element={<AdminProv><AdminImagesPage /></AdminProv>} />
                      <Route path="/adminlogs" element={<AdminProv><AdminLogsPage /></AdminProv>} />

                      { /* Tool Overview Page */}
                      <Route path="/tooloverview" element={<OverviewPage />} />
                    </Routes>
                    </div>
                    <FooterBar />
                  </NewDP> { /* Data Provider End */}
                </ExportProv>
              </MsgProv>
          )}
          </Authenticator>
        </BrowserRouter>
      </div>
      </CustomThemeProvider>
      </LocalizationProvider>
    );
}

export default App;
