import { Button,   Container,   Dialog, DialogActions, DialogContent, DialogTitle,  IconButton, TextField, Tooltip } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DeleteForever, DownloadOutlined, Settings } from "@mui/icons-material"
import { MessageC } from "../contexts/MessageContext"
import { CatchDrops } from "./pagecomponents/CatchDrops"
import { CatchHits } from "./pagecomponents/CatchHits"
import { Fade } from "react-reveal"
import { EmailNotis } from "./pagecomponents/EmailNotis"
import { ThemeC } from "../constants/appbase"
import { DataP } from "../contexts/DataProv"

export const ProjectPage = () => {
    const routerParams = useParams()

    const dataP = useRef(useContext(DataP))
    useEffect(() => {
        dataP.current.changeActiveProject(routerParams.id)
    }, [routerParams.id ])

    return (
        <DataP.Consumer>
            { ({activeProjectId, state }) => {
                const curActive = state.projects.filter(item => item.id === activeProjectId)[0];
                return (
                    <div style={{overflow: 'hidden'}}>
                    { state.projects.length === 0 ? (
                        <Container>
                            <div style={{minHeight: '200px', textAlign: 'center'}}></div>
                        </Container>
                    ) : (
                        <>
                            <ProjectBox activeProject={curActive} />
                        </>
                    )}
                    </div>
                    )
                }}
        </DataP.Consumer>
    )
}

const ProjectBox = ({activeProject}) => {
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    // project report
    const dataP = useContext(DataP)
    const genProjReport = () => {
        dataP.generateProjectReport().then(() => {
            msgC.showMessage("Saved Project Report", "#0f0", true)
        })
    }

    // ignore errors (no rendering if no project)
    if (activeProject === undefined) {
        return <></>
    }

    return (
        <div>
            <div style={{zIndex: '1', display: 'flex', justifyContent: 'space-between', 
                color: AppColors.DefaultTextColor,
                backgroundColor: AppColors.DefaultBackgroundColor, 
                padding: '10px', 
                paddingTop: '30px',
                boxShadow: `0 5px 3px 2px ${AppColors.DefaultBoxShadowColor}`}}>
                <div style={{display: 'flex', justifyContent: 'space-around', flexGrow: '1'}}>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{fontSize: '1.3rem', fontWeight: 'bold'}}>{activeProject.project_name}</div>
                        </div>
                        
                        <p>Description: { activeProject.project_description }</p>
                        { /* Download Project Report */ }
                        <div style={{display: 'flex'}}> 
                            <Tooltip title="Download Results in CSV">                                        
                                <Button variant="outlined" style={{color: AppColors.DefaultTextColor}} onClick={genProjReport}>
                                    <DownloadOutlined style={{marginRight: '10px', color: AppColors.DefaultTextColor}} />
                                    Report
                                    </Button>
                            </Tooltip>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                        <EditProj activeProject={activeProject} />
                        <DeleteProj activeProject={activeProject} />
                    </div>
                </div>
            </div>

            <Fade>
                <div style={{minHeight: '500px', paddingTop: '20px'}}>
                    { /* Notication Emails */}
                    <EmailNotis activeProject={activeProject} />

                    { /* Catch Drops  */}
                    <CatchDrops activeProject={activeProject} />

                    { /* Catch Hits */}
                    <CatchHits activeProject={activeProject} />
                </div>
            </Fade>
        </div>
    )
}


const EditProj = ({activeProject}) => {
    const [ open, setOpen ] = useState(false)
    const [ title, setTitle ] = useState(activeProject.project_name)
    const [ description, setDescription ] = useState(activeProject.project_description)
    const { AppColors } = useContext(ThemeC)

    // on update
    useEffect(() => {
        setTitle(activeProject.project_name)
        setDescription(activeProject.project_description)
    }, [activeProject])

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleTitleChange = (e) => setTitle(e.target.value)
    const handleDescChange = (e) => setDescription(e.target.value)

    // new 
    const newData = useContext(DataP)
    const msgC = useContext(MessageC)
    const handleUpdateProj = async () => {

        await newData.updateProject(activeProject.id, title, description)
        msgC.showMessage("Updated Project", "#0f0")
        handleClose()
    }

    return (
        <div style={{margin: '5px', display: 'flex', alignItems: 'center'}}>
            <Tooltip title="Edit Project">
                <IconButton style={{border: `1px solid ${AppColors.DefaultBorderColor}`}} onClick={handleOpen}>
                    <Settings style={{color: AppColors.DefaultTextColor}} />
                </IconButton>
            </Tooltip>
            <Dialog
                onClose={handleClose}
                open={open}>
                <DialogTitle>Edit {activeProject.project_name}</DialogTitle>
                <DialogContent>
                    <div style={{padding: '10px'}}>
                        <TextField value={title} fullWidth label="Title" onChange={handleTitleChange} />
                    </div>
                    <div style={{padding: '10px'}}>
                        <TextField value={description} fullWidth label="Description" onChange={handleDescChange} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                    <Button color="success" variant="contained" onClick={handleUpdateProj}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const DeleteProj = ({activeProject}) => {
    const [ open, setOpen ] = useState(false)

    // new 
    const newData = useContext(DataP)

    const nav = useNavigate();
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)
    const handleDelete = () => {
        msgC.showMessage("Deleting Project... Please wait", "#ff0")
        newData.deleteProject(activeProject.id).then(() => {
            msgC.showMessage("Deleted Project Successfully.", "#f00")
            nav("/")
        })
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Tooltip title="Delete Project">
                <IconButton onClick={() => {setOpen(true)}} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                    <DeleteForever style={{color: '#f00'}} />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={() => {setOpen(false)}}>
                <DialogTitle>Delete Project {activeProject.project_name}</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete project {activeProject.project_name}?
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant="outlined" color="error" onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}