import { Button, Chip, Container, TextField } from "@mui/material"
import { Fade } from "react-reveal";
import { BackToTools } from "../pagecomponents/BackToTools";
import { useContext, useEffect, useState } from "react";
import { ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";


export const ContentPolicyPage = () => {
    const [ loading, setLoading ] = useState(false)
    const [ inputText, setInputText ] = useState('')
    const [ results, setResults ] = useState()

    /* AI START */
    const [ ai, setai ] = useState()

    const onChange = (e) => setInputText(e.target.value)
    const { AppColors } = useContext(ThemeC)
    const dataP = useContext(DataP)
    useEffect(() => {
        setai(dataP.state.openAI)
    }, [dataP.state.openAI])
    /* AI END */

    const runSentiment = async () => {
        setLoading(true)
        ai.moderations.create({model: "text-moderation-latest", input: inputText}).then(res => {
            if (res.results.length > 0) {
                setResults(res.results[0])
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (results === undefined) return
    }, [results])

    const RenderIcon = (isEnabled) => {
        return (
            <>
                { isEnabled ? (
                    <Chip label="True" color="error" style={{minWidth: '75px'}} />
                ) : (
                    <Chip label="False" color="success" style={{minWidth: '75px'}} />
                )}
            </>
        )
    }

    return (
        <div>
            <Fade>
            <Container id="htmlImageCont" style={{paddingBottom: '200px', color: AppColors.SecondaryTextColor }}>
                <BackToTools />
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                    <h2 className="lightBottomBord" style={{marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>Content Policy</h2>
                    <div style={{minHeight: '80px'}}>
                        { loading && (
                            <div className="lds-ripple"><div></div><div></div></div>
                        )}
                    </div>
                </div>

                <div style={{padding: '10px', textAlign: 'center'}}>
                    Given a input text, outputs if the model classifies it as violating OpenAI's content policy.
                </div>

                <TextField 
                    multiline
                    rows={5}
                    label="Input Text" 
                    style={{
                        backgroundColor: AppColors.DefaultBackgroundColor,
                        color: AppColors.DefaultTextColor,
                        border: `1px solid ${AppColors.DefaultBorderColor}`
                        
                    }}
                    InputLabelProps={{               
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                    inputProps={{
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                    value={inputText}
                    onChange={onChange}
                    fullWidth
                />
                <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '20px'}}>
                    <Button variant="contained" onClick={runSentiment}>Determine</Button>
                </div>

                { /* Results Panel */}
                <div style={{
                    marginTop: '20px'}}>
                { results !== undefined && results.categories !== undefined && (
                    <div style={{padding: '10px', borderRadius: '5px', backgroundColor: AppColors.DefaultBackgroundColor, boxShadow: `0 0 2px 2px ${AppColors.DefaultBoxShadowColor}`}}>
                        { allCats.map((item, i) => (
                                <div
                                    key={`item-${i}`} 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        borderBottom: '1px solid #333', 
                                        margin: '5px',
                                        padding: '5px',
                                    }}>
                                    <div>
                                        <b>Score:</b> {Math.round(results.category_scores[item]*100)}
                                    </div>
                                    <div>
                                        <span style={{textTransform: 'capitalize'}}>{ item }</span>
                                    </div>
                                    <div>
                                        { RenderIcon(results.categories[item]) }
                                    </div>
                                </div>
                        ))}
                    </div>
                )}
                </div>

            </Container>
            </Fade>
        </div>
    )
}

const allCats = [
    "sexual",
    "hate",
    "harassment",
    "self-harm",
    "self-harm/intent",
    "self-harm/instructions",
    "sexual/minors",
    "hate/threatening",
    "violence",
    "violence/graphic",
]