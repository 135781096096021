import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import { DataStore } from 'aws-amplify';
import { List, ListItemButton, ListSubheader, SwipeableDrawer } from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';
import { APPNAME,  ThemeC } from '../constants/appbase';
import { DataP } from '../contexts/DataProv';


export const ResponsiveAppBar = ({ user, signOut }) => {
    const { AppColors, RenderColorButton } = useContext(ThemeC)
    const [anchorElUser, setAnchorElUser] = useState(null);
    //const [ extraTopPadding, setExtraTopPadding ] = useState("0px")
    
    //const [ localEmail, setLocalEmail ] = useState('')
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSignOut = async () => {
        //DataStore.clear()
        signOut()
    }

    useEffect(() => {
        // reload if no user identified
        if (user.attributes === undefined) {
            window.location.reload(true);
            return
        }
    }, [user])

    /*
    useEffect(() => {
        if (Capacitor.getPlatform() === "ios") {
            setExtraTopPadding("25px")
        }
    }, [])
    */

    const dataP = useContext(DataP)
    const consistentBorder = `1px solid ${AppColors.SecondaryBorderColor}`;

    return (
        <AppBar position="sticky" style={{
            backgroundColor: AppColors.DefaultBackgroundColor, 
            color: AppColors.DefaultTextColor,
            boxShadow: `0 2px 2px 3px ${AppColors.DefaultBoxShadowColor}`,    
        }}>

        { /**
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
            fontSize: '0.7rem',
            backgroundColor: '#00801f',
            textAlign: 'center',
            color: '#000',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: '1px',
            borderBottom: '1px solid #aaa',
            //marginBottom: extraTopPadding,
        }}>
            CUI//REL to USA, FVEY
        </div>
        */}

        { /* LE BANNER 
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
            fontSize: '0.7rem',
            backgroundColor: '#0004ff',
            textAlign: 'center',
            color: '#fff',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: '1px',
            borderBottom: '1px solid #aaa'
        }}>
            SBU - SENSITIVE BUT UNCLASSIFIED
        </div>
        */}


        { /* localEmail !== undefined && (
            <div style={{textAlign: 'center', padding: '5px', fontSize: '0.7rem'}}>{ localEmail }</div>    
        ) */}

        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <Link to="/" className='destyle'>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                    { APPNAME }
                </Typography>
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <Link to="/" className='destyle'>
                <IconButton
                size="large"
                aria-label="home"
                aria-controls="menu-home"
                color="inherit"
                sx={{ p: 1,border: consistentBorder }}
                >
                <HomeOutlined   />  
                </IconButton>
                </Link>
            </Box>

            <Typography
                variant="h5"
                noWrap
                component="div"
                href="/"
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
            <Link to="/" className='destyle'>
                { APPNAME }
            </Link>
            </Typography>

            { /* Desktop Menu Top */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Link to="/" className='destyle buttonMargin'>
                    <Button
                        className='buttonSpace'
                        key={'dashboard'}
                        sx={{ my: 2, color: AppColors.DefaultTextColor, display: 'block', border: consistentBorder }}
                    >
                        Dashboard
                    </Button>
                </Link>
                <Link to="/tools" className='destyle buttonMargin'>
                    <Button
                        className='buttonSpace'
                        key={'tools'}
                        sx={{ my: 2, color: AppColors.DefaultTextColor, display: 'block', border: consistentBorder }}
                    >
                        Tools
                    </Button>
                </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open Menu">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt={ APPNAME } src={process.env.PUBLIC_URL + AppColors.AppIcon} style={{border: consistentBorder}} />
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer
                    PaperProps={{
                        style: {
                            backgroundColor: AppColors.SecondaryBackgroundColor,
                            color: AppColors.DefaultTextColor,
                            boxShadow: `0 0 2px 3px ${AppColors.SecondaryBoxShadowColor}`,
                        }
                    }}
                    anchor='right'
                    open={Boolean(anchorElUser)}
                    onOpen={handleOpenUserMenu}
                    onClose={handleCloseUserMenu}>
                    <Box
                        style={{minWidth:'250px'}}>
                        <List>
                            <ListSubheader component={"div"} style={{
                                backgroundColor: AppColors.SecondaryBackgroundColor,
                                color: AppColors.SecondaryLightText,
                                marginTop: '25px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img alt="" src={process.env.PUBLIC_URL + AppColors.AppIcon} style={{maxWidth: '35px'}} />
                                    <div>{ APPNAME }</div>
                                </div>
                            </ListSubheader>
                            <Link to="/" className='destyle' onClick={() => {handleCloseUserMenu(); }} >
                                <ListItemButton style={{borderBottom: consistentBorder, borderTop: consistentBorder}}>
                                    <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Dashboard</Typography>
                                </ListItemButton>
                            </Link>
                            <Link to="/tools" className='destyle' onClick={() => {handleCloseUserMenu(); }} >
                                <ListItemButton style={{borderBottom: consistentBorder}}>
                                    <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Tools</Typography>
                                </ListItemButton>
                            </Link>

                            { /* Render Entire Project List */}
                            { dataP.RenderProjectList(handleCloseUserMenu) }

                            <ListSubheader component={"div"} style={{
                                backgroundColor: AppColors.SecondaryBackgroundColor,
                                color: AppColors.SecondaryLightText,
                                marginTop: '25px',
                                textAlign: 'right'
                            }}>SETTINGS</ListSubheader>

                            <Link to="/profile" className='destyle' onClick={() => {handleCloseUserMenu(); }} >
                                <ListItemButton style={{borderBottom: consistentBorder, borderTop: consistentBorder}}>
                                    <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Profile</Typography>
                                </ListItemButton>
                            </Link>
                            <Link to="/tooloverview" className='destyle' onClick={() => {handleCloseUserMenu(); }} >
                                <ListItemButton style={{borderBottom: consistentBorder, borderTop: consistentBorder}}>
                                    <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Overview</Typography>
                                </ListItemButton>
                            </Link>

                            { /* If Admin Show */}
                            <dataP.RenderIfInGroup group="Logs">
                                <ListSubheader component={"div"} style={{
                                        backgroundColor: AppColors.SecondaryBackgroundColor,
                                        color: AppColors.SecondaryLightText,
                                        marginTop: '25px',
                                        textAlign: 'right'
                                    }}>LOGGING</ListSubheader>
                                <Link to="/adminlogs" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Logs</Typography>
                                    </ListItemButton>
                                </Link>
                            </dataP.RenderIfInGroup>
                            <dataP.RenderIfInGroup group="Admin">
                                <ListSubheader component={"div"} style={{
                                        backgroundColor: AppColors.SecondaryBackgroundColor,
                                        color: AppColors.SecondaryLightText,
                                        marginTop: '25px',
                                        textAlign: 'right'
                                    }}>MANAGEMENT</ListSubheader>
                                <Link to="/admin" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Users</Typography>
                                    </ListItemButton>
                                </Link>
                                <Link to="/admingroups" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Groups</Typography>
                                    </ListItemButton>
                                </Link>
                                { /**
                                <Link to="/admindomains" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Domains</Typography>
                                    </ListItemButton>
                                </Link>
                                 */}
                                <Link to="/adminimages" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Images</Typography>
                                    </ListItemButton>
                                </Link>
                                <Link to="/admininfra" className='destyle' onClick={() => {handleCloseUserMenu(); }}>
                                    <ListItemButton style={{borderBottom: consistentBorder}}>
                                        <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Infrastructure</Typography>
                                    </ListItemButton>
                                </Link>
                            </dataP.RenderIfInGroup>

                            <ListItemButton onClick={() => {handleCloseUserMenu(); handleSignOut(); }} style={{marginTop:'35px'}}>
                                <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>Logout</Typography>
                            </ListItemButton>
                        </List>
                        { RenderColorButton() }
                    </Box>
                </SwipeableDrawer>
            </Box>
            </Toolbar>
        </Container>
        </AppBar>
    );
}