import { Container, Grid, Tooltip } from "@mui/material"
import { useContext } from "react"
import { Fade } from "react-reveal"
import { Link } from "react-router-dom"
import { MessageC } from "../contexts/MessageContext"
import { ThemeC } from "../constants/appbase"
import { DataP } from "../contexts/DataProv"

export const ToolsPage = () => {

    // new 
    const dataP = useContext(DataP)
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    const generateFullCSV = () => {
        dataP.generateFullProjectsReport().then(() => {
            msgC.showMessage("Generated CSV Report", "#0f0", true)
        })
    }

    return (
        <div>
            <Fade>
                <Container>
                    <h2 style={{
                        color: AppColors.SecondaryTextColor,
                        borderBottom: `1px solid ${AppColors.DefaultBorderColor}`}}>Tools</h2>
                    <Grid container spacing={2} padding={2} style={{justifyContent: 'center'}}>
                        { AllTools.map(item => (
                            <dataP.RenderIfInGroup group={item.group} key={item.title}>
                                <Grid item xs={12} md={4}>
                                    <Link to={item.link} className="destyle">
                                    <Tooltip title={item.tooltip}>
                                        <div className={"toolsGrid " + item.class} style={{
                                            backgroundColor: AppColors.DefaultBackgroundColor, 
                                            color: AppColors.DefaultTextColor,
                                        }}>
                                            { item.title }
                                        </div>
                                    </Tooltip>
                                    </Link>
                                </Grid>
                            </dataP.RenderIfInGroup>
                        ))}

                        { /* Export Results */}
                        <Grid item xs={12} md={4}>
                            <Tooltip title="Export all project results in CSV">
                                <div className="toolsGrid" onClick={generateFullCSV} style={{
                                    color: AppColors.DefaultTextColor,
                                    backgroundColor: AppColors.DefaultBackgroundColor}}>
                                    Export Results
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    </Container>
            </Fade>
        </div>
    )
}


const AllTools = [
    {title: "IP Lookup", tooltip: "Lookup information based on IP", link: "/tools/ip", class: "toolBox", group: "Tools"},
    {title: "Domain Lookup", tooltip: "Lookup information based on Domain", link: "/tools/domain", class: "toolBox", group: "Tools"},
    {title: "Phone Lookup", tooltip: "Identify information on a Phone number", link: "/tools/phone", class: "toolBox", group: "Tools"},
    {title: "QR Code Generator", tooltip: "Generate a QR code for a URL", link: "/tools/qr", class: "toolBox", group: "Tools"},
    {title: "Port Scanner", tooltip: "Port Scanner", link: "/tools/port", class: "toolBoxP", group: "ToolsO"},
    {title: "Password Check", tooltip: "Password Leak Check", link: "/tools/password", class: "toolBoxP", group: "ToolsO"},
    {title: "ChatGPT", tooltip: "ChatGPT Helper", link: "/tools/chatgpt", class: "toolBoxY", group: "ToolsAI"},
    {title: "AI Audio", tooltip: "Transcribe, Translate Audio with AI", link: "/tools/aiaudio", class: "toolBoxY", group: "ToolsAI"},
    {title: "Translation", tooltip: "Translate Text with AI", link: "/tools/translate", class: "toolBoxY", group: "ToolsAI"},
    {title: "Content Policy", tooltip: "Identify the sentiment with AI", link: "/tools/content", class: "toolBoxY", group: "ToolsAI"},
]