import { Button, Container, IconButton, List, ListItem, ListItemText, TextField, Tooltip } from "@mui/material"
import { Fade } from "react-reveal"

import { createMap } from "maplibre-gl-js-amplify"; 
import "maplibre-gl/dist/maplibre-gl.css";
import { useContext, useEffect, useState } from "react";
import { MessageC } from "../../contexts/MessageContext";

import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import { Download } from "@mui/icons-material";
import { BackToTools } from "../pagecomponents/BackToTools";
import { ADMINAPI, ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";

async function initializeMap(lat,lng,acc) {
    const zoomCalc = ((acc / 35) * 35) + 7;
    const map = await createMap({
        container: "map", // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
        center: [lat, lng], // center in New York
        zoom: acc === null ? 1 : zoomCalc,
        preserveDrawingBuffer: true,
    })
    return map;
}

export const IPLookupPage = () => {
    const [ ipAddress, setIPAddress ] = useState('')
    const [ ipInfo, setIpInfo ] = useState(undefined)
    const { AppColors } = useContext(ThemeC)

    useEffect(() => {
        try {
            initializeMap(0,0,null)
        } catch {}
    }, [])

    const dataP = useContext(DataP)
    const msgC = useContext(MessageC)
    const queryIpInfo = () => {
        // test permissioned
        try {
            dataP.RunPermissionedAPI(ADMINAPI, "/tools/ipinfo", {ip: ipAddress}).then(res => {
                setIpInfo(res.data)
            })
        }catch (err) {
            msgC.showMessage(`${err}`, "#f00")
        }
    }

    // ip info to redo map 
    useEffect(() => {
        try {
            if (ipInfo === undefined) return 
            initializeMap(ipInfo.longitude,ipInfo.latitude,1)
        } catch{}
    }, [ipInfo])

    const saveData = () => {
        // get map 
        var node = document.getElementById('htmlImageCont');
        htmlToImage.toPng(node, {backgroundColor: '#000'})
            .then(function (dataUrl) {
                download(dataUrl, 'iplookup.png');
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    const onChange = (e) => {
        setIPAddress(e.target.value)
    }

    const onCheckKey = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            queryIpInfo()
        }
    }

    return (
        <div style={{paddingBottom: '150px', color: AppColors.SecondaryTextColor}}>
            <Fade>
                <Container id="htmlImageCont" style={{paddingBottom: '200px'}}>

                    <BackToTools />

                    <div style={{display: 'flex', justifyContent: 'center', alignItems:"center"}}>
                        <h2 className="lightBottomBord" style={{flexGrow: '1'}}>IP Lookup</h2>
                        <Tooltip title="Download Report">
                            <IconButton onClick={saveData} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                                <Download style={{color: AppColors.SecondaryTextColor}} />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div id="hideInstructions" style={{padding: '10px', textAlign: 'justify'}}>
                        Enter an IP address (ex. 8.8.8.8) in the box below and click "Search" to query for information regarding the IP address.  
                    </div>

                    <div style={{padding: '10px', alignItems: 'center', display: 'flex'}}>
                        <TextField 
                            label="IP Address" 
                            variant="filled" 

                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`
                                
                            }}
                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}

                            value={ipAddress}
                            onChange={onChange}
                            onKeyDown={onCheckKey}
                            fullWidth
                        />
                        <div style={{marginLeft: '10px'}}>
                            <Button variant="contained" onClick={() => {queryIpInfo()}}>Search</Button>
                        </div>
                    </div>

                    <div id="map" className="mapContainer"></div>

                    <div style={{marginTop: '50px'}}>
                        <h2 className="lightBottomBord">Results</h2>
                        { ipInfo !== undefined ? (
                            <div style={{backgroundColor: '#000', borderRadius: '10px', boxShadow: '0 0 2px 3px #333'}}>
                                <List>
                                    <ListItem>
                                        <ListItemText>IP: { ipInfo.ip }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Version: { ipInfo.version }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>City: { ipInfo.city }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Region: { ipInfo.region }</ListItemText>
                                    </ListItem>           
                                    <ListItem>
                                        <ListItemText>Country Name: { ipInfo.country_name }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Latitude: { ipInfo.latitude }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Longitude: { ipInfo.longitude }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>ASN: { ipInfo.asn }</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Org: { ipInfo.org }</ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        ) : (<>No Results</>)}
                    </div>

                </Container>
            </Fade>
        </div>
    )
}