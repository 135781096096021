import { Button,  Chip,  Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText,  Tooltip } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { ThemeC } from "../../constants/appbase"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"
import {  Cancel, CheckCircle, DeleteForever } from "@mui/icons-material"
import { AddDomain } from "./AddDomain"
import { AdminContext } from "../../contexts/AdminProv"

export const AdminInfraPage = () => {
    const [ allDomains, setAllDomains ] = useState([])
    const [ apiStatusus, setApiStatusus ] = useState('')

    // theming
    const { AppColors } = useContext(ThemeC)
    const gridStyle = {
        border: `1px solid ${AppColors.DefaultBoxShadowColor}`,
        padding: '15px',
    }

    // groups 
    const [ allGroups, setAllGroups ] = useState([])

    // admin api clean calls
    const msgC = useContext(MessageC)
    const adminP = useContext(AdminContext)

    // cleanup database permissioned
    const CleanupDb = async () => {
        const res = await adminP.AdminCleanDB()
        if (res.length !== 0) {
            msgC.showMessage(`Successfully Deleted ${res.length} items`, "#0f0")
        } else {
            msgC.showMessage(`No items capable of cleaning`, "#0f0")
        }
    }

    // cleanup s3 images permissioned
    const CleanupS3 = async () => {
        const res = await adminP.AdminCleanS3()
        if (res.length !== 0) {
            msgC.showMessage(`Successfully Deleted ${res.length} images`, "#0f0")
        } else {
            msgC.showMessage(`No images capable of cleaning`, "#0f0")
        }
    }

    // cleanup cloudwatch permissioned
    const CleanupCloudwatch = async () => {
        const res = await adminP.AdminCleanCloudWatch()
        const clearO = res["log-a"]
        const clearT = res["log-f"]
        const total = parseInt(clearO)+parseInt(clearT)
        if (total > 1) {
            msgC.showMessage(`Successfully Deleted ${total} logs`, "#0f0")
        } else {
            msgC.showMessage(`No logs to clear`, "#0f0")
        }
    }

    const UpdateAPIStatus = useRef(async () => {
        const res = await adminP.AdminCheckAPIStatus()
        setApiStatusus(res)
    })

    const UpdateAllDomains = useRef(async () => {
        const res = await adminP.AdminListDomains()
        setAllDomains(res)
    })

    const adminPRef = useRef(adminP)
    useEffect(() => {
        UpdateAPIStatus.current()
        UpdateAllDomains.current()
        const setNewGroups = async () => {
            const res = await adminPRef.current.AdminListGroups()
            setAllGroups(res)
        }
        setNewGroups()
    }, [])

    useEffect(() => {
        var downStatusFound = false;
        for(let i = 0; i < apiStatusus.length; i++) {
            if (apiStatusus[i].status !== "up") {
                downStatusFound = true 
            }
        }
        if (apiStatusus.length === 0 || downStatusFound) {
            setTimeout(() => {
                UpdateAPIStatus.current()
            }, 5000)
        }
    }, [apiStatusus])

    const isStatusUp = (d) => {
        for (let i = 0; i < apiStatusus.length; i++) {
            if (apiStatusus[i].domain === d) {
                if (apiStatusus[i].status === "up") {
                    return true 
                }
            }
        }
        return false 
    }

    return (
        <div style={{marginTop: '20px', color: AppColors.DefaultTextColor}}>
            <Fade>
            <Container>

                <div style={{
                    borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, 
                    padding: '10px', 
                    paddingTop: '20px', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                }}>
                    <div style={{fontSize: '1.4rem', fontWeight: 'bold'}}>Infrastructure</div>
                </div>

                <div style={{paddingTop: '20px'}}>
                    <h3>Database Cleanup</h3>
                    <Grid container padding={2} spacing={2}>
                        <Grid style={gridStyle} item xs={12} md={4}>
                            <h3>Clean Database</h3>
                            <p>Deletes all old records out of database</p>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <ConfirmCB 
                                    title="Clean Database" 
                                    content="Are you sure you want to delete the old records?"
                                    buttonTitle="Clean"
                                    confirmCb={CleanupDb} />
                            </div>
                        </Grid>
                        <Grid style={gridStyle} item xs={12} md={4}>
                            <h3>S3 Image Storage Clean</h3>
                            <p>Deletes unreferenced images off S3 Bucket</p>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <ConfirmCB 
                                    title="S3 Image Clean" 
                                    content="Deletes all unreferenced images in S3 storage bucket"
                                    buttonTitle="Clean"
                                    confirmCb={CleanupS3} />
                            </div>
                        </Grid>
                        <Grid style={gridStyle} item xs={12} md={4}>
                            <h3>Clean Cloudwatch Logs</h3>
                            <p>Deletes all logs related to Application</p>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <ConfirmCB 
                                    title="Clean Cloudwatch Logs" 
                                    content="Deletes all logs related to Application"
                                    buttonTitle="Clean"
                                    confirmCb={CleanupCloudwatch} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container padding={2} spacing={2}>
                        <Grid style={gridStyle} item xs={12} md={12}>

                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h3>All Domains</h3>
                                
                                { /* Add Domain Dialog */}
                                <AddDomain allGroups={allGroups} forceRefresh={() => UpdateAllDomains.current()} addIcon={true} />
                            </div>

                            <p>Check domain mapping within application</p>
                            <div>
                                <List
                                    style={{
                                        maxHeight: '500px',
                                        overflowY: 'scroll',
                                        backgroundColor: AppColors.DefaultBackgroundColor,
                                        color: AppColors.DefaultTextColor,
                                    }}>
                                    { allDomains.map((item, i) => (
                                        <ListItem key={i} 
                                            style={{
                                                display: 'flex', 
                                                flexGrow: '1',
                                                borderBottom: `1px solid ${AppColors.DefaultBorderColor}`
                                            }}>
                                            <ListItemText value={ item.String } style={{
                                                justifyContent: 'space-between'}}>
                                                <div style={{display: 'flex'}}>
                                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: '1'}}> 
                                                        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>

                                                            <div style={{marginRight: '10px', alignItems: 'center', display: 'flex'}}>
                                                                { isStatusUp(item.String) ? (
                                                                    <Tooltip title="API is Online">
                                                                        <CheckCircle style={{color: AppColors.ButtonSuccessColor}} />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip title="API is Offline">
                                                                        <Cancel style={{color: AppColors.ButtonDeleteColor}} />
                                                                    </Tooltip>
                                                                )}
                                                            </div> 

                                                            <div style={{marginRight: '10px'}}>
                                                                <Chip label={item.GroupPermission} style={{
                                                                    border: `1px solid ${AppColors.DefaultBorderColor}`,
                                                                    backgroundColor: AppColors.SecondaryBackgroundColor,
                                                                    color: AppColors.SecondaryTextColor}} />
                                                            </div>

                                                            <div>{ item.String }</div>
                                                        </div>

                                                        <ConfirmDeleteDomain item={item} callback={() => UpdateAllDomains.current()}/>
                                                    </div> 
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </Container>
            </Fade>
        </div>
    )
}

// Confirm Dialog Helper
export const ConfirmCB = ({title, content, confirmCb, buttonTitle, disabled = false }) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const { ButtonStyle } = useContext(ThemeC)

    const handleCb = () => {
        confirmCb()
        handleClose()
    }
    
    return (
        <div>
            <div>
                <Button 
                    disabled={disabled}
                    {...ButtonStyle} onClick={handleOpen} variant="contained">{buttonTitle}</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent>{ content }</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    <Button onClick={handleCb} variant="contained">{ buttonTitle} </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

const ConfirmDeleteDomain = ({item, callback}) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    const { AppColors } = useContext(ThemeC)
    // admin api clean calls
    const msgC = useContext(MessageC)

    const adminP = useContext(AdminContext)

    // delete domain from db
    const deleteDomain = async (item) => {
        try {
            await adminP.DeleteDomain(item.Id)
            msgC.showMessage("Successfully Deleted", "#0f0")
        } catch (e) {
            msgC.showMessage("Error Deleting Domain", "#f00")
        }
        
        callback()
        handleClose()
    }

    return (
        <div>
            <div>
                <Tooltip title="Delete Domain">
                    <IconButton onClick={handleOpen}>
                        <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    >
                    <DialogTitle>Delete {item.String}</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete the domain from the application?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                        <Button variant="contained" style={{backgroundColor: AppColors.ButtonDeleteColor}} onClick={() => deleteDomain(item)}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}