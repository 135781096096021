import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, Tooltip } from "@mui/material"
import { DataStore } from "aws-amplify"
import { useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { CatchHit } from "../models"
import { Fade } from "react-reveal"
import { Geo } from "aws-amplify"
import { createMap, drawPoints } from "maplibre-gl-js-amplify"; 
import "maplibre-gl/dist/maplibre-gl.css";
import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import { ChildCare, Download } from "@mui/icons-material";
import { Directory, Filesystem } from "@capacitor/filesystem"
import { Capacitor } from "@capacitor/core"
import { MessageC } from "../contexts/MessageContext"
import { ADMINAPI, APPMOBILEPATH, ThemeC } from "../constants/appbase"
import { DataP } from "../contexts/DataProv"

const initializeMap = async (lat, lng, acc) => {
    const zoomCalc = ((acc / 35) * 35) + 7;
    const map = await createMap({
        container: "map", // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
        center: [lat, lng], // center in New York
        zoom: acc !== null ? zoomCalc : 1,
        preserveDrawingBuffer: true,
    }).catch(() => {

    })
    return map;
}

export const ResultPage = () => {
    const [ currentResult, setResult ] = useState(undefined)
    const [ minorAlert, setMinorAlert ] = useState(false)
    const [ projectName, setProjectName ] = useState('')
    const [ dropName, setDropName ] = useState('')

    // image permissed
    const [ imageData, setImageData ] = useState(undefined)
    const { AppColors } = useContext(ThemeC)
    const params = useParams()

    const dataP = useContext(DataP)

    useEffect(() => {
        if (dataP.state.projects === undefined) return
        
        DataStore.query(CatchHit, params.id).then(res => {
            setResult(res)
            res.CatchDrop.then(res => {
                setMinorAlert(res.minor)
                setDropName(res.drop_name)

                const projF = dataP.state.projects.filter(pr => pr.id === res.projectID)
                if (projF.length > 0)  {
                    setProjectName(projF[0].project_name)
                }
            })
        })

        // top of result when clicked 
        window.scrollTo({left: 0, top: 0})
    }, [params.id, dataP.state.projects])

    const dataPRef = useRef(dataP)
    useEffect(() => {
        if (currentResult === undefined) return 

        // check if catch image
        if (currentResult.catch_image !== "") {
            dataPRef.current.RunPermissionedAPI(ADMINAPI, "/image/get", {url: currentResult.catch_image}).then(res => {       
                setImageData(`data:image/png;base64,${res.data}`)
            })
       
        }

        const drawMap = (mapCreated, lng, lat) => {
            // drop pin
            mapCreated.on("load", async function () {
                // reverse geo search for address
                const tarCoords = [lng, lat];
                const searchOptionsWithBiasPosition = {
                    maxResults: 1, // 50 is the max and the default
                    //searchIndexName: "string", // the string name of the search index
                }
                
                const res = await Geo.searchByCoordinates(tarCoords, searchOptionsWithBiasPosition);
                drawPoints("Target",
                    [
                        {
                        coordinates: tarCoords, 
                        title: "Target",
                        address: res.label,
                        },
                    ], // An array of coordinate data, an array of Feature data, or an array of [NamedLocations](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/types.ts#L8)
                    mapCreated,
                    {
                        showCluster: true,
                        unclusteredOptions: {
                            showMarkerPopup: true,
                        },
                        clusterOptions: {
                            showCount: false,
                        },
                    }
                );
            });
        }

        // get coords         
        var acc = "";
        var lat = "";
        var lng = "";
        for(let x = 0; x < currentResult.catch_info.length;x++) {
            const infoItem = currentResult.catch_info[x]
            // temp store lat & lng
            if (infoItem.includes("GEO Latitude") && !infoItem.includes('none')) {
                lat = parseFloat(infoItem.split(": ")[1])
            } else if (infoItem.includes("GEO Longitude") && !infoItem.includes('none')) {
                lng = parseFloat(infoItem.split(": ")[1])
            } else if (infoItem.includes("GEO Accuracy") && !infoItem.includes('none')) {
                acc = parseFloat(infoItem.split(": ")[1])
            }
        }

        if (acc !== "" && lat !== "" && lng !== "") {
            try {
                initializeMap(lng, lat, acc).then((mapCreated) => {
                    drawMap(mapCreated, lng, lat)
                })
            } catch{}
        } else {
            // lookup lat / lng based on city, region 
            const city = currentResult.catch_info[5]   // backend db
            const region = currentResult.catch_info[6] // backend db

            // ip based location 
            Geo.searchByText(`${city}, ${region}`).then(res => {
                if (res.length === 0) {
                    // no guesstimates
                    initializeMap(lng, lat, null)
                    return 
                } 
                try {
                    const bestGuess = res[0].geometry.point
                    initializeMap(bestGuess[0], bestGuess[1], 1)
                }catch{}
            })
        }
    }, [currentResult])

                

    const msgC = useContext(MessageC)
    const saveData = () => {
        // get map 
        var node = document.getElementById('htmlImageCont');
        htmlToImage.toPng(node, {backgroundColor: AppColors.DefaultBackgroundColor})
            .then(function (dataUrl) {
                const saveToPhotos = async (filename, dataToWrite) => {
                    await Filesystem.writeFile({
                        path: `${APPMOBILEPATH}/report_${filename}`,
                        data: dataToWrite,
                        directory: Directory.Documents
                    }).then(() => {
                        msgC.showMessage(`Saved Report to Documents/${APPMOBILEPATH}`, AppColors.ButtonSuccessColor, true)
                    })
                }

                const newFn = `${currentResult.catch_ip}-${currentResult.catch_time}.png`
                if (Capacitor.isNativePlatform()) {
                    // 
                    saveToPhotos(newFn, dataUrl)
                } else {
                    download(dataUrl, newFn);
                }
                //window.saveAs(dataUrl, 'my-node.png');
                //download(dataUrl, "dlTextBlob.png", "text/plain");
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }


    // blank while loading 
    if (currentResult === undefined) {
        return (
            <div></div>
        )
    }

    const itemTime = new Date(0);
    itemTime.setUTCSeconds(parseInt(currentResult.catch_time / 1000))

    return (
        <div style={{color: AppColors.SecondaryTextColor, paddingTop: '30px'}}>
            <Fade>
                <Container>
                    <div style={{padding: '10px', display: 'flex', justifyContent: 'space-between'}}>
                        <DeleteHitDialog currentResult={currentResult} />
                        <Tooltip title="Go back to the parent project">
                            <Link to={`/projects/${currentResult.projectID}`} className="destyle">
                                <Button variant="outlined" style={{color: AppColors.DefaultTextColor, backgroundColor: AppColors.DefaultBackgroundColor}}>Back to Project</Button>
                            </Link>
                        </Tooltip>
                    </div>
                </Container>
                <Container id="htmlImageCont" style={{paddingTop: '30px', paddingBottom: '100px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                        color: AppColors.SecondaryTextColor}}>
                        <div><b>Project:</b> { projectName }</div>
                        { dropName !== "" && (
                            <div><b>Drop:</b> { dropName }</div>
                        )}
                        
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems:"center"}}>
                        <h2 className="lightBottomBord" style={{flexGrow: '1'}}>Result {currentResult.catch_ip }</h2>
                        <IconButton onClick={() => saveData()} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                            <Download style={{color: AppColors.SecondaryTextColor}} />
                        </IconButton>
                    </div>

                    <div style={{position: 'relative', overflow: 'hidden'}}>
                        <div id="map" className="mapContainer"></div>
                    </div>

                    <div>
                        <List>
                            <ListItem style={{display: 'flex', justifyContent: 'center'}}>
                                { minorAlert && (
                                    <div>
                                        <Alert severity="warning" style={{justifyContent: 'center', alignItems: 'center'}}>
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <ChildCare /> 
                                                <div style={{padding: '5px'}}>This hit is related to a drop created with the "minor" option.</div>
                                            </div>
                                        </Alert>
                                    </div>
                                )}
                            </ListItem>
                            <ListItem>
                                <ListItemText><b>IP:</b> {currentResult.catch_ip}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText><b>Time Hit:</b> {itemTime.toUTCString()}</ListItemText>
                            </ListItem>
                            { /**
                            <ListItem>
                                <ListItemText><b>User Agent:</b> {currentResult.catch_user_agent}</ListItemText>
                            </ListItem>
                             */}
                        </List>

                        <List style={{padding: '20px', 
                            color: AppColors.DefaultTextColor,
                            backgroundColor: AppColors.DefaultBackgroundColor, borderRadius: '10px', 
                            boxShadow: `0 0 2px 2px ${AppColors.DefaultBoxShadowColor}`}}>
                            { currentResult.catch_info.map(item => {
                                const itemS = item.split(":")
                                return (
                                    <ListItem key={item} style={{borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, flexWrap:"wrap"}}>
                                        <ListItemText>{ itemS[0] }</ListItemText>
                                        <ListItemText>{ itemS[1] }</ListItemText>
                                    </ListItem>
                                )}
                            )}
                        </List>
                        
                        {  imageData !== undefined && (
                            <div style={{padding: '10px', textAlign: 'center'}}>
                                <div style={{paddingBottom: '20px'}}>
                                <Alert>Camera Permissions were granted during capture.</Alert>
                                </div> 
                                <img src={imageData} alt={`${APPMOBILEPATH}_image`} style={{display: 'flex', maxWidth: '100%'}} />
                            </div>
                        )}
                    </div>
                    
                </Container>
            </Fade>
        </div>
    )
}


const DeleteHitDialog = ({currentResult}) => {
    const [ open, setOpen ] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const { AppColors } = useContext(ThemeC)
    const nav = useNavigate()
    const deleteHit = async () => {
        // delete current result
        const projID = currentResult.projectID
        const original = await DataStore.query(CatchHit, currentResult.id);
        await DataStore.delete(original)

        // navigate back to project 
        nav(`/projects/${projID}`)
    }

    return (
        <div>
            <Tooltip title="Delete Hit">
                <Button variant="outlined" color="error" style={{color: AppColors.DefaultTextColor, backgroundColor: AppColors.DefaultBackgroundColor}}
                    onClick={handleOpen}>Delete</Button>
            </Tooltip>
            <Dialog
                onClose={handleClose}
                open={open}
                >
                <DialogTitle>
                    Delete Hit
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the hit information?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Cancel</Button>
                    <Button color="error" variant="contained"
                        onClick={deleteHit}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}