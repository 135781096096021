/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://bfwutgvliraivamgbx7ed3zame.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uivdrihl6rfoffixm675s62yoa",
    "aws_cloud_logic_custom": [
        {
            "name": "keyholeadmin",
            "endpoint": "https://tjcx0gjte0.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:f1f31584-6ed7-41d0-8004-a1323c3f61ef",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_6VeBmDvoy",
    "aws_user_pools_web_client_id": "7ope77ujhn3h7pbt4ro6uhosr7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "keyholerewrite-storage-e6fa0659-staging150419-main",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-2",
            "maps": {
                "items": {
                    "keyholerewritemap-main": {
                        "style": "HybridHereExploreSatellite"
                    }
                },
                "default": "keyholerewritemap-main"
            },
            "search_indices": {
                "items": [
                    "placesearchkeyholerewrite-main"
                ],
                "default": "placesearchkeyholerewrite-main"
            }
        }
    }
};


export default awsmobile;
