import { useContext, useRef, useState } from "react"
import { ThemeC } from "../../constants/appbase"
import { MessageC } from "../../contexts/MessageContext"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { AddCircle } from "@mui/icons-material"
import { DataP } from "../../contexts/DataProv"
import { AdminContext } from "../../contexts/AdminProv"

export const AddDomain = ({allGroups, forceRefresh, addIcon}) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => {setOpen(false)}
    const handleOpen = () => {setOpen(true)}

    const [ domainUrl, setDomainUrl ] = useState('')
    const [ domainGroup, setDomainGroup ] = useState('')
    const handleChangeDomainUrl = (e) => setDomainUrl(e.target.value)
    const handleChangeDomainGroup = (e) => setDomainGroup(e.target.value)

    // admin add domain call 
    const { AppColors } = useContext(ThemeC)

    const dataP = useRef(useContext(DataP))
    const adminP = useContext(AdminContext)
    const msgC = useContext(MessageC)
    const addDomain = async () => {
        console.log("Add Domain")

        try {
            await adminP.AddDomain(domainUrl, domainGroup)
            msgC.showMessage("Added Domain", "#0f0")
            dataP.current.UpdateDomains()
            setDomainGroup("")
            setDomainUrl("")
        } catch(e) {
            msgC.showMessage("Error Adding Domain", "#f00")
        }
        forceRefresh()
        handleClose()
    }

    return (
        <div>
            <div>
                { addIcon ? (
                    <IconButton onClick={handleOpen}>
                        <AddCircle style={{color: AppColors.ButtonSuccessColor}} />
                    </IconButton>
                ) : (
                    <Button variant="contained" onClick={handleOpen} color="success">Add</Button>
                )}
                
            </div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Add Domain</DialogTitle>
                <DialogContent>
                    <div>
                        <FormHelperText style={{textAlign: 'center'}}>Prior to adding the domain, please ensure the domain has been purchased and has the correct API mapping</FormHelperText>
                    </div>
                    <div style={{margin: '20px 10px'}}>
                        <TextField 
                            fullWidth
                            value={domainUrl}
                            onChange={handleChangeDomainUrl}
                            label="Domain Name" />
                        <FormHelperText style={{marginTop: '10px'}}>Domain name without "https://".  Suffix included for custom map.</FormHelperText>
                    </div>
                    <div style={{margin: '20px 10px'}}>
                        <FormControl fullWidth>
                            <InputLabel id="group-name-label">Group Name</InputLabel>
                            <Select
                                onChange={handleChangeDomainGroup}
                                value={domainGroup}
                                label="group-name-label"
                                fullWidth>
                                { allGroups.map(item => (
                                    <MenuItem key={item.GroupName} value={item.GroupName}>{ item.GroupName }</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText style={{marginTop: '10px'}}>The Group tied to the domain is utilized for permission purposes.</FormHelperText>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                    <Button variant="contained" color="success" onClick={addDomain}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}