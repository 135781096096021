import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton,  List,  ListItem,  ListItemText,  TextField,  Tooltip } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { ThemeC } from "../../constants/appbase"
import { CheckCircle, CircleNotifications, Download, ExpandMore, InfoRounded, NotInterested, RefreshOutlined, WarningOutlined } from "@mui/icons-material"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"

import { createMap } from "maplibre-gl-js-amplify"; 
import "maplibre-gl/dist/maplibre-gl.css";
import { ExportC } from "../../components/generateCSV"
import { AdminContext } from "../../contexts/AdminProv"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

async function initializeMap(lat,lng,acc) {
    const zoomCalc = ((acc / 35) * 35) + 7;
    const map = await createMap({
        container: "map", // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
        center: [lat, lng], // center in New York
        zoom: acc === null ? 1 : zoomCalc,
        preserveDrawingBuffer: true,
    })
    return map;
}

export const AdminLogsPage = () => {
    const [ apiEvents, setAPIEvents] = useState()
    const [ adminEvents, setAdminEvents ] = useState()

    const { AppColors } = useContext(ThemeC)

    //const [ hitClearDialogOpen, setHitClearDialogOpen ] = useState(false)
    //const [ adminDialogOpen, setAdminDialogOpen ] = useState(false)
    //const handleHitClearDialogOpen = () => setHitClearDialogOpen(true)
    //const handleHitClearDialogClose = () => setHitClearDialogOpen(false)
    //const handleAdminDialogOpen = () => setAdminDialogOpen(true)
    //const handleAdminDialogClose = () => setAdminDialogOpen(false)

    // date 
    const [ dateFilter, setDateFilter ] = useState(dayjs(new Date()))
    const [ adminNextToken, setAdminNextToken ] = useState(undefined)
    const [ functionNextToken, setFunctionNextToken ] = useState(undefined)
    
    const handleDateFilterChange = async (e) => {
        setDateFilter(e)
        setAdminNextToken(undefined)
        setFunctionNextToken(undefined)
        console.log("New Date Selected")
        UpdateLogs.current(functionFilter, adminFilter, e, undefined, undefined, hitsOnly)
    }

    // filter
    const [ functionFilter, setFunctionFilter ] = useState('')
    const handleChangeFunctionFilter = (e) => setFunctionFilter(e.target.value)
    const [ adminFilter, setAdminFilter ] = useState('')
    const handleChangeAdminFilter = (e) => setAdminFilter(e.target.value)

    // ip info dialog
    const [ ipInfoOpen, setIpInfoOpen ] = useState(false)
    const [ ipAddress, setIpAddress ] = useState('')
    const [ ipInfo, setIpInfo ] = useState(undefined)
    const handleIpInfoClose = () => {
        setIpInfoOpen(false)
        setTimeout(() => {
            // reset ip information on close
            setIpInfo(undefined)
        }, 500)
    }
    const handleIpInfoOpen = () => setIpInfoOpen(true)

    // hits only
    const [ hitsOnly, setHitsOnly ] = useState(false)
    const handleChangeHitsOnly = (e) => {
        UpdateLogs.current(functionFilter, adminFilter, dateFilter, undefined, undefined, !hitsOnly)
        setHitsOnly(!hitsOnly)
    }

    const adminP = useContext(AdminContext)
    const msgC = useContext(MessageC)
    const UpdateLogs = useRef(async (functionFilter, adminFilter, dateFilter, functionNextToken, adminNextToken, hitsOnly) => {
        msgC.showMessage("Starting log refresh", "#0f0")
        var baseOptions = {};
        if (adminNextToken !== undefined) {
            baseOptions.adminNextToken = adminNextToken;
        }
        if (functionNextToken !== undefined) {
            baseOptions.functionNextToken = functionNextToken;
        }

        if (dateFilter !== undefined) {
            var curFilter = dateFilter.unix();
            baseOptions.filterEpoch = curFilter// - (curFilter % 86400); (removed & placed into backend)

        } else { // first log pull
            var curFilterDef = dayjs().unix();
            baseOptions.filterEpoch = curFilterDef //- (curFilterDef % 86400); (removed & placed into backend)
        }

        var res;
        if (hitsOnly) {
            console.log("hitsonly")
            res = await adminP.AdminListLogs(functionFilter, adminFilter, baseOptions, true)
        } else {
            console.log("allresults")
            res = await adminP.AdminListLogs(functionFilter, adminFilter, baseOptions)
        }
        
        const getEvents = (res) => {
            if (res.Events === null || res.Events.length === 0) return []
            var allLogs = [];
            for (let i = 0; i < res.Events.length; i++) {
                const msg = res.Events[i].Message
                const slices = msg.split("[ logentry ]")
                try {                
                    var parsed = JSON.parse(slices[slices.length-1])
                    parsed.streamName = res.Events[i].LogStreamName
                    allLogs.push(parsed)
                } catch {

                }
            }
            return allLogs
        }

        // pagination tokenization reset
        if (res["log-a"].Events?.length === 0) {
            setAdminNextToken(undefined)
        } else {
            setAdminNextToken(res["log-a"].NextToken)
        }
        if (res["log-f"].Events?.length === 0) {
            setFunctionNextToken(undefined)
        } else {
            setFunctionNextToken(res["log-f"].NextToken)
        }

        const adminEvts = getEvents(res["log-a"])
        const apiEvts = getEvents(res["log-f"])

        setAdminEvents(adminEvts)
        setAPIEvents(apiEvts)
        msgC.showMessage("All logs updated", "#0f0")
    }) 

    // Next Page Helpers
    const NextPageAdmin = () => {
        msgC.showMessage("Loading Next Page", "#0f0")
        UpdateLogs.current(functionFilter, adminFilter, dateFilter, undefined, adminNextToken)
    }
    const NextPageFunction = () => {
        msgC.showMessage("Loading Next Page", "#0f0")
        UpdateLogs.current(functionFilter, adminFilter, dateFilter, functionNextToken, undefined, hitsOnly)
    }

    const handleUpdate = () => {
        setFunctionFilter('')
        setAdminFilter('')
        UpdateLogs.current(functionFilter, adminFilter, dateFilter, undefined, undefined, hitsOnly)
    }

    const GetIPInfo = async (ipAddress) => {
        setIpAddress(ipAddress)
        handleIpInfoOpen()

        const res = await adminP.AdminGetIPInfo(ipAddress)
        setIpInfo(res)
    }

    const expC = useContext(ExportC)
    const saveLog = async (logType) => {
        if (logType === "api") {
            expC.generateLogCSV("apievents", apiEvents)
        } else {
            expC.generateLogCSV("adminevents", adminEvents)
        }
        msgC.showMessage("Report saved", "#0f0", true)
    }

    // filtering for logs 
    const FilterLogs = async () => {
        //console.log("FL: Update with filter")
        UpdateLogs.current(functionFilter, adminFilter, dateFilter, undefined, undefined, hitsOnly)
    }

    useEffect(() => {
        // load map 
        if (ipInfo === undefined) return
        initializeMap(ipInfo.longitude,ipInfo.latitude,1)
    }, [ipInfo])

    useEffect(() => {
        UpdateLogs.current()
    }, [])

    return (
        <div style={{marginTop: '20px', color: AppColors.SecondaryTextColor}}>
            <div>
                <Fade>
                <Container>
                { /* Date Selection */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Box style={{             
                        borderRadius: '10px',
                        marginBottom: '20px',
                        backgroundColor: AppColors.DefaultBackgroundColor,
                        color: AppColors.DefaultTextColorTextColor,
                        boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`,
                        padding: '10px',
                        paddingTop: '20px'
                    }}>
                        <DatePicker
                            autoOk={true}
                            label="Date"
                            value={dateFilter}
                            onChange={handleDateFilterChange}
                            sx={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                svg: {
                                    color: AppColors.DefaultTextColor,
                                },
                                label: {
                                    color: AppColors.DefaultTextColor,
                                },
                                input: {
                                    color: AppColors.DefaultTextColor,
                                },
                            }}
                            />
                    </Box>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={handleUpdate}>
                            <RefreshOutlined style={{color: AppColors.SecondaryTextColor}}/>
                        </IconButton>
                        <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>Hit API Log</div>
                    </div>
                    <div>
                        <Tooltip title="Download API CSV">
                        <IconButton onClick={() => saveLog("api")} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                            <Download style={{color: AppColors.SecondaryTextColor}} />
                        </IconButton>
                        </Tooltip>
                    </div>
                    {/* 
                    <div>
                        <IconButton onClick={handleHitClearDialogOpen} disabled>
                            <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                        </IconButton>
                        <Dialog
                            onClose={handleHitClearDialogClose}
                            open={hitClearDialogOpen}>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <div>Are you sure you want to clear the function API logs?</div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={handleHitClearDialogClose}>Close</Button>
                                <Button variant="contained" onClick={ClearFunctionLog}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    */}
                </div>

                { /* Filter Box  */}
                <div style={{padding: '10px', display: 'flex'}}>
                    <TextField 
                        value={functionFilter}
                        onChange={handleChangeFunctionFilter}
                        label="Filter"
                        fullWidth
                        style={{
                            border: `1px solid ${AppColors.DefaultBorderColor}`,
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }}
                        InputLabelProps={{               
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        inputProps={{
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        />
                    <Button
                        onClick={FilterLogs}
                        style={{
                            border: `1px solid ${AppColors.DefaultBorderColor}`,
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }}>Filter</Button>
                </div>
                <div style={{padding:'10px', display: 'flex', alignItems: 'center'}}>
                    <Tooltip title={hitsOnly ? "Hits Only" : "All Results"}>
                        <IconButton onClick={handleChangeHitsOnly}>
                            { hitsOnly ? (
                                <CircleNotifications style={{color: AppColors.ButtonSuccessColor}} />
                            ) : (
                                <NotInterested style={{color: AppColors.ButtonWarningColor}} />
                            )}
                        </IconButton>
                    </Tooltip>
                    <FormHelperText style={{color: AppColors.SecondaryTextColor}}>{ hitsOnly ? "Viewing Hits Only" : "Viewing All Results"}</FormHelperText>
                </div>
                <div style={{
                        backgroundColor: AppColors.DefaultBackgroundColor,
                        maxHeight: '500px', overflowY: 'scroll', border: `1px solid ${AppColors.SecondaryBorderColor}`, padding: '10px'}}>

                        { apiEvents !== undefined ? (
                            <>
                                { apiEvents.length > 0 && apiEvents.map((item, i) => (
                                    <div key={i}>
                                        <Accordion 
                                            style={{
                                                backgroundColor: AppColors.SecondaryBackgroundColor, 
                                                color: AppColors.SecondaryTextColor,
                                                borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, marginBottom: '5px'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore style={{color: AppColors.SecondaryTextColor}} />}
                                                aria-controls={`panel${i}-content`}
                                                id={`panel${i}-header`}
                                                >
                                                    { /** Accordian Summary */}
                                                    <Grid container>
                                                        <Grid item xs={12} md={4}>

                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <div style={{maxWidth: '25px'}}>
                                                                    <div style={{
                                                                        marginRight: '10px',
                                                                        display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    { item.project === undefined || item.project === "" ? (
                                                                        <WarningOutlined style={{
                                                                            color: AppColors.ButtonWarningColor}} />
                                                                    ) : (
                                                                        <CheckCircle style={{
                                                                            color: AppColors.ButtonSuccessColor}} />
                                                                    )}
                                                                    </div>
                                                                </div>
                                                                <Chip label={item.path} variant="outlined"
                                                                    style={{
                                                                        backgroundColor: AppColors.DefaultBackgroundColor,
                                                                        color: AppColors.DefaultTextColor,
                                                                        display: 'flex',
                                                                    }} 
                                                                    />
                                                            </div>

                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    padding: '5px',
                                                                    flexGrow: 1,
                                                                }}>
                                                                <div>
                                                                    <Tooltip title={`${new Date(item.time*1000).toLocaleString()}`}>
                                                                        <div>{ new Date(item.time*1000).toUTCString() }</div>
                                                                    </Tooltip>
                                                                </div>
                                                                <div>{ item.headers["X-Forwarded-For"] }</div>
                                                                <div>{ item.method }</div>
                                                            </div>        
                                                        </Grid>
                                                    </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails style={{
                                                backgroundColor: AppColors.DefaultBackgroundColor, 
                                                color: AppColors.DefaultTextColor,
                                                border: `1px solid ${AppColors.DefaultBoxShadowColor}`
                                            }}>
                                            <div>
                                                { /** Accordian Collapsable */}
                                                <div>Host: { item.headers["Host"] }</div>                 
                                                <div>UserAgent: { item.headers["User-Agent"] }</div>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    { /* Multiple IP Infos based on split */}
                                                    { item.headers["X-Forwarded-For"].split(",").map((subIp, i) => {
                                                        return (
                                                            <div style={{display: 'flex', alignItems: 'center'}}  key={subIp}>
                                                                X-Forwarded-For: { subIp }
                                                                <Tooltip title="Get IP Information">
                                                                <IconButton key={i} onClick={() => GetIPInfo(subIp.replaceAll(" ", ""))}>
                                                                    <InfoRounded style={{color: AppColors.DefaultTextColor}} />
                                                                </IconButton>
                                                                </Tooltip>
                                                            </div> 
                                                        )
                                                    })}
                                                </div>  
                                                <br />
                                                {  Object.keys(item.headers).map((key) => (
                                                    <div key={key}>{ key } - { item.headers[key] }</div>
                                                )) }

                                                { /* Delete Log Entry Button 
                                                <div style={{display: 'flex', justifyContent: 'center'}}> 
                                                    <Button 
                                                        onClick={() => DeleteLogEvt("function", item.streamName)}
                                                        variant="contained" style={{
                                                        backgroundColor: AppColors.ButtonDeleteColor,
                                                    }}>Delete</Button>
                                                </div>*/}
                                            </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))} 
                                { apiEvents.length === 0 && (
                                    <>No Events Found</>
                                )}
                            </>
                        ) : (
                            <>Loading...</> 
                        )}
                </div>
                
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '20px',
                }}>
                    <Button variant="contained" onClick={NextPageFunction}>Next Page</Button>
                </div>


                { /* Admin API Log */}
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '50px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={handleUpdate}>
                            <RefreshOutlined style={{color: AppColors.SecondaryTextColor}}/>
                        </IconButton>
                        <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>Admin API Log</div>
                    </div>
                    <div>
                        <Tooltip title="Download Admin CSV">
                        <IconButton onClick={() => saveLog("admin")} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                            <Download style={{color: AppColors.SecondaryTextColor}} />
                        </IconButton>
                        </Tooltip>
                    </div>
                    {/* 
                    <div>
                        <IconButton onClick={handleAdminDialogOpen}>
                            <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                        </IconButton>
                        <Dialog
                            onClose={handleAdminDialogClose}
                            open={adminDialogOpen}>
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <div>Are you sure you want to clear the admin API logs?</div>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={handleAdminDialogClose}>Close</Button>
                                <Button variant="contained" onClick={ClearAdminLog}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    */}
                </div>


                { /* Filter Box  */}
                <div style={{padding: '10px', display: 'flex'}}>
                    <TextField 
                        value={adminFilter}
                        onChange={handleChangeAdminFilter}
                        label="Filter"
                        fullWidth
                        style={{
                            border: `1px solid ${AppColors.DefaultBorderColor}`,
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }}
                        InputLabelProps={{               
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        inputProps={{
                            style:{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                            }
                        }}
                        />
                    <Button
                        onClick={FilterLogs}
                        style={{
                            border: `1px solid ${AppColors.DefaultBorderColor}`,
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }}>Filter</Button>
                </div>

                <div style={{padding:'10px'}}>
                    <FormHelperText style={{color: AppColors.SecondaryTextColor}}>Oldest On Top</FormHelperText>
                </div>
                <div style={{
                    backgroundColor: AppColors.DefaultBackgroundColor,
                    maxHeight: '500px', overflowY: 'scroll', border: `1px solid ${AppColors.SecondaryBorderColor}`, padding: '10px'}}>
                    <div>
                        { adminEvents !== undefined ? (
                                <>
                                    { adminEvents.length > 0 && adminEvents.map((item, i) => (
                                        <div key={i}>
                                            <Accordion 
                                                style={{
                                                    backgroundColor: AppColors.SecondaryBackgroundColor, 
                                                    color: AppColors.SecondaryTextColor,
                                                    borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, marginBottom: '5px'}}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore style={{color: AppColors.SecondaryTextColor}} />}
                                                    aria-controls={`panela${i}-content`}
                                                    id={`panela${i}-header`}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={12} md={4}>
                                                                <Chip label={item.path} variant="outlined"
                                                                    style={{
                                                                        backgroundColor: AppColors.DefaultBackgroundColor,
                                                                        color: AppColors.DefaultTextColor,
                                                                        display: 'flex',
                                                                    }} 
                                                                    />
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexWrap: 'wrap',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        paddingLeft: '10px',
                                                                        flexGrow: 1,
                                                                    }}>
                                                                        <Tooltip title={`${new Date(item.time*1000).toLocaleString()}`}>
                                                                            <div>{ new Date(item.time*1000).toUTCString() }</div>
                                                                        </Tooltip>
                                                                        <div>{ item.user }</div>
                                                                </div>        
                                                            </Grid>
                                                        </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails style={{
                                                    overflow: 'scroll',
                                                    backgroundColor: AppColors.DefaultBackgroundColor, 
                                                    color: AppColors.DefaultTextColor,
                                                    border: `1px solid ${AppColors.DefaultBoxShadowColor}`
                                                }}>
                                                <div>
                                                    <div>{ item.method }</div>
                                                    <div>Host: { item.headers["Host"] }</div>                 
                                                    <div>UserAgent: { item.headers["User-Agent"] }</div> 

                                                    { /* Multiple IP Infos based on split */}
                                                    { item.headers["X-Forwarded-For"].split(",").map((subIp, i) => {
                                                        return (
                                                            <div style={{display: 'flex', alignItems: 'center'}} key={subIp}>
                                                                X-Forwarded-For: { subIp }
                                                                <Tooltip title="Get IP Information">
                                                                <IconButton key={i} onClick={() => GetIPInfo(subIp.replaceAll(" ", ""))}>
                                                                    <InfoRounded style={{color: AppColors.DefaultTextColor}} />
                                                                </IconButton>
                                                                </Tooltip>
                                                            </div> 
                                                        )
                                                    })}

                                                    <br />
                                                    {  Object.keys(item.headers).map((key) => (
                                                        <div key={key}>{ key } - { item.headers[key] }</div>
                                                    )) }

                                                    { /* Delete Log Entry Button 
                                                    <div style={{display: 'flex', justifyContent: 'center'}}> 
                                                        <Button 
                                                            onClick={() => DeleteLogEvt("admin", item.streamName)}
                                                            variant="contained" style={{
                                                            backgroundColor: AppColors.ButtonDeleteColor,
                                                        }}>Delete</Button>
                                                    </div>*/}
                                                </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    ))} 
                                    { adminEvents.length === 0 && (
                                        <>No Events Found</>
                                    )}
                                </>
                            ) : (
                                <>Loading...</> 
                            )}
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '20px',
                }}>
                    <Button variant="contained" onClick={NextPageAdmin}>Next Page</Button>
                </div>


                { /* IP INFO DIALOG */ }
                <div>
                    <Dialog
                        open={ipInfoOpen}
                        onClose={handleIpInfoClose}
                        PaperProps={{style: {
                            backgroundColor: AppColors.SecondaryBackgroundColor,
                            color: AppColors.SecondaryTextColor,
                            boxShadow: `0 0 2px 3px ${AppColors.SecondaryBoxShadowColor}`
                        }}}
                        >
                        <DialogTitle
                            style={{
                            }}>IP Information {ipAddress}</DialogTitle>
                        <DialogContent
                            style={{
                                color: AppColors.SecondaryTextColor,
                                padding: '10px',
                            }}>

                            { /* Small Map */}
                            <div id="map" style={{
                                minHeight: '200px',
                            }}>

                            </div>

                            { ipInfo !== undefined ? (
                                <div style={{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor, 
                                    boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`,
                                    borderRadius: '10px'}}>
                                    <List>
                                        <ListItem>
                                            <ListItemText>IP: { ipInfo.ip }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Version: { ipInfo.version }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>City: { ipInfo.city }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Region: { ipInfo.region }</ListItemText>
                                        </ListItem>           
                                        <ListItem>
                                            <ListItemText>Country Name: { ipInfo.country_name }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Latitude: { ipInfo.latitude }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Longitude: { ipInfo.longitude }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>ASN: { ipInfo.asn }</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Org: { ipInfo.org }</ListItemText>
                                        </ListItem>
                                    </List>
                                </div>
                            ) : (<>No Results</>)}
                        </DialogContent>
                        <DialogActions
                            style={{
                                backgroundColor: AppColors.SecondaryBackgroundColor,
                                color: AppColors.SecondaryTextColor,
                            }}>
                            <Button variant="contained" onClick={handleIpInfoClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>

                </Container>
                </Fade>
            </div>
        </div>
    )
}