import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, MenuItem,   Select,  TextField, Tooltip } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { Navigate } from "react-router-dom"
import { AddCircle, Delete, DeleteForever, DoNotDisturb, LockReset, Person, Settings } from "@mui/icons-material"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"
import { ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"
import { AdminContext } from "../../contexts/AdminProv"


const GetAttribute = (name, curUser) => {
    if (curUser === undefined) return undefined
    for (let x = 0; x < curUser.Attributes.length; x++) {
        if (curUser.Attributes[x].Name === name) {
            return  curUser.Attributes[x].Value
        }
    }
    return undefined
}

export const AdminUserPage = () => {   
    const dataP = useContext(DataP)

    return (
        <div>
            { /* Administrator Render */}
            <dataP.RenderIfAdmin>
                <AdminContent />
            </dataP.RenderIfAdmin>
            
            { /* Dashboard Navigation */ }
            <dataP.RenderIfNotAdmin>
                <Navigate to="/" />
            </dataP.RenderIfNotAdmin>
        </div>
    )
}


// Admin Content 
const AdminContent = () => {
    const [ forceUpdate, setForce ] = useState(false)
    const [ allGroups, setAllGroups ] = useState([])
    const forceR = () => {setForce(!forceUpdate)}

    const { AppColors } = useContext(ThemeC)

    const adminP = useContext(AdminContext)
    const adminPRef = useRef(adminP)

    useEffect(() => {
        const ListAllGroups = async () => {
            const res = await adminPRef.current.AdminListGroups()
            setAllGroups(res)
        }
        ListAllGroups()
    }, [])

    return (
        <div style={{marginTop: '20px'}}>
            <Container>
                <div style={{
                    borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`, 
                    padding: '10px', 
                    paddingTop: '20px', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                }}>
                    <div style={{fontSize: '1.4rem', fontWeight: 'bold', color: AppColors.SecondaryTextColor}}>Users</div>
                    <InviteUser refreshCb={forceR} />
                </div>

                { /* Reset Password, Delete Account, Move Results to New Account */}
                <div>
                    <UsersData forceUpdate={forceUpdate} refreshCb={forceR} allGroups={allGroups} />
                </div>
            </Container>
        </div>
    )
}


// Invite User Dialog
const InviteUser = ({refreshCb}) => {
    const [ open, setOpen ] = useState(false)
    const [ user, setUser ] = useState('')
    const [ password, setPassword ] = useState('')
    const { AppColors } = useContext(ThemeC)

    const onChangeUser = (e) => setUser(e.target.value)
    const onChangePassword = (e) => setPassword(e.target.value)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const msgC = useContext(MessageC)
    const adminP = useContext(AdminContext)

    const inviteUser = async () => {
        const res = await adminP.AdminCreateUser(user, password)
        if (res.User === undefined) {
            // error 
            msgC.showMessage(res, "#f00")
        } else {
            msgC.showMessage("Successfully Created User", "#0f0")
            refreshCb()
            setUser('')
            setPassword('')
        }
        handleClose()
    }

    const passwordStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()"
    const genRandomPassword = () => {
        var pwStr = "";
        for(let i =0; i < 15; i++) {
            let char = Math.floor(Math.random() * passwordStr.length +1);
            pwStr += passwordStr.charAt(char )
        }
        setPassword(pwStr)
    }

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '10px'}}>
                <Button onClick={handleOpen} variant="contained"
                    style={{
                        color: AppColors.DefaultTextColor,
                        backgroundColor: AppColors.DefaultBackgroundColor, boxShadow: '0 0 2px 1px #7a7'}}>Create User</Button>
            </div>
            <Dialog 
                open={open}
                onClose={handleClose}
                PaperProps={{style: {
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor, 
                    boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`}}}
                >
                <DialogTitle>Create User</DialogTitle>
                <DialogContent>
                    <div style={{margin: '10px'}}>
                        <TextField label="Email" value={user} onChange={onChangeUser} fullWidth
                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`
                               
                            }}
                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }} />
                    </div>
                    <div style={{margin: '10px', marginTop: '20px'}}>
                        <TextField label="Password" value={password} onChange={onChangePassword} fullWidth
                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`
                            }}

                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }} />
                    </div>

                    <div style={{margin: '10px', marginTop: '20px'}}>
                        <Button variant="contained" fullWidth onClick={genRandomPassword}>Generate Password</Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Close</Button>
                    <Button onClick={inviteUser} variant="contained" color="success">Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}



const UsersData = ({forceUpdate, refreshCb, allGroups}) => {
    const [ userFilter, setUserFilter ] = useState('')
    const [ userData, setUserData ] = useState([])

    const msgC = useRef(useContext(MessageC))

    const adminP = useContext(AdminContext)
    const adminPRef = useRef(adminP)
    const dataP = useRef(useContext(DataP))

    const { AppColors } = useContext(ThemeC)
    
    useEffect(() => {
        const ListUsers = async (dataP) => {
            const orgUserData = (userList) => {
                let tempUserData = [];
                for(let i = 0; i < userList.length;i++) {
                    // create user obs 
                    const name = GetAttribute("name", userList[i])
                    const email = GetAttribute("email", userList[i])
                    const sub = GetAttribute("sub", userList[i])
                    const modDate = userList[i].UserLastModifiedDate
                    tempUserData = [...tempUserData, {mod: modDate, name: name, email: email, sub: sub, enabled: userList[i].Enabled, userstatus: userList[i].UserStatus}]
                }
                return tempUserData
            }
        
            if (dataP.current === undefined) return
            try {
                const res = await adminPRef.current.AdminListUsers()
                const userList = res.Users;
                if (userList.length > 0) {
                    return orgUserData(userList)
                }
            } catch(e) {
                msgC.current.showMessage("Error: Try Refreshing App", "#f00")
            }
        }
    
        ListUsers(dataP).then(allUsers => {
            if (allUsers !== undefined && allUsers.length > 0) {
                setUserData(allUsers)
            }
        })
    }, [forceUpdate])


    const searchForUser = async () => {
        console.log(`Search for user ${userFilter}`)
        const orgUserData = (userList) => {
            let tempUserData = [];
            for(let i = 0; i < userList.length;i++) {
                // create user obs 
                const name = GetAttribute("name", userList[i])
                const email = GetAttribute("email", userList[i])
                const sub = GetAttribute("sub", userList[i])
                tempUserData = [...tempUserData, {name: name, email: email, sub: sub, enabled: userList[i].Enabled, userstatus: userList[i].UserStatus}]
            }
            return tempUserData
        }

        const res = await adminP.AdminListUsers(userFilter)
        const userList = res.Users;
        setUserData(orgUserData(userList))
    }
    
    const handleChangeFilter = (e) => {
        setUserFilter(e.target.value)
    }

    const onKeyCheck = (e) => {
        if (e.key === "Enter") {
            searchForUser()
        }
    }

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', padding: '10px'}}>
                <TextField 
                    onKeyDown={onKeyCheck}
                    onChange={handleChangeFilter}
                    value={userFilter}
                    variant="filled"
                    label="Email"
                    fullWidth
                    style={{
                        backgroundColor: AppColors.DefaultBackgroundColor,
                        color: AppColors.DefaultTextColor,
                        border: `1px solid ${AppColors.DefaultBorderColor}`
                        
                    }}
                    InputLabelProps={{               
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                    inputProps={{
                        style:{
                            backgroundColor: AppColors.DefaultBackgroundColor,
                            color: AppColors.DefaultTextColor,
                        }
                    }}
                />
                <Button variant="contained"
                    style={{margin: '10px'}}
                    onClick={searchForUser}>Search</Button>
            </div>

            <div style={{marginTop: '20px'}}>

                <Grid container spacing={2} padding={2}>
                    { userData.map((item) => (
                        <Grid item key={item.email}
                            xs={12} md={4}>
                            <Fade>
                            <div 
                            style={{
                                color: AppColors.SecondaryTextColor,
                                backgroundColor: AppColors.DefaultBackgroundColor, borderRadius: '10px', padding: '10px',
                                boxShadow: `0 0 1px 2px ${AppColors.SecondaryBoxShadowColor}`}} >
                                <div style={{padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div>{ item.name }</div>
                                    <div>
                                        <EditUser activeUser={item} forceUpdate={refreshCb} allGroups={allGroups} />
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
                                    <div style={{
                                        padding: '5px',
                                        marginBottom: '5px',
                                        backgroundColor: AppColors.SecondaryBackgroundColor,
                                        boxShadow: `0 0 2px 2px ${AppColors.SecondaryBoxShadowColor}`,
                                    }}>
                                        <div>
                                            Email: { item.email }
                                        </div>
                                        <div>
                                            Status: { item.userstatus }
                                        </div>
                                        <div>
                                            Enabled:  { item.enabled ? <span style={{
                                                color: AppColors.ButtonSuccessColor}}>Enabled</span> : <span style={{color: AppColors.ButtonDeleteColor}}>Disabled</span>}
                                        </div>
                                        <div>
                                            Modified: { item.mod }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Fade>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}


const EditUser = ({activeUser, forceUpdate, allGroups}) => {
    const [ confirmDeleteOpen, setConfirmOpen ] = useState(false)
    const [ selectedGroup, setSelectedGroup ] = useState()
    const [ activeGroups, setActiveGroups ] = useState([])

    const [ groupOpen, setGroupOpen ] = useState(false)
    const handleGroupClose = () => setGroupOpen(false)
    const handleGroupOpen = () => setGroupOpen(true)

    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const { AppColors } = useContext(ThemeC)
    const msgC = useContext(MessageC)

    const adminP = useContext(AdminContext)
    const adminPRef = useRef(adminP)
    const dataP = useContext(DataP)

    const deleteAccount = async () => {
        if (dataP === undefined) return
        const res = adminP.AdminDeleteUser(activeUser.email)
        if (res.status === "success") {
            msgC.showMessage("Successfully Deleted", "#0f0")
        } else {
            msgC.showMessage(res.data, "#f00")
        }
        forceUpdate()
    }

    const ListGroupForUser = async () => {
        const groups = await adminP.AdminListUserGroup(activeUser.email)
        setActiveGroups(groups)
    }

    const deleteGroupFromUser = async (groupName) => {
        const res = await adminP.AdminDeleteUserGroup(activeUser.email, groupName)
        if (res.status === "success") {
            // success 
            msgC.showMessage("Successfully Removed Group", "#0f0")
            ListGroupForUser()
        } else {
            // fail 
            msgC.showMessage("Error removing Group", "#f00")
        }
    }

    const addGroupToUser = async (groupName) => {
        const res = await adminP.AdminAddUserGroup(activeUser.email, groupName)
        if (res.status === "success") {
            // success 
            msgC.showMessage("Successfully Added Group", "#0f0")
            ListGroupForUser()
        } else {
            // fail 
            msgC.showMessage("Error adding Group", "#f00")
        }
        handleGroupClose()
    }

    // get dc ref
    useEffect(() => {
        if (!open) return 
        const updateGroups = async () => {
            const res = await adminPRef.current.AdminListUserGroup(activeUser.email)
            setActiveGroups(res)
        }
        updateGroups()
    }, [open, activeUser.email])

    // update first selected group on receive
    useEffect(() => {
        if (allGroups.length > 0) {
            setSelectedGroup(allGroups[0].GroupName)
        }
    }, [allGroups])

    const isSelfAdmin = (item) => (activeUser.email === dataP.state.user.attributes.email && item.GroupName === "Admin")
    const handleGroupSel = (e) => {
        setSelectedGroup(e.target.value)
    }


    return (
        <div>
            { /* Add Group */}
            <div>
                <Dialog 
                    BackdropProps={{style: {backgroundColor: '#000d'}}}
                    open={groupOpen}
                    onClose={handleGroupClose}
                    PaperProps={{ style: {boxShadow: '0 0 2px 3px #ccc', backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.SecondaryTextColor}}}>
                    <DialogTitle>
                        Add Group 
                    </DialogTitle>
                    <DialogContent 
                        style={{minWidth: '200px'}}>
                        <Select fullWidth
                            inputProps={{style: {color: '#fff'}}}
                            sx={{backgroundColor: '#000', color: '#fff'}}
                            value={selectedGroup}
                            onChange={handleGroupSel}>
                            { allGroups.map(group => (
                                <MenuItem value={group.GroupName} key={group.GroupName}>{group.GroupName}</MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleGroupClose}>Close</Button>
                        <Button variant="contained" color="success" onClick={() => addGroupToUser(selectedGroup)}>Add</Button>
                    </DialogActions>
                </Dialog>
            </div>

            { /* Confirm Delete */}
            <div>
                <Dialog 
                    open={confirmDeleteOpen}
                    onClose={() => setConfirmOpen(false)}>
                    <DialogTitle>Confirm Deletion of { activeUser.email }</DialogTitle>
                    <DialogActions>
                        <Button variant={"contained"} onClick={() => setConfirmOpen(false)}>Close</Button>
                        <Button variant={"contained"} color="error" onClick={deleteAccount}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>

            { /* Edit Rest */ }
            <IconButton style={{border: `1px solid ${AppColors.DefaultBorderColor}`}} onClick={handleOpen}>
                <Settings style={{color: AppColors.DefaultTextColor}} />
            </IconButton>
            <Dialog 
                BackdropProps={{style: {backgroundColor: '#000d'}}}
                open={open}
                onClose={handleClose}
                PaperProps={{ style: {boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`, backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor}}}
            >
                <DialogTitle>Edit User - {activeUser !== undefined && activeUser.name}</DialogTitle>
                <DialogContent style={{minWidth: '300px'}}>
                    <div>
                        {/* Reset Password */}
                        <div style={{marginTop: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{padding: '5px', marginRight: '20px'}}>Reset Password</div>
                            <ResetPassword activeUser={activeUser} />
                        </div>

                        {/* Reset Password */}
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{padding: '5px', marginRight: '20px'}}>Account State</div>
                            <DisableEnableUser activeUser={activeUser} refreshCb={forceUpdate} />
                        </div>

                        { /* Delete User */}
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{padding: '5px', marginRight: '20px'}}>Delete Account</div>
                            <IconButton onClick={() => setConfirmOpen(true)} disabled={dataP.state.user.attributes.email === activeUser.email}>
                                <Tooltip title="Delete Account">
                                    <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                                </Tooltip>
                            </IconButton>
                        </div>

                        { /* Groups */}
                        <div style={{
                            border: `1px solid ${AppColors.SecondaryBoxShadowColor}`, 
                            padding: '5px', borderRadius: '10px', backgroundColor: AppColors.SecondaryBackgroundColor, marginTop: '20px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <IconButton onClick={handleGroupOpen}>
                                    <AddCircle style={{color: AppColors.ButtonSuccessColor}} />
                                </IconButton>
                                <div style={{display: 'flex', justifyContent: 'center', borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, flexGrow: '1'}}>Groups</div>
                            </div>
                            <List>
                                { activeGroups.map(item => (
                                    <Fade key={item.GroupName}>
                                    <ListItem 
                                        key={item.GroupName}
                                        secondaryAction={
                                            <IconButton 
                                                disabled={isSelfAdmin(item)}
                                                onClick={() => deleteGroupFromUser(item.GroupName)}><Delete style={{color: isSelfAdmin(item) ? '#555' : '#f00'}} /></IconButton>
                                        }>{ item.GroupName }
                                    </ListItem>
                                    </Fade>
                                ))}
                            </List>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const DisableEnableUser = ({activeUser, refreshCb}) => {
    const adminP = useContext(AdminContext)
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    const changeUserState = async () => {
        const stateToChange = activeUser.enabled ? "disable" : "enable"
        const res = await adminP.AdminSetUserStatus(activeUser.email, stateToChange)
        if (res.status === "success") {
            // success 
            msgC.showMessage(`Successfully ${stateToChange}d Account`, "#0f0")
            refreshCb()
        } else {
            // fail 
            msgC.showMessage(res, "#f00")
        }
    }

    const displayText = activeUser.enabled ? 
        <Tooltip title="Disable Account">
            <Person style={{color: AppColors.ButtonSuccessColor}} /></Tooltip> : <Tooltip title="Enable Account"><DoNotDisturb style={{color: AppColors.ButtonDeleteColor}} /></Tooltip>
    
    return (
        <div>
            <IconButton onClick={changeUserState}>
                {displayText}
            </IconButton>
        </div>
    )
}

const ResetPassword = ({activeUser}) => {
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose =  () => setOpen(false)

    const adminP = useContext(AdminContext)
    const msgC = useContext(MessageC)
    const handleReset = async () => {
        const res = await adminP.AdminResetUserPassword(activeUser.email)
        if (res.status === "success") {
            // success 
            msgC.showMessage("Successfully Reset Password", "#0f0")
        } else {
            // fail 
            msgC.showMessage(res, "#f00")
        }
        handleClose()
    }

    return (
        <div>
            <div>
                <IconButton onClick={handleOpen}>
                    <Tooltip title="Reset Password">
                        <LockReset color="warning" />
                    </Tooltip>
                </IconButton>
            </div>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}>
                    <DialogTitle>Confirm Reset Password</DialogTitle>
                    <DialogContent>
                        <Box style={{maxWidth: '300px'}}>
                            Are you sure you want to reset the password for <br/> {activeUser.email}?
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                        <Button variant="contained" color="error" onClick={handleReset}>Reset</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}