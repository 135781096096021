import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField, Tooltip } from "@mui/material"
import { useContext, useState } from "react"
import { MessageC } from "../../contexts/MessageContext"
import { ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"

export const CreateProjectDialog = () => {
    const [ open, setOpen ] = useState(false)
    const [ projTitle, setTitle ] = useState('')
    const [ projDesc, setDesc ] = useState('')

    const { AppColors } = useContext(ThemeC)
    const handleClose = ()=> {
        setOpen(false);
    }

    const handleOpen = ()=> {
        setOpen(true);
    }

    // new
    const newDataC = useContext(DataP)
    const msgC = useContext(MessageC)

    const handleCreateProj = async () => {
        if (projTitle === '') {
            //console.log("no project title")
            msgC.showMessage("No Project Title", "#ff0")
            return 
        }
        await newDataC.createProject(projTitle, projDesc)
        
        // clear out 
        setTitle('')
        setDesc('')
        setOpen(false)

        msgC.showMessage("Created Project...", "#0f0")
    }
    
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Tooltip title="Create Project">
                <Button style={{
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor, boxShadow: `0 0 2px 2px ${AppColors.ButtonSuccessColor}`}} 
                    onClick={handleOpen}>Create Project</Button>
                </Tooltip>
            </div>  
            <Dialog 
                open={open}
                onClose={handleClose}>
                <DialogTitle>Create Project</DialogTitle>
                <DialogContent>
                    <div >
                        <FormHelperText style={{padding: '10px'}}>Title for the project (required)</FormHelperText>
                        <TextField id="title" label="Title" variant="outlined" style={{marginBottom: '10px'}} fullWidth 
                            onChange={(e) => setTitle(e.target.value)} 
                            value={projTitle} />
                    </div>
                    <div >
                        <FormHelperText  style={{padding: '10px'}}>Description for the project (optional)</FormHelperText>
                        <TextField id="title" rows="3" label="Description" variant="outlined" fullWidth 
                            onChange={(e) => setDesc(e.target.value)}
                            value={projDesc} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => handleCreateProj()}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}