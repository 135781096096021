import { useContext, useEffect, useRef, useState } from "react"
import { ThemeC } from "../../constants/appbase"
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material"
import { MessageC } from "../../contexts/MessageContext"
import { DeleteForever } from "@mui/icons-material"
import { AdminContext } from "../../contexts/AdminProv"


export const AdminImagesPage = () => {
    const [ forceRefresh, setForceRefresh ] = useState(false)
    const forceR = () => setForceRefresh(!forceRefresh)
    const [ allImageKeys, setAllImageKeys ] = useState()
    const [ allImageData, setAllImageData ] = useState([])
    const { AppColors } = useContext(ThemeC)

    const adminP = useRef(useContext(AdminContext))

    const msgC = useRef(useContext(MessageC))
    useEffect(() => {
        const GetCurrentImages = async () => {
            const res = await adminP.current.AdminListImages()
            if (res.length === 0) {
                msgC.current.showMessage("No images found", "#0f0")
                setAllImageKeys([])
                return 
            } else {
                var allData = [];
                for (let i =0; i < res.length; i++) {
                    const newO = {...res[i], id: i}
                    allData.push(newO)
                }
                setAllImageKeys(allData)
            }
        }
        GetCurrentImages()

    }, [forceRefresh])


    useEffect(() => {
        // new keys
        if (allImageKeys === undefined || allImageKeys.length === 0) return 
        // spawn keys
        const getImage = async (key, id) => {
            const keyName = key.name;
            const imageD = await adminP.current.AdminGetImage(keyName)
            var imageObj = {...key, id: id, data: imageD}
            return imageObj;
        }

        const startImageLoad = async () => {
            // cur image 
            var allData = [];
            for(let x = 0; x < allImageKeys.length;x++) {
                const newD = await getImage(allImageKeys[x], x).then(res => {
                    // add to data
                    return res 
                })
                allData.push(newD)
            }
            setAllImageData(allData)
            msgC.current.showMessage("Loaded All Images.", "#0f0")
        }
        startImageLoad()
    }, [allImageKeys])


    const DeleteImage = async (imageName) => {
        await adminP.current.AdminDeleteImage(imageName)
        forceR()
    }

    return (
        <div style={{marginTop: '20px', color: AppColors.DefaultTextColor}}>
            <Container>
                <div style={{
                        borderBottom: `1px solid ${AppColors.DefaultBorderColor}`, 
                        padding: '10px', 
                        paddingTop: '20px', 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                    }}>
                        <div style={{fontSize: '1.4rem', fontWeight: 'bold'}}>Images</div>
                </div>

                <Grid container padding={2} spacing={2}>
                    { allImageKeys !== undefined && allImageKeys.map((item, i) => (
                        <Grid item xs={12} md={3} key={`image-${i}`}>

                            <div style={{
                                border: `1px solid ${AppColors.DefaultBorderColor}`,
                                textAlign: 'center',
                                padding: '10px',
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                marginBottom: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <div>{ item.name }</div>
                                <ConfirmIconCB 
                                    title={`Delete Image ${item.name}`}
                                    content={`Are you sure you want to delete image ${item.name}?`}
                                    buttonTitle="Delete"
                                    confirmCb={() => DeleteImage(item.name)} />
                            </div>

                            { allImageData[i] !== undefined && (
                                <img 
                                    alt={item.name}
                                    style={{maxWidth: '100%'}}
                                    src={`data:image/png;base64,${allImageData[i].data}`} />
                            )}
                        </Grid>
                    ))}
                    { allImageKeys !== undefined && allImageKeys.length === 0 && (
                        <div style={{textAlign: 'center', padding: '10px', width: '100%'}}>No images found.</div>
                    )}
                </Grid>
            </Container>
        </div>
    )
}


// Confirm Dialog Helper
export const ConfirmIconCB = ({title, content, confirmCb, buttonTitle }) => {
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const { AppColors } = useContext(ThemeC)
    const handleCb = () => {
        confirmCb()
        handleClose()
    }
    return (
        <div>
            <div>
                <Tooltip title="Delete Image">
                    <IconButton onClick={handleOpen}>
                        <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                    </IconButton>
                </Tooltip>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                >
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent>{ content }</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    <Button onClick={handleCb} variant="contained" style={{backgroundColor: AppColors.ButtonDeleteColor}}>{ buttonTitle} </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}