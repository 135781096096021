import { Container } from "@mui/material"
import { CreateProjectDialog } from "./pagecomponents/CreateProject"
import { ShowProjects } from "./pagecomponents/ShowProjects"

export const  Dashboard = () => {
    return (
        <div>
            <div>
                <Container style={{paddingTop: '50px'}}>
                    { /* Create Project */ }
                    <CreateProjectDialog />

                    { /* Project Shows */ }
                    <ShowProjects />
                </Container>
            </div>
        </div>
    )
}
