import { createContext, useContext } from "react"
import { ADMINAPI } from "../constants/appbase"
import { DataP } from "./DataProv"
import { DataStore } from "aws-amplify"
import { Domains } from "../models"

// context will need to be a child of DataP

export const AdminContext = createContext()

/*
const reducer = (state, action) => {
    switch (action.type) {
        case "reducer_action":
            return {...state}
        default: 
            return {
                ...state,
            }
    }
}

const initialState = {

}
*/

export const AdminProv = ({children}) => {
    //const [ curState, dispatchReducer ] = useReducer(reducer, initialState)

    const dataP = useContext(DataP)

    // Logging
    const AdminCheckAPIStatus = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/check_api_status", {}).then(res => {
            return res.data
        })
    }
    const AdminListLogs = async (functionFilter, adminFilter, baseOptions, onlyHits) => {
        var allOpts = {
            ...baseOptions,
            functionFilter: functionFilter,
            adminFilter: adminFilter, 
            onlyHits: onlyHits,

        }
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/listlogs", allOpts).then(res => {
            return res.data
        })
    }    
    const AdminCleanFunctionLog = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/cleanfunctionlog", {}).then(res => {
            return res.data
        })
    }   
    const AdminCleanAdminLog = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/cleanadminlog", {}).then(res => {
            return res.data
        })
    }   
    const AdminGetIPInfo = async (ipAddress) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/tools/ipinfo", {ip: ipAddress}).then(res => {
            return res.data
        })
    }

    // Infra
    const AdminCleanDB = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/cleandb", {}).then(res => {
            return res.data
        })
    }
    const AdminCleanS3 = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/cleans3", {}).then(res => {
            return res.data
        })
    }
    const AdminCleanCloudwatch = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/cleanlogs", {}).then(res => {
            return res.data
        })
    }

    // Groups 
    const AdminAddGroup = async (groupName) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_create_group", {groupName: groupName}).then(res => {
            return res.data.Group
        })
    }
    const AdminDeleteGroup = async (groupName) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_delete_group", {groupName: groupName}).then(res => {
            return res.data 
        })
    }
    const AdminListGroups = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_list_groups", {}).then(res => {
            return res.data.Groups
        })
    }

    // Domains 
    const AdminAddDomain = async (domainUrl, domainGroup) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_add_domain", {domain: domainUrl, group: domainGroup}).then(res => {
            console.log(res)
            return res.data
        })
    }
    const AdminListDomains = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_list_domains", {}).then(res => {
            return res.data
        })
    }
    const AdminDeleteDomain = async (itemId, domain) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_delete_domain", {key: itemId, domainName: domain}).then(res => {
            console.log(res)
            return res.data 
        })
    }

    // add domain mutation 
    const AddDomain = async (domain, group) => {
        console.log("newaddition")
        return await DataStore.save(
            new Domains({
                string: domain,
                group_permission: group,
            })
        )
    }
    // delete domain mutation 
    const DeleteDomain = async (id) => {
        return await getDomain(id).then(async domain => {
            return await DataStore.delete(Domains, domain)
        })
    }

    // get domain 
    const getDomain = async (id) => {
        return await DataStore.query(Domains, id)
    }

    // images
    const AdminListImages = async () => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_list_images", {}).then(res => {
            return res.data
        })
    }
    const AdminDeleteImage = async (imageKeyName) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/deleteimage", {
            image: imageKeyName,
        }).then(res => {
            return res.data
        })
    }
    const AdminGetImage = async (imageKeyName) => {
        return await dataP.GetImage(imageKeyName)
    }

    // Users
    const AdminCreateUser = async (user, password) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_create_user", {
            email: user,
            pw: password,
        }).then(res => {
            return res.data
        })
    }
    const AdminResetUserPassword = async (emailAddress) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_reset_pass", {email: emailAddress}).then(res => {
            return res.data
        })
    }
    const AdminListUsers = async (...filter) => {
        var options = {};
        if (filter.length > 0) {
            options = { filter: filter[0] }
        }
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_list_users", options).then(res => {
            return res.data
        })
    }
    const AdminDeleteUser = async (emailAddress) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_delete_user", {
            email: emailAddress,
        }).then(res => {
            return res.data
        })
    }
    const AdminListUserGroup = async (emailAddress) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_list_user_group", {email: emailAddress}).then(res => {
            return res.data.Groups
        })
    }
    const AdminAddUserGroup = async (emailAddress, groupName) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_add_user_group", {
            email: emailAddress, 
            group: groupName,
        }).then(res => {
            return res.data
        })
    }
    const AdminDeleteUserGroup = async (emailAddress, groupName) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_delete_user_group", {
            email: emailAddress, 
            group: groupName,
        }).then(res => {
            return res.data
        })
    }
    const AdminSetUserStatus = async (emailAddress, stateToChange) => {
        return await dataP.RunPermissionedAPI(ADMINAPI, "/admin/admin_user_status", {
            email: emailAddress, 
            status: stateToChange,
        }).then(res => {
            return res.data
        })
    }


    const state = {
        // Current state
        //state: curState,

        // User Management 
        AdminCreateUser: AdminCreateUser,
        AdminListUsers: AdminListUsers,
        AdminDeleteUser: AdminDeleteUser,
        AdminSetUserStatus: AdminSetUserStatus,
        AdminResetUserPassword: AdminResetUserPassword,

        AdminListUserGroup: AdminListUserGroup,
        AdminAddUserGroup: AdminAddUserGroup,
        AdminDeleteUserGroup: AdminDeleteUserGroup,

        // Logging
        AdminCleanAdminLog: AdminCleanAdminLog,
        AdminCleanFunctionLog: AdminCleanFunctionLog,
        AdminListLogs: AdminListLogs,
        AdminGetIPInfo: AdminGetIPInfo,
        AdminCheckAPIStatus: AdminCheckAPIStatus,

        // Infrastructure 
        AdminCleanDB: AdminCleanDB,
        AdminCleanS3: AdminCleanS3,
        AdminCleanCloudWatch: AdminCleanCloudwatch,

        // Groups 
        AdminDeleteGroup: AdminDeleteGroup,
        AdminAddGroup: AdminAddGroup,
        AdminListGroups: AdminListGroups,

        // Domains 
        AdminAddDomain: AdminAddDomain,
        AdminListDomains: AdminListDomains,
        AdminDeleteDomain: AdminDeleteDomain, 

        // basic additions
        AddDomain: AddDomain,
        DeleteDomain: DeleteDomain,

        // Admin Images
        AdminListImages: AdminListImages,
        AdminDeleteImage: AdminDeleteImage, 
        AdminGetImage: AdminGetImage,
    }
    return (
        <AdminContext.Provider value={state}>{children}</AdminContext.Provider>
    )
}