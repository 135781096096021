import { Button, Container, List, ListItem, ListItemText, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { Fade } from "react-reveal"
import { BackToTools } from "../pagecomponents/BackToTools"
import { MessageC } from "../../contexts/MessageContext"
import { ADMINAPI, ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"


export const PasswordCheckPage = () => {
    const [ email, setEmail ] = useState('')
    const [ searched, setSearched ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ results, setResults ] = useState([])

    const dataP = useContext(DataP)

    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const checkKey = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            handleCheck()
        }
    }

    const handleCheck = async () => {
        setLoading(true)
        setSearched(email)

        // test permissioned
        try {
            dataP.RunPermissionedAPI(ADMINAPI, "/tools/password", {target: email}).then(res => {
                setResults(res.data.result)
                setLoading(false)
            })
        }catch (err) {
            msgC.showMessage(`${err}`, "#f00")
        }
        
    }   


    return (
        <div>
            <Fade>
                <Container style={{color: AppColors.SecondaryTextColor}}>
                   
                    <BackToTools />

                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                        <h2 className="lightBottomBord" style={{marginTop: '0px', flexGrow: '1', minHeight: '80px', display: 'flex', alignItems: 'flex-end'}}>Password Check</h2>
                        <div style={{minHeight: '80px'}}>
                            { loading && (
                                <div className="lds-ripple"><div></div><div></div></div>
                            )}
                        </div>
                    </div>

                    <div style={{padding: '15px', textAlign: 'center'}}>
                        This tool is provided to search by email address to see if any of the previous passwords have been leaked in public database breaches
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextField 
                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`
                                
                            }}
                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            onKeyDown={checkKey}
                            variant="filled" 
                            fullWidth 
                            label="Email Address"
                            onChange={handleChangeEmail} 
                            value={email} />
                        <Button variant="contained"
                            onClick={handleCheck}
                            style={{marginLeft: '10px'}}>Search</Button>
                    </div>


                    <div style={{paddingTop: '50px'}}>
                        <h3 className="lightBottomBord">Results</h3>
                        <div style={{
                                backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor, borderRadius: '10px', 
                                boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`, minHeight: '100px'}}>
                            { results !== undefined && (
                            <List>
                                { results.map((item, i) => (
                                    <ListItem key={i} style={{borderBottom: '1px solid #333'}}>
                                        <ListItemText style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <b>Password:</b> { item.split("|")[0].replace(`${searched}:`, "") }
                                            </div>
                                            <div>
                                                <b>Source:</b> { item.split("|")[1] }
                                            </div>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                                </List>
                            )}
                        </div>
                    </div>
                </Container>
            </Fade>
        </div>
    )
}


