import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { CameraAlt, CheckCircle, ChildCare, DeleteForever, LocationOn, PermDeviceInformation, QrCode2, RemoveCircle, Screenshot, TimesOneMobiledata } from "@mui/icons-material"
import { MessageC } from "../../contexts/MessageContext"
import { QRCode } from 'react-qrcode-logo'
import { Clipboard } from "@capacitor/clipboard"
import { Directory, Filesystem } from "@capacitor/filesystem"
import { LocalNotifications } from "@capacitor/local-notifications"
import { Capacitor } from "@capacitor/core"
import { Fade } from "react-reveal"
import { APPMOBILEPATH, APPNAME, ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"
import { EditCatchDropDialog } from "./EditCatchDrop"

export const CatchDrops = ({activeProject}) => {
    const { AppColors } = useContext(ThemeC)
    if (activeProject === undefined) {
        return <></>
    }
    return (
        <div style={{minHeight: '250px'}}>
            <Container style={{marginTop: '40px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h3 style={{
                        color: AppColors.SecondaryTextColor,
                        flexGrow: '1', marginRight: '10px', borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`}}>Drops</h3>
                    <div>
                        <CreateDrop activeProject={activeProject} />
                    </div>
                </div>

                <div>
                    <Grid container spacing={2} padding={2} style={{justifyContent: 'center'}}>
                        <DropGridBox activeProject={activeProject} />
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

const CreateDrop = ({activeProject}) => {
    const [ open, setOpen ] = useState(false)
    const [ dropURL, setDropURL ] = useState('')
    const [ minor, setMinor ] = useState(false)
    const [ domainSelected, setDomain ] = useState('')
    const [ errorText, setErrorText ] = useState('')
    const [ dropNote, setDropNote ] = useState('')
    const [ forcePermissions, setForcePermissions ] = useState(false)
    const [ oneShotDisable, setOneShotDisable ] = useState(false)
    const [ geoPermission, setGeoPermission ] = useState(false)
    const [ cameraPermission, setCameraPermission ] = useState(false)
    const [ forcePermText, setForcePermissionText ] = useState("")

    const [ screenshotPermission, setScreenshotPermission ] = useState(false)
    const handleChangeScreenshotPerm = (e) => setScreenshotPermission(e.target.checked)
    
    const { AppColors } = useContext(ThemeC)

    // stepper 
    const [ activeStep, setActiveStep ] = useState(1)

    const msgC = useContext(MessageC)
    const changeDropNote = (e) => setDropNote(e.target.value)

    const handleClose = () => {
        setOpen(false)
    }
    
    const handleChange = (e) => {
        setMinor(e.target.checked)
    }

    const changeActiveStep = (step) => {
        if (step === 2) {
            // check email
            var matches = dropURL.match(/(https?:\/\/.*)|(http?:\/\/.*)|(tel?:.*)/gi);
            if (matches === null || matches.length !== 1) {
                setErrorText("https, http, or tel URL not found")
                msgC.showMessage("Wrong URL Received", "#f00")
                return
            } else {
                setErrorText('')
            }
            setActiveStep(2)
        } else {
            setActiveStep(step)
        }
    }

    // new 
    const dataP = useContext(DataP)
    const handleCreateDrop = async () => {
        // create drop here 
        await dataP.createDrop(
            domainSelected, 
            dropURL, 
            minor, 
            forcePermissions, 
            oneShotDisable, 
            geoPermission, 
            cameraPermission, 
            screenshotPermission,
            dropNote,
            forcePermText,
        )

        msgC.showMessage("Created Drop", "#0f0")
        handleClose()

        // cleanup
        setDomain(dataP.state.domainsPermissioned[0])
        setDropURL('')
        setMinor(false)
        setScreenshotPermission(false)
        setForcePermissions(false)
        setOneShotDisable(false)
        setGeoPermission(false)
        setForcePermissionText("")
        setCameraPermission(false)
        setDropNote('')
    }

    const handleChangeCameraPerm = (e) => setCameraPermission(e.target.checked)
    const handleChangeGeoPerm = (e) => setGeoPermission(e.target.checked)
    const handleChangeOneShot = (e) => setOneShotDisable(e.target.checked)
    const handleChangeForcePerms = (e) => {
        if (e.target.checked) {
            setForcePermissionText("")
        }
        setForcePermissions(e.target.checked)
    }
    const handleChangeForcePermText = (e) => setForcePermissionText(e.target.value)

    // select first one 
    useEffect(() => {
        if (dataP.state.domainsPermissioned === undefined || dataP.state.domainsPermissioned.length === 0) {
            return
        }
        setDomain(dataP.state.domainsPermissioned[0])
    }, [dataP.state.domainsPermissioned])

    if (dataP.state.domainsPermissioned === undefined ) return <></>

    return (
        <div>
            <Tooltip title="Create Target Drop">
                <Button 
                    onClick={() => {
                        setActiveStep(1)
                        setOpen(true)
                    }}
                    style={{
                        backgroundColor: AppColors.DefaultBackgroundColor, 
                        color: AppColors.DefaultTextColor, 
                        boxShadow: `0 0 2px 2px ${AppColors.ButtonSuccessColor}`, minWidth: '120px',
                    }}>Create Drop</Button>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Create Drop</DialogTitle>
                <DialogContent>
                    { /* Step One */}
                    { activeStep === 1 && (
                        <Fade>
                        <div>
                            <p style={{marginBottom: '30px', textAlign: 'justify'}}>Create a drop to redirect the target to the URL provided below.  The link provided below will be the 
                                redirection endpoint once the target has interacted with the generated "Target Link".</p>
                            
                            <TextField fullWidth label="Drop Name" value={dropNote} onChange={changeDropNote} />
                            <FormHelperText style={{marginBottom: '20px'}}>Create a name for your drop</FormHelperText>

                            <Select value={domainSelected}  fullWidth 
                                variant="filled" onChange={(e) => setDomain(e.target.value)}>
                                { dataP.state.domainsPermissioned.map(item => (
                                    <MenuItem key={item} value={item}>{ item }</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText style={{marginBottom: '20px'}}>Select a redirect domain</FormHelperText>

                            <TextField fullWidth label="Target URL" value={dropURL} onChange={(e) => setDropURL(e.target.value)} />
                            <FormHelperText>Target URL must start with "https://", "http://", or "tel:" </FormHelperText>
                            <FormHelperText style={{paddingTop: '10px'}}>Examples:</FormHelperText>
                            <FormHelperText style={{textAlign: 'center'}}>https://www.google.com/maps/abcdef</FormHelperText>
                            <FormHelperText style={{textAlign: 'center'}}>tel:+19998881234,#000</FormHelperText>

                            { errorText !== '' ? (
                                <div style={{textAlign: 'center', color: AppColors.ButtonDeleteColor}}>
                                    {errorText}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        </Fade>
                    )}

                    { /* Step Two */}
                    { activeStep === 2 && (
                        <Fade>
                        <div>
                            <FormGroup style={{paddingTop: '15px'}}>
                                <FormLabel>Permissions Requested</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox checked={geoPermission} onChange={handleChangeGeoPerm} />
                                } label="Geo" />
                                <FormControlLabel control={
                                    <Checkbox checked={cameraPermission} onChange={handleChangeCameraPerm} />
                                } label="Camera" />

                                { /** Screenshot Additions 
                                <FormControlLabel control={
                                    <Checkbox checked={screenshotPermission} onChange={handleChangeScreenshotPerm} />
                                } label="Screenshot (DEV)" />
                                */}
                            </FormGroup>
                            <FormHelperText>The redirect will ask for the permissions selected above.</FormHelperText>
                            
                            { (geoPermission === true || cameraPermission === true) && (
                                <Fade>
                                    <div>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <p>Force Permissions</p>
                                            <Switch 
                                                checked={forcePermissions}
                                                onChange={handleChangeForcePerms}
                                                />
                                        </div>
                                        <FormHelperText>
                                            Force permissions will only redirect when they are approved for the above selection.  If the permissions
                                            are not granted, the user will be fed a blank page unless text is specified below.
                                        </FormHelperText>
                                    </div>
                                </Fade>
                            )}

                            { forcePermissions && (
                                <Fade>
                                    <div style={{padding: '10px'}}>
                                        <Tooltip title="Text that will be displayed until permissions are granted">
                                            <TextField label="Force Permission Text" fullWidth value={forcePermText} onChange={handleChangeForcePermText}/>
                                        </Tooltip>
                                    </div>
                                </Fade>
                            )}


                        </div> 
                        </Fade>
                    )}

                    { /* Step Three */}
                    { activeStep === 3 && (
                        <Fade>
                        <div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p>One Hit Disable</p>
                                <Switch 
                                    checked={oneShotDisable}
                                    onChange={handleChangeOneShot}
                                    />
                            </div>
                            <FormHelperText>One hit disable will ensure the drop is immediately disabled after capturing a result.</FormHelperText>

                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p>Minor</p>
                                <Switch 
                                    checked={minor}
                                    onChange={handleChange}
                                    />
                            </div>
                            <FormHelperText>Target is attributed to targeting a minor.</FormHelperText>
                        </div>
                        </Fade>
                    )}
                </DialogContent>
                <DialogActions>
                    { activeStep === 1 && (
                        <>
                            <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Close</Button>
                            <Button color="success" style={{margin: '5px'}} variant="outlined" onClick={() => changeActiveStep(2)}>Next</Button>
                        </>
                    )}
                    { activeStep === 2 && (
                        <>
                            <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Close</Button>
                            <Button variant="outlined" style={{margin: '5px'}} onClick={() => setActiveStep(1)}>Back</Button>
                            <Button color="success" style={{margin: '5px'}} variant="outlined" onClick={() => changeActiveStep(3)}>Next</Button>
                        </>
                    )}
                    { activeStep === 3 && (
                        <>
                            <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Close</Button>
                            <Button variant="outlined" style={{margin: '5px'}} onClick={() => setActiveStep(2)}>Back</Button>
                            <Button color="success" style={{margin: '5px'}} variant="contained" onClick={() => handleCreateDrop()}>Create</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}


const DropGridBox = ({activeProject}) => {
    const [ allDrops, setAllDrops ] = useState([])
        
    // NEW
    const newDataC = useContext(DataP)
    useEffect(() => {
        if (newDataC.state.drops === undefined) return 
        const updateDrops = async () => {
            const res = await newDataC.state.drops.filter(item => item.projectID === activeProject.id)
            setAllDrops(res)
        }
        updateDrops()
    }, [newDataC.state.drops, activeProject.id])

    return (
        <Grid container spacing={2} padding={2} style={{justifyContent: 'center'}}>
            { allDrops.map((curDrop) => (
                <IndividualDrop curDrop={curDrop} key={curDrop.id} />
            ))}
            
        </Grid>
    )
}

const IndividualDrop = ({curDrop}) => {
    const [ deleteOpen, setDeleteOpen ] = useState(false)
    const msgC = useContext(MessageC)

    // NEW
    const dataP = useContext(DataP)

    const { AppColors } = useContext(ThemeC)
    const handleDeleteClose = () => {
        setDeleteOpen(false)
    }
    const handleDeleteOpen = () => {
        setDeleteOpen(true)
    }

    const handleDisable = async(id) => {
        await dataP.disableDrop(id)
        //await newDataC.DisableDrop(id)
        //console.log("- disabled drop ")
        msgC.showMessage("Disabled Drop", "#f00")
    }

    const handleEnable = async(id) => {
        await dataP.enableDrop(id)
        //await newDataC.EnableDrop(id)
        //console.log("- enabled drop ")
        msgC.showMessage("Enabled Drop", "#0f0")
    }

    const handleDelete = async(curDrop) => {
        //console.log(curDrop)
        await dataP.deleteDrop(curDrop.id)
        //await newDataC.DeleteDrop(curDrop.id)
        //console.log("deleted drop")
        handleDeleteClose()
        msgC.showMessage("Deleted Drop", "#f00")
    }

    const copyTargetLink = (domain, redirect_url) => {
        const redirURL = `https://${domain}/${redirect_url}`

        // capacitor write to clipboard application
        try {
            if (Capacitor.isNativePlatform()) {
                const attemptBridge = async () => {
                    await Clipboard.write({string: redirURL})
                }
                attemptBridge()
            }
        } catch {}

        if (navigator.clipboard) {
            navigator.clipboard.writeText(redirURL);
        } else {
            var input = document.createElement('input');
            input.setAttribute('value', redirURL);
            document.body.appendChild(input);
            input.select();
            document.execCommand("copy");
            document.body.removeChild(input);
        }
        msgC.showMessage("Copied Target URL", "#ff0")
    }

    return (
        <Grid item xs={12} md={4} key={curDrop.id}>
            <div style={{
                boxShadow: curDrop.active ? `0 0 3px 3px ${AppColors.ButtonSuccessColor}` : `0 0 3px 3px ${AppColors.ButtonDeleteColor}`, 
                padding: '10px',
                borderRadius: "5px",
                transition: '0.5s',
                backgroundColor: AppColors.DefaultBackgroundColor,
                color: AppColors.DefaultTextColor,
                }}  className="dropGrid"
                >
                <div style={{                
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    }}>
                    <div>
                        <Tooltip title="Enable or Disable Drop">
                            { curDrop.active ? (
                                <IconButton style={{ backgroundColor: AppColors.DefaultBackgroundColor, border: `1px solid ${AppColors.DefaultBorderColor}`}} onClick={() => handleDisable(curDrop.id)}>
                                    <CheckCircle style={{color: AppColors.ButtonSuccessColor}}  />
                                </IconButton>
                            ) : (
                                <IconButton style={{ backgroundColor: AppColors.DefaultBackgroundColor, border: `1px solid ${AppColors.DefaultBorderColor}`}} onClick={() => handleEnable(curDrop.id)} >
                                    <RemoveCircle style={{color: AppColors.ButtonDeleteColor}} />
                                </IconButton>
                            )}
                        </Tooltip>
                    </div>

                    <div style={{textAlign: 'center'}}>
                        <DropIcons curDrop={curDrop} />
                    </div>


                    <div style={{
                        display: 'flex'
                    }}>

                        <EditCatchDropDialog catchDrop={curDrop} />
                        { /* */}

                        <div>
                            <Tooltip title="Delete Drop">
                                <IconButton style={{ backgroundColor: AppColors.DefaultBackgroundColor, border: `1px solid ${AppColors.DefaultBorderColor}`}} onClick={() => handleDeleteOpen()} >
                                    <DeleteForever style={{color: AppColors.ButtonDeleteColor}} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div style={{fontSize: '0.8rem', textOverflow: 'ellipsis', whiteSpace: "nowrap", overflow: 'hidden', paddingTop: '10px'}}>
                    { curDrop.drop_name && (
                        <div><b>Drop:</b><span style={{fontSize: '0.7rem'}}> { curDrop.drop_name }</span></div>
                    )}

                    <div><b>URL:</b><span style={{fontSize: '0.7rem'}}> { curDrop.redirect_url }</span></div>
                    <div><b>Target Domain:</b> { curDrop.domain }</div>
                    <div><b>Generated ID: </b> { curDrop.generated_id  }</div>
                </div>
                <Dialog
                    onClose={handleDeleteClose}
                    open={deleteOpen}
                    key={curDrop.id}>
                    <DialogTitle>Delete Drop</DialogTitle>
                    <DialogContent>This will delete the entire drop including the results attached for {curDrop.redirect_url}.</DialogContent>
                    <DialogActions>
                            <Button variant="outlined" onClick={() => handleDeleteClose()}>Cancel</Button>
                            <Button color="error" variant="contained" onClick={() => handleDelete(curDrop)}>Delete</Button>
                    </DialogActions>
                </Dialog>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '10px', alignItems: 'center'}}>
                    <Tooltip title="Copy URL to send to Target">
                        <span>
                        <Button style={{
                                backgroundColor: AppColors.DefaultBackgroundColor, 
                                color: curDrop.active ? AppColors.DefaultTextColor : AppColors.ButtonDisabledColor,
                                boxShadow: `0 0 2px 2px ${AppColors.DefaultBoxShadowColor}`
                            }}
                            disabled={!curDrop.active}
                            onClick={() => copyTargetLink(curDrop.domain, curDrop.generated_id)}
                        >Copy Target Link</Button>
                        </span>
                    </Tooltip>
                    <DownloadQR targetUrl={`https://${curDrop.domain}/${curDrop.generated_id}`} disabled={!curDrop.active} />
                </div>
            </div>
        </Grid>
    )
}

const DownloadQR = ({targetUrl, disabled}) => {
    const [ open, setOpen ] = useState(false)
    const [ filename, setFilename ] = useState('')

    const handleOpen  = () => { setOpen(true) }
    const handleClose = () => { setOpen(false)}

    const { AppColors } = useContext(ThemeC)
    // Save | Download image
    function downloadImage(data, fn) {
        var a = document.createElement('a');
        a.href = data;
        a.download = fn;
        document.body.appendChild(a);
        a.click();
    }

    const saveImage = () => {
        var canvas = document.getElementById("react-qrcode-logo"); 
        var image = canvas.toDataURL("image/png", 1);  // here is the most important part because if you dont replace you will get a DOM 18 exception.
        
        const writeQrImage = async (filename, imageD) => {
            if (!filename.endsWith(".png")) {
                filename = filename + ".png"
            }
            await Filesystem.writeFile({
                path: `${APPMOBILEPATH}/${filename}`,
                data: imageD,
                directory: Directory.Documents,
            });
        }

        var fn = filename
        if (fn === "") {
            fn = "qrlink.png" 
        }

        downloadImage(image, fn); // it will save locally
        if (Capacitor.isNativePlatform()) {
            writeQrImage(fn, image)
            const genId = Math.random() * 100
            const noti = {
                title: APPNAME,
                body: `QR Located in Documents/${APPMOBILEPATH}`,
                id: genId,
                largeIcon: "icon",
                smallIcon: "icon_small",
            }
            LocalNotifications.schedule({
                notifications: [noti],
            })
        }
     
    }

    return (
        <div>
            <span>
                <IconButton style={{border: `1px solid ${AppColors.SecondaryBorderColor}`}} onClick={handleOpen} disabled={disabled}>
                    <Tooltip title="Download QR Image for Target Link">
                            <QrCode2 style={{color: disabled ? AppColors.ButtonDisabledColor : AppColors.DefaultTextColor, transition: '0.5s'}} />
                    </Tooltip>
                </IconButton>
            </span>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Target URL QR Code</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <div id="qrCodeCont">
                            <QRCode 
                                value={targetUrl}    
                            /> 
                        </div>
                        <div style={{paddingTop: '20px'}}>
                            <TextField 
                                label="Filename"
                                value={filename} onChange={(e) => {setFilename(e.target.value)}} />
                        </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                        <Button variant="contained" onClick={saveImage}>Download</Button>
                    </DialogActions>
            </Dialog>
        </div>
    )
}


export const DropIcons = ({curDrop}) => {
    const { AppColors } = useContext(ThemeC)

    const noOptions = !curDrop.one_shot_disable && !curDrop.force_permissions && !curDrop.perm_geo && !curDrop.perm_camera

    return (
        <div style={{textAlign: 'center', borderRadius: '7px', border: `1px solid ${AppColors.DefaultBorderColor}`, padding: '5px', 
            display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            { curDrop.one_shot_disable && (
            <Tooltip title="One Hit Disable option is enabled">
                <TimesOneMobiledata  />
            </Tooltip>
            )}
            { curDrop.force_permissions && (
            <Tooltip title="Force Permissions option is enabled">
                <PermDeviceInformation  />
            </Tooltip>
            )}
            { curDrop.minor && (
            <Tooltip title="Drop is minor related">
                <ChildCare  />
            </Tooltip>
            )}
            { curDrop.perm_geo && (
            <Tooltip title="Geo Permission Requested">
                <LocationOn  />
            </Tooltip>
            )}
            { curDrop.perm_camera && (
            <Tooltip title="Camera Permission Requested">
                <CameraAlt  />
            </Tooltip>
            )}
            { curDrop.perm_screen && (
            <Tooltip title="Screenshot Permission Requested">
                <Screenshot  />
            </Tooltip>
            )}
            { noOptions && (
                <>Basic Redirect</>
            )}
        </div>
    )
}