import { Edit, SaveOutlined } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"
import { DropIcons } from "./CatchDrops"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"



export const EditCatchDropDialog = ({catchDrop}) => {
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const { AppColors } = useContext(ThemeC)
    const dataP = useContext(DataP)
    const msgP = useContext(MessageC)

    // one hit disable render 
    const changeOneHit = (permToChange) => {
        dataP.changeDropOneHit(catchDrop.id, permToChange)
    }
    const renderOneHit = () => (
        <div>
            <Tooltip title={catchDrop.one_shot_disable ? "One Hit Option Enabled" : "One Hit Option Disabled"}>
                <div style={{display: 'flex', borderBottom: '1px solid #aaa'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Switch inputProps={{ 'aria-label': 'controlled' }} checked={catchDrop.one_shot_disable} onChange={(e) => changeOneHit(e.target.checked)} />
                    </div>
                    <div style={{padding: '10px'}}>
                        { catchDrop.one_shot_disable ? "One Hit Option Enabled" : "One Hit Option Disabled" }
                    </div>
                </div>
            </Tooltip>
        </div>
    )

    // permission render
    const changeGeoPerm = (permToChange) => {
        dataP.changeDropGeo(catchDrop.id, permToChange)
    }
    const changeCameraPerm = (permToChange) => {
        dataP.changeDropCamera(catchDrop.id, permToChange)
    }
    const renderPermissions = () => (
        <div>
            <Tooltip title={catchDrop.perm_geo ? "Geo Requested" : "Geo not requested"}>
                <div style={{display: 'flex', borderBottom: '1px solid #aaa'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Switch inputProps={{ 'aria-label': 'controlled' }} checked={catchDrop.perm_geo} onChange={(e) => changeGeoPerm(e.target.checked)} />
                    </div>
                    <div style={{padding: '10px'}}>
                        { catchDrop.perm_geo ? "Geo Requested" : "Geo not requested" }
                    </div>
                </div>
            </Tooltip>
            <Tooltip title={catchDrop.perm_camera ? "Camera Requested" : "Camera not requested"}>
                <div style={{display: 'flex', borderBottom: '1px solid #aaa'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Switch inputProps={{ 'aria-label': 'controlled' }} checked={catchDrop.perm_camera} onChange={(e) => changeCameraPerm(e.target.checked)} />
                    </div>
                    <div style={{padding: '10px'}}>
                        { catchDrop.perm_camera ? "Camera Requested" : "Camera not requested" }
                    </div>
                </div>
            </Tooltip>
        </div>
    )

    // force perm additions
    const changeDropForcePerms = (permToChange) => {
        dataP.changeDropPerms(catchDrop.id, permToChange)
    }
    const [ forcePermText, setForcePermText ] = useState("")
    useEffect(() => {
        setForcePermText(catchDrop.force_permission_text)
    }, [catchDrop.force_permission_text])
    const saveFormPerm =  async () => {
        await dataP.changeForcePermText(catchDrop.id, forcePermText)
        msgP.showMessage("Force Permission Text Updated", "#0f0")
    }

    const renderForcePermissionAdjust = () => (
        <div>
            <Tooltip title={catchDrop.force_permissions ? "Force Permissions Enabled" : "Permissions not enforced"}>
                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Switch inputProps={{ 'aria-label': 'controlled' }} checked={catchDrop.force_permissions} onChange={(e) => changeDropForcePerms(e.target.checked)} />
                    </div>
                    <div style={{padding: '10px'}}>
                        { catchDrop.force_permissions ? "Force Permissions Enabled" : "Force Permissions Disabled" }
                    </div>
                </div>
            </Tooltip>

            { catchDrop.force_permissions && (
                <div style={{display: 'flex', padding: '10px'}}>
                    <Fade>
                        <div style={{display: 'flex', alignItems: 'center', paddingTop: '10px'}}>
                            <Tooltip title={"Text to show if permissions are NOT enabled" } >
                                <TextField label="Force Permission Text" value={forcePermText} onChange={(e) => setForcePermText(e.target.value)} />
                            </Tooltip>
                            <Tooltip title="Save">
                                <IconButton color="success" onClick={saveFormPerm}>
                                    <SaveOutlined />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Fade>
                </div>
            )}
        </div>
    )


    return (
        <div>
            <div style={{marginRight: '5px'}}>
                <IconButton onClick={handleOpen} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                    <Edit style={{color: AppColors.DefaultTextColor }} />
                </IconButton>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Edit Drop {catchDrop.drop_name}</DialogTitle>
                <DialogContent>
                    <div style={{paddingBottom: '10px'}}>
                        <DropIcons curDrop={catchDrop} />
                    </div>

                    <ChangeUrlDialog catchDrop={catchDrop} />

                    <h4 style={{textAlign: 'center'}}>Drop Options</h4>
                    { renderPermissions() }
                    { renderForcePermissionAdjust() }

                    <h4 style={{textAlign: 'center'}}>Additional Options</h4>
                    { renderOneHit() }

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const ChangeUrlDialog = ({catchDrop}) => {
    const [ open, setOpen ] = useState(false)
    const handleDialogOpen = () => setOpen(true)
    const handleDialogClose = () => setOpen(false)

    const [ currentURL, setCurrentURL ] = useState("")
    const handleChangeURL = (e) => setCurrentURL(e.target.value)

    useEffect(() => {
        setCurrentURL(catchDrop.redirect_url)
    }, [catchDrop.redirect_url])

    const msgC = useContext(MessageC)
    const dataP = useContext(DataP)
    const updateSave = () => {
        dataP.changeDropUrl(catchDrop.id, currentURL)
        msgC.showMessage("Updated URL", "#0f0")
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                <Button variant="contained" color="warning" onClick={handleDialogOpen}>Change URL</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                >
                    <DialogTitle>Change URL</DialogTitle>    
                    <DialogContent>
                        <p>This will update the redirection on this drop to the URL below.</p>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <TextField value={currentURL} onChange={handleChangeURL} fullWidth />
                            <Button variant="contained" color="success" style={{marginLeft: '10px'}} onClick={updateSave}>Save</Button>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleDialogClose}>Close</Button>
                    </DialogActions>
            </Dialog>
        </div>
    )
}