import { Button } from "@mui/material"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { ThemeC } from "../../constants/appbase"


export const BackToTools = () => {
    const { AppColors } = useContext(ThemeC)
    return (
        <div style={{paddingTop: '20px', justifyContent: 'flex-end', display: 'flex'}}>
            <Link to="/tools" className="destyle">
                <Button variant="outlined" style={{color: AppColors.DefaultTextColor , backgroundColor: AppColors.DefaultBackgroundColor}}>Back to Tools</Button>
            </Link>
        </div>
    )
}