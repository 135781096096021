import { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
import { CatchDrop, CatchHit, GeneratedID, Project, Tool } from "../models";
import { API, Auth, DataStore } from "aws-amplify";
import { Button, ListItemButton, ListSubheader, Snackbar, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ADMINAPI, APPNAME, ThemeC } from "../constants/appbase";
import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { ExportC } from "../components/generateCSV";
import OpenAI from "openai";

// admin group name 
const AdminGroupName = "Admin"

// new context
export const DataP = createContext()

// data reducer
const dataReducer = (state, action) => {
    switch (action.type) {
        case "projects_changed":
            return {
                ...state,
                projects: action.allProjects,
            }
        case "hits_changed":
            return {
                ...state,
                hits: action.allHits,
            }
        case "drops_changed":
            return {
                ...state,
                drops: action.allDrops,
            }
        case "tools_changed":
            // open ai
            const openAITools = action.allTools.filter(item => item.tool_name === "ChatGPT");
            var openAi = undefined
            if (openAITools.length > 0) {
                openAi = new OpenAI({
                    apiKey: openAITools[0].tool_api_key, // This is also the default, can be omitted
                    dangerouslyAllowBrowser: true,
                });
            }
            return {
                ...state,
                openAI: openAi,
                tools: action.allTools,
            }
        case "domains_permissioned_changed":
            return {
                ...state,
                domainsPermissioned: action.domains,
            }
        case "user_changed":
            // extract groups for permissions
            var allGs = [];
            if (action.newUser.signInUserSession.accessToken.payload["cognito:groups"] !== undefined) {
                allGs = action.newUser.signInUserSession.accessToken.payload["cognito:groups"];
            }
            return {
                ...state,
                user: action.newUser,
                userGroups: allGs,
            }
        case "active_changed":
            return {
                ...state,
                activeProjectId: action.newProjectId,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

const initialState = {
    // local state management
    projects: [],
    drops: [],
    hits: [],
    tools: [],

    // domains 
    domainsPermissioned: [],

    // user data
    userGroups: [],
    user: {},

    // active project
    activeProjectId: "",

    // open ai tools
    openAI: {},
}

export const NewDP = ({children, user}) => {
    const [ state, reducerDispatch ] = useReducer(dataReducer, initialState)
    const { AppColors } = useContext(ThemeC)

    // hit alert settings
    const [ hitAlert, setHitAlert ] = useState("")
    const [ hitOpen, setHitOpen ] = useState(false)

    const nav = useRef(useNavigate());

    useEffect(() => {
        // start datastore
        //DataStore.start()

        // get permissioned domain list
        const getDomainListPermissioned = async () => {
            return await RunPermissionedAPI(ADMINAPI, "/tools/domainlist", {}).then(res => {
                if (Array.isArray(res.data)) {
                    return res.data.map(item => item.String)
                } else {
                    console.log(`DomainList Error: ${res.data}`)
                    return []
                }
            })
        }
        // get permissioned list
        getDomainListPermissioned().then(res => {
            reducerDispatch({type: "domains_permissioned_changed", domains: res})
        })

        // project subscription 
        const projSub = DataStore.observeQuery(Project).subscribe((res) => {
            reducerDispatch({type: "projects_changed", allProjects: res.items})
        })

        // catch drop observaction 
        const dropSub = DataStore.observeQuery(CatchDrop).subscribe((res) => {
            reducerDispatch({type: "drops_changed", allDrops: res.items})
        })
        // catch hit observaction 
        const hitSub = DataStore.observeQuery(CatchHit).subscribe((res) => {
            reducerDispatch({type: "hits_changed", allHits: res.items})
        })
        // tools observaction 
        const toolsSub = DataStore.observeQuery(Tool).subscribe((res) => {
            reducerDispatch({type: "tools_changed", allTools: res.items})
        })
        // get user
        const getUser = async () => {
            const user = await Auth.currentAuthenticatedUser();
            reducerDispatch({type: "user_changed", newUser: user})
        }
        getUser()

        // hit subscription for new hits 
        const getHitLink = (hitObj) => (
            <>
                <Link to={"/result/" + hitObj.catchdropID + "/" + hitObj.id} className="destyle" onClick={() => setHitOpen(false)}>
                    Hit Received: {hitObj.catch_ip} 
                    <Button color="success" variant="contained" style={{marginLeft: '10px'}}>View</Button>
                </Link>
            </>
        )
        const showHitMessage = (hitObj) => {
            // Mobile alert within app
            try {
                if (Capacitor.isNativePlatform()) {
                    const hitUrl = "/result/" + hitObj.catchdropID + "/" + hitObj.id;
                    const genId = Math.floor(Math.random() * 2147483647)
                    const noti = {
                        title: `${APPNAME} Hit Received`,
                        body: `Hit Received: ${hitObj.catch_ip}`,
                        id: genId,
                        largeIcon: "icon",
                        smallIcon: "icon_small",
                        allowWhileIdle: true,
                        extra: {
                            url: hitUrl
                        },
                    }
                    LocalNotifications.schedule({
                        notifications: [noti],
                    })
                } else {
                    // open notification
                    setHitAlert(getHitLink(hitObj))
                    setHitOpen(true)
                }
            } catch (err){
                console.log(err)
            }
        }
        
        LocalNotifications.addListener("localNotificationActionPerformed", (event) => {
            // on click
            if (event.actionId === "tap") {
                nav.current(event.notification.extra.url)
            }
        })

        const hitAddedSubscription = DataStore.observe(CatchHit).subscribe(msg => {
            if (msg.opType === "INSERT") {
                const hitObj = msg.element;
                showHitMessage(hitObj)
            }
        })

        return () => {
            projSub.unsubscribe();
            dropSub.unsubscribe();
            hitSub.unsubscribe();
            toolsSub.unsubscribe();
            hitAddedSubscription.unsubscribe();
        }
    }, [user])

    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////// Drop Helpers        //////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    const createDrop = async (domain, url, minor, forcePermissions, oneShotDisable, geoPerm, cameraPerm, screenPerm, dropNote, forcePermText) => {
        while (true) {
            const genIDString = genHash()
            const res = await DataStore.query(GeneratedID) // query all gen identifiers
            const otherFound = res.filter(item => item.generated === genIDString)
            if (otherFound.length === 0) {
                // create and save 
                const genID = await DataStore.save(
                    new GeneratedID({
                        generated: genIDString,
                    })
                )
                await DataStore.save(
                    new CatchDrop({
                        domain: domain,
                        active: true,
                        minor: minor,
                        redirect_url: url,
                        GeneratedID: genID, // need for multiple checks across scalable accts
                        generated_id: genIDString,
                        projectID: state.activeProjectId,
                        force_permissions: forcePermissions,
                        one_shot_disable: oneShotDisable,
                        perm_geo: geoPerm,
                        perm_camera: cameraPerm,
                        perm_screen: screenPerm,
                        drop_name: dropNote,
                        additional_payload: "",
                        force_permission_text: forcePermText,
                    })
                )
                break
            }
        }
    }
    // get drop helper
    const getDrop = async (id) => {
        return await DataStore.query(CatchDrop, id).then(res => {
            return res
        })
    }
    // enable drop 
    const enableDrop = async (id) => {
        getDrop(id).then(async drop => {
            // got project (update emails)
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.active = true
                    })
                )
            }
        })
    }
    // change force permissions (toggle)
    const changeDropPerms = (id, permission) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.force_permissions = permission
                    })
                )
            }
        })
    }

    // change drop url 
    const changeDropUrl = (id, newUrl) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.redirect_url = newUrl
                    })
                )
            }
        })
    }

    // change drop one hit 
    const changeForcePermText = (id, newText) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.force_permission_text = newText
                    })
                )
            }
        })
    }

    // change drop one hit 
    const changeDropOneHit = (id, permission) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.one_shot_disable = permission
                    })
                )
            }
        })
    }

    // change force permissions (toggle)
    const changeDropGeo = (id, permission) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.perm_geo = permission
                    })
                )
            }
        })
    }

    const changeDropCamera = (id, permission) => {
        getDrop(id).then(async drop => {
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.perm_camera = permission
                    })
                )
            }
        })
    }

    // disable drop 
    const disableDrop = async (id) => {
        getDrop(id).then(async drop => {
            // got project (update emails)
            if (drop) {
                return await DataStore.save(
                    CatchDrop.copyOf(drop, updated => {
                        updated.active = false
                    })
                )
            }
        })
    }
    
    // delete drop 
    const deleteDrop = async (id) => {
        return await getDrop(id).then(async drop => {
            return await DataStore.delete(CatchDrop, drop)
        })
    }
    // get drop redirect Url 
    const getDropRedirectURL = (catchDropId) => {
        if (state.drops === undefined) return 
        const res = state.drops.filter(item => item.id === catchDropId)
        if (res.length === 0) return 
        return res[0].redirect_url
    }
    // get catchdrop name from id 
    const getDropName = (catchDropId) => {
        if (state.drops.length === 0) return ""
        const filtered = state.drops.filter(item => item.id === catchDropId)
        if (filtered.length === 0) return ""
        return filtered[0].drop_name
    }
    // get catchdrop name from id 
    const getDropMinorStatus = (catchDropId) => {
        if (state.drops.length === 0) return ""
        const filtered = state.drops.filter(item => item.id === catchDropId)
        if (filtered.length === 0) return ""
        return filtered[0].minor
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////// Project Helpers        //////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    // generate project report
    const ExpC = useContext(ExportC)
    const generateProjectReport = async () => {
        if (state.activeProjectId === undefined) return 
        await DataStore.query(Project, state.activeProjectId).then(async res => {
            await ExpC.generateDataLine(res)
        })
    }
    const generateFullProjectsReport = async () => {
        if (state.projects === undefined) return 
        await DataStore.query(Project).then(async res => {
            await ExpC.generateFullCSV(res)
        })
    }
    
    // change active project
    const changeActiveProject = (id) => {
        reducerDispatch({type: "active_changed", newProjectId: id})
    }

    // create project 
    const createProject = async (title, description) => {
        const res = await DataStore.save(
            new Project({
                project_name: title,
                project_description: description,
                project_emails: [state.user.attributes.email],
                notifications_enabled: true, // default notifications
            })
        )
        console.log(res)
        return res 
    }
    // update project notifications 
    const updateProjectNotifications = async (id, notificationState) => {
        const original = await DataStore.query(Project, id)
        return await DataStore.save(
            Project.copyOf(original, updated => {
                updated.notifications_enabled = notificationState
            })
        )
    }
    // delete project
    const deleteProject = async (id) => {
        await getProject(id).then(async proj => {
            return await DataStore.delete(proj)
        })
    }
    // get project helper
    const getProject = async (id) => {
        return await DataStore.query(Project, id)
    }
    // update project 
    const updateProject = async (id, title, desc) => {
        getProject(id).then(async proj => {
            if (proj) {
                const updatedProject = await DataStore.save(
                    Project.copyOf(proj, updated => {
                        updated.project_name = title
                        updated.project_description = desc
                    })
                )
                return updatedProject
            }
        })
    }
    // add email to project 
    const addEmailToProject = async (id, email) => {
        return await getProject(id).then(async proj => {
            if (proj) {
                return await DataStore.save(
                    Project.copyOf(proj, updated => {
                        updated.project_emails = [...proj.project_emails, email]
                    })
                )
            }
        })
    }
    // remove email from project 
    const removeEmailFromProject = async (id, email) => {
        return await getProject(id).then(async proj => {
            // got project (update emails)
            if (proj) {
                return await DataStore.save(
                    Project.copyOf(proj, updated => {
                        updated.project_emails = proj.project_emails.filter(item => item !== email)
                    })
                )
            }
        })
    }
    // render if admin 
    const RenderIfAdmin = ({children}) => {
        if (state.userGroups === undefined) return <></>
        if (CheckUserGroup(AdminGroupName) === true) {
            return <>{ children }</>
        } else {
            return <></>
        }
    }
    // render if not admin
    const RenderIfNotAdmin = ({children}) => {
        if (state.userGroups === undefined) return <></>
        if (CheckUserGroup(AdminGroupName) === false) {
            return <>{ children }</>
        } else {
            return <></>
        }
    }

    // dynamic rendering
    const RenderIfInGroup = ({group, children}) => {
        if (state.userGroups === undefined || state.userGroups.length === 0) return <></>
        if (CheckUserGroup(group)) {
            return <>{ children }</>
        } else {
            return <></>
        }
    }
    // check user group
    const CheckUserGroup = (group) => {
        if (state.userGroups === undefined || state.userGroups.length === 0) { return false }
        for (let i=0;i<state.userGroups.length;i++){
            if (state.userGroups[i] === group) {
                return true 
            }
        }
        return false 
    }
    // navbar project list rendering
    const RenderProjectList = (callback) => {
        if (state.projects === undefined || state.projects.length === 0) return <></>
        return (
            <>
                <ListSubheader component={"div"} 
                style={{
                    backgroundColor: AppColors.SecondaryBackgroundColor,
                    color: AppColors.SecondaryLightText,
                    marginTop: '25px',
                    textAlign: 'right',
                    borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`,
                }}>PROJECTS</ListSubheader>
                { state.projects.map(project => (
                    <Link to={`/projects/${project.id}`} key={project.id} className='destyle' onClick={() => {callback(); }} >
                        <ListItemButton style={{borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`}}>
                            <Typography textAlign="center" style={{color: AppColors.SecondaryTextColor}}>{ project.project_name }</Typography>
                        </ListItemButton>
                    </Link>
                ))}
            </>
        )
    }

    // run permissioned api
    const RunPermissionedAPI = async (apiName, apiPath, queryParams) => {
        // get creds
        const user = await Auth.currentAuthenticatedUser();
        const myInit = {
            headers: {
                "Authorization": `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            }, 
            response: true,
            queryStringParameters: queryParams
        }
        return API.get(apiName, apiPath, myInit).then(res => {
            return res 
        })
    }
    const UpdateDomains = async () => {
        return await RunPermissionedAPI(ADMINAPI, "/tools/domainlist", {}).then(res => {
            var domainList = res.data.map(item => item.String)
            reducerDispatch({type: "domains_permissioned_changed", domains: domainList})
        }) 
    }

    const GetImage = async (imageKeyName) => {
        return await RunPermissionedAPI(ADMINAPI, "/image/get", {
            url: `/${imageKeyName}`,
        }).then(res => {
            return res.data
        })
    }



    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////


    // external state 
    const externalState = {
        // state
        state: state,

        // active project
        activeProjectId: state.activeProjectId, 
        changeActiveProject: changeActiveProject,

        // reports
        generateProjectReport: generateProjectReport,
        generateFullProjectsReport: generateFullProjectsReport,

        // project functions 
        updateProject: updateProject,
        addEmailToProject: addEmailToProject,
        removeEmailFromProject: removeEmailFromProject,
        updateProjectNotifications: updateProjectNotifications,
        deleteProject: deleteProject,
        createProject: createProject,

        // drop functions 
        createDrop: createDrop,
        enableDrop: enableDrop,
        disableDrop: disableDrop,
        deleteDrop: deleteDrop,
        getDropRedirectURL: getDropRedirectURL,
        changeDropPerms: changeDropPerms,

        // drop helpers
        getDropMinorStatus: getDropMinorStatus,
        getDropName: getDropName,
        // additional helpers (edit dialog)
        changeDropCamera: changeDropCamera,
        changeDropGeo: changeDropGeo,
        changeDropOneHit: changeDropOneHit,
        changeForcePermText: changeForcePermText,
        changeDropUrl: changeDropUrl,

        // navbar render
        RenderProjectList: RenderProjectList,
        RenderIfInGroup: RenderIfInGroup,

        RenderIfAdmin: RenderIfAdmin,
        RenderIfNotAdmin: RenderIfNotAdmin,

        // domain helpers
        UpdateDomains: UpdateDomains,

        // admin api helper
        RunPermissionedAPI: RunPermissionedAPI,

        // Get Image Helper
        GetImage: GetImage,
    }

    return (
        <DataP.Provider value={externalState}>
            {children}

            { /* Realtime Notifications */}
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    style: {
                        border: `1px inset #0f0a`,
                    }
                }}
                open={hitOpen}
                message={hitAlert}
                onClose={() => setHitOpen(false)}
                autoHideDuration={6000} />
        </DataP.Provider>
    )
}



////////////////////////////////////////////////////
// generation of ID (shortened)
////////////////////////////////////////////////////
const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
const genHash = () => {
    return makeid(12);
}