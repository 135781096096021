import { useContext } from "react"
import { ThemeC } from "../constants/appbase"

export const FooterBar = () => {
    const { AppColors } = useContext(ThemeC)
    return (
        <div style={{
            display: 'flex', 
            color: AppColors.DefaultTextColor,
            backgroundColor: AppColors.DefaultBackgroundColor, 
            justifyContent: 'center', 
            padding: '5px', 
            borderTop: `1px solid ${AppColors.DefaultBorderColor}`,
            fontSize: '0.8rem',
            marginTop: '50px'}}>
            &copy; Copyright. All Rights Reserved.
        </div>
    )
}