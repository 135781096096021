import { Button, Container, IconButton, List, ListItem, ListItemText, TextField, Tooltip } from "@mui/material"
import { Fade } from "react-reveal"
import { useContext, useState } from "react";
import { MessageC } from "../../contexts/MessageContext";
import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import { Download } from "@mui/icons-material";
import { BackToTools } from "../pagecomponents/BackToTools";
import { ADMINAPI, ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";



export const DomainLookupPage = () => {
    const [ domain, setDomain ] = useState('')
    const [ domainInfo, setDomainInfo ] = useState(undefined)

    const dataP = useContext(DataP)
    
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    const queryDomainInfo = () => {
        // needed for regex
        const res = domain.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g) //eslint-disable-line 
        if (res === null || res.length === 0) {
            msgC.showMessage("No domain found", "#ff0")
            return 
        }

        const domainToTest = res[0].replace("http://", "").replace("https://", "")
        setDomain(domainToTest)

        // test permissioned
        try {
            dataP.RunPermissionedAPI(ADMINAPI, "/tools/domaininfo", {d: domainToTest}).then(res => {
                setDomainInfo(res.data)
            })
        }catch (err) {
            msgC.showMessage(`${err}`, "#f00")
        }
    }

    const saveData = () => {
        // get map 
        var node = document.getElementById('htmlImageCont');
        htmlToImage.toPng(node, {backgroundColor: '#000'})
            .then(function (dataUrl) {
                download(dataUrl, 'iplookup.png');
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    const onChange = (e) => {
        setDomain(e.target.value)
    }

    const onCheckKey = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            queryDomainInfo()
        }
    }

    return (
        <div style={{paddingBottom: '150px', color: AppColors.SecondaryTextColor }}>
            <Fade>
                <Container id="htmlImageCont" style={{paddingBottom: '200px'}}>

                    <BackToTools />

                    <div style={{display: 'flex', justifyContent: 'center', alignItems:"center"}}>
                        <h2 className="lightBottomBord" style={{flexGrow: '1'}}>Domain Lookup</h2>
                        <Tooltip title="Download Report">
                            <IconButton onClick={saveData} style={{border: `1px solid ${AppColors.DefaultBorderColor}`}}>
                                <Download style={{color: AppColors.SecondaryTextColor}} />
                            </IconButton>
                        </Tooltip>
                    </div>


                    <div style={{padding: '10px', textAlign: 'justify'}}>
                        Enter a domain address (ex. google.com) in the box below and click "Search" to query for information regarding the domain.  
                    </div>


                    <div style={{padding: '10px', alignItems: 'center', display: 'flex'}}>
                        <TextField 
                            label="Domain Name" 
                            variant="filled" 
                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`
                                
                            }}
                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            value={domain}
                            onChange={onChange}
                            onKeyDown={onCheckKey}
                            fullWidth
                        />
                        <div style={{marginLeft: '10px'}}>
                            <Button variant="contained" onClick={queryDomainInfo}>Search</Button>
                        </div>
                    </div>

                    <div style={{marginTop: '50px'}}>
                        <h2 className="lightBottomBord">Results</h2>
                        { domainInfo !== undefined ? (
                            <div style={{backgroundColor: '#000', borderRadius: '10px', boxShadow: '0 0 2px 3px #333'}}>
                                <List>
                                { domainInfo["A_RECORD"] !== null && (
                                    <ListItem style={{flexDirection: 'column'}}>
                                        <ListItemText><h3>A (Resolves To) Records</h3></ListItemText>
                                        <ListItemText>
                                            <ul>
                                                { domainInfo["A_RECORD"].map((item, i) => (
                                                    <li key={`${i}`}>{ item }</li>
                                                ))}
                                            </ul>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                { domainInfo["MX_RECORD"] !== null && (
                                    <ListItem style={{flexDirection: 'column'}}>
                                        <ListItemText><h3>MX (Mail Server) Records</h3></ListItemText>
                                        <ListItemText>
                                            <ul>
                                                { domainInfo["MX_RECORD"].map((item, i) => (
                                                    <li key={`${i}`}>{ item }</li>
                                                ))}
                                            </ul>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                { domainInfo["NS_RECORD"] !== null && (
                                    <ListItem style={{flexDirection: 'column'}}>
                                        <ListItemText><h3>NS (Name Server) Records</h3></ListItemText>
                                        <ListItemText>
                                            <ul>
                                                { domainInfo["NS_RECORD"].map((item, i) => (
                                                    <li key={`${i}`}>{ item }</li>
                                                ))}
                                            </ul>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                { domainInfo["TXT_RECORD"] !== null && (
                                    <ListItem style={{flexDirection: 'column', flexWrap: 'wrap', wordBreak: "break-all"}}>
                                        <ListItemText><h3>TXT (Text Records) Records</h3></ListItemText>
                                        <ListItemText>
                                            <ul>
                                                { domainInfo["TXT_RECORD"].map((item, i) => (
                                                    <li key={`${i}`}>{ item }</li>
                                                ))}
                                            </ul>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                </List>
                            </div>
                        ) : (<>No Results</>)}
                    </div>

                </Container>
            </Fade>
        </div>
    )
}