import { PresetImages } from './QRImages';


export const QrPresets = {
    cashapp: {
        testURL: "https://cash.app",
        dots: "dots", // squares
        error: "H",
        qrSize: 320,
        qrPadding: 20,
        qrEyeRounding: 27,
        logo: {
            height: 50,
            width: 50,
            padding: 14,
            opacity: 100,
            removeQr: true,
            style: "square", // circle
            imageData: PresetImages.cashapp,
        },
        colors: {
            foreground: "#000000",
            background: "#FFFFFF",
            eyeColor: "#000000"
        }
    },
    venmo: {
        testURL: "https://venmo.com",
        dots: "dots", // squares
        error: "H",
        qrSize: 320,
        qrPadding: 20,
        qrEyeRounding: 0,
        logo: {
            height: 50,
            width: 50,
            padding: 13,
            opacity: 100,
            removeQr: true,
            style: "square", // circle
            imageData: PresetImages.venmo,
        },
        colors: {
            foreground: "#008cff",
            background: "#FFFFFF",
            eyeColor: "#008cff"
        }
    },
    zelle: {
        testURL: "https://zellepay.com",
        dots: "dots", // squares
        error: "H",
        qrSize: 320,
        qrPadding: 20,
        qrEyeRounding: 0,
        logo: {
            height: 36,
            width: 145,
            padding: 10,
            opacity: 100,
            removeQr: true,
            style: "square", // circle
            imageData: PresetImages.zelle,
        },
        colors: {
            foreground: "#ffffff",
            background: "#6c1cd3",
            eyeColor: "#ffffff"
        }
    },
    paypal: {
        testURL: "https://paypal.com",
        dots: "dots", // squares
        error: "H",
        qrSize: 320,
        qrPadding: 20,
        qrEyeRounding: 0,
        logo: {
            height: 100,
            width: 100,
            padding: 0,
            opacity: 100,
            removeQr: false,
            style: "square", // circle
            imageData: PresetImages.paypal,
        },
        colors: {
            foreground: "#042b8a",
            background: "#ffffff",
            eyeColor: "#042b8a"
        }
    },
}
