import { Container, IconButton, List, ListItem, ListItemButton, ListSubheader } from "@mui/material"
import { Fade } from "react-reveal"
import { ThemeC } from "../constants/appbase"
import { useContext, useRef } from "react"
import { ArrowCircleUp } from "@mui/icons-material"

export const OverviewPage = () => {
    const { AppColors } = useContext(ThemeC)

    const createRef = useRef(null)
    const projectRef = useRef(null)
    const createDropRef = useRef(null)
    const overviewRef = useRef(null)
    const targetRef = useRef(null)
    const resultsRef = useRef(null)

    // scroll back to top 
    const topRef = useRef(null)
    const scrollBackToTop = () => {
        topRef.current.scrollIntoView()
    }

    const TitleAndScroll = ({title}) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',

            }}>
                <h3 style={{color: '#0f0', textAlign: 'center'}}>{ title }</h3>
                <IconButton onClick={scrollBackToTop}>
                    <ArrowCircleUp style={{color: '#fff'}} />
                </IconButton>
            </div>
        )
    }

    return (
        <Fade>
            <Container style={{marginTop: '-20px', paddingTop: '20px', textAlign: 'center', color: AppColors.DefaultTextColor,
                backgroundColor: AppColors.DefaultBackgroundColor}} ref={topRef}>
                <h2 style={{color: AppColors.SecondaryTextColor}}>Overview</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <p>
                        This page provides a basic tool overview to visualize the proper use cases.  This tool is a project 
                        based application, which allows for creation of "altered" links to help possibly extract useful 
                        targeting information.
                    </p>
                    <List style={{flexGrow: '1'}}>
                        <ListSubheader>Quick Navigation</ListSubheader>
                        <ListItemButton onClick={() => createRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Step 1: Create Project
                        </ListItemButton>
                        <ListItemButton onClick={() => projectRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Step 2: Project Page
                        </ListItemButton>
                        <ListItemButton onClick={() => createDropRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Step 3: Create Drop
                        </ListItemButton>
                        <ListItemButton onClick={() => targetRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Step 4: Send to Target
                        </ListItemButton>
                        <ListItemButton onClick={() => resultsRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Step 5: View Results
                        </ListItemButton>
                        <ListItemButton onClick={() => overviewRef.current.scrollIntoView()} style={{borderBottom: '1px solid #333'}}>
                            Extra: Operational Walkthrough
                        </ListItemButton>
                    </List>
                </div>
                
                <div>
                    <List>
                        <hr />
                        <ListItem>
                            <div ref={createRef} style={{paddingTop: '150px'}}>

                                <TitleAndScroll title="Step 1: Create Project" />
                                <p>Select the "Create Project" button on the dashboard</p>
                                <p>Add in a Title & optional description to identify project</p>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/demo/createproject.png'} alt="create project" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                            </div>
                        </ListItem>
                        <hr />
                        <ListItem>
                            <div ref={projectRef} style={{paddingTop: '150px'}}>
                                <TitleAndScroll title="Step 2: Project Page" />
                                <p>
                                    Select the Project recently created.  Email notifications are sent on every result received if enabled.
                                    Email notifications can be disabled by pressing the "Active" icon, and additional email addresses can  
                                    be added for result notifications.
                                </p>
                                <p></p>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/demo/projectpage.png'} alt="project page" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                            </div>
                        </ListItem>
                        <hr />
                        <ListItem>
                            <div ref={createDropRef} style={{paddingTop: '150px'}}>
                                <TitleAndScroll title="Step 3: Create Drop" />
                                <p>
                                    Create a drop for the Target identified.  Add an identifier for the "Target". Select one of the provisioned 
                                    domain names to utilize for the drop.  This will be the URL sent to the target to obfuscate the tools redirect URL.  Next, 
                                    select the permissions requesting from target device.  "Force Permissions" will return a blank page if permissions are 
                                    denied.
                                </p>
                                <p></p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap:"wrap"
                                }}>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/images/demo/createdrop.png'} alt="create drop" 
                                            style={{
                                                maxWidth: '100%'
                                            }}/>
                                    </div>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/images/demo/droppermissions.png'} alt="drop permissions" 
                                            style={{
                                                maxWidth: '100%'
                                            }}/>
                                    </div>
                                </div>
                                <div>
                                    <p>
                                        Final drop options include "One Hit" which will disable to drop when an initial hit is received.  This 
                                        can be utilized for one touch disable operations.  The minor option can be selected to add an identifier 
                                        of an operation involving a minor.
                                    </p>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap:"wrap"
                                    }}>
                                        <img src={process.env.PUBLIC_URL + '/images/demo/onehit.png'} alt="one hit disable" 
                                            style={{
                                                maxWidth: '100%'
                                            }}/>
                                    </div>
                                </div>
                                <div>
                                    <p>
                                        Multiple drops can be created per project.  The results will align to the name of the drop created.  Each drop includes 
                                        a link that can be sent to the target, or a QR code generated redirecting to the target link.
                                    </p>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap:"wrap"
                                    }}>
                                        <img src={process.env.PUBLIC_URL + '/images/demo/multipledrops.png'} alt="multiple drops" 
                                            style={{
                                                maxWidth: '100%'
                                            }}/>
                                    </div>
                                </div>
                            </div> 
                        </ListItem>
                        <hr />
                        <ListItem>
                            <div ref={targetRef} style={{paddingTop: '150px', textAlign: 'center'}}>
                                <TitleAndScroll title="Step 4: Send to Target" />
                                <p>
                                    At this point after the drop creation, a "Copy Link" and "QR Code" button appears within the drop box.  Either
                                    option points to the crafted redirect URL to extract the information.  Send this "Target Link" to the target and 
                                    wait for results.
                                </p>
                                <p></p>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/demo/qr.png'} alt="Target" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                            </div>
                        </ListItem>
                        <hr />
                        <ListItem>
                            <div ref={resultsRef} style={{paddingTop: '150px', textAlign: 'center'}}>
                                <TitleAndScroll title="Step 5: View Results" />
                                <p>
                                    As results are received, they will be consolidated on the bottom of the project page.
                                    To view the details on any information gathered, click the "View Details" for the individual
                                    result.  This will display a map location (GEO marker if permissions were granted) as well as 
                                    any image captured during the redirect.  
                                </p>
                                <p></p>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/demo/resultlist.png'} alt="Results" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/demo/results.png'} alt="Results" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                            </div>
                        </ListItem>
                        <hr />

                        <div>
                            <div ref={overviewRef} style={{paddingTop: '150px'}}>
                                <div style={{paddingTop: '80px', paddingBottom: '100px'}} >
                                    <TitleAndScroll title="Operational Walkthrough" />
                                    <img src={process.env.PUBLIC_URL + '/images/demo/keyhole.gif'} alt="walkthrough" 
                                        style={{
                                            maxWidth: '100%'
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </List>
                </div>
            </Container>
        </Fade>

    )
}


