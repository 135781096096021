import { Button } from "@mui/material";
import { createContext, useState } from "react";

// App Variables are utilized below for application
export const APPNAME = "KEYHOLE";
export const ADMINAPI = "keyholeadmin";
export const APPMOBILEPATH = "keyhole";

export const APPSTARTICON = "/images/key.png";

export const ThemeC = createContext()

export const CustomThemeProvider = ({children}) => {
    const [ currentState, setState ] = useState(DefaultTheme)

    const changeState = () => {
        if (currentState === DefaultTheme) {
            setState(WhiteTheme)
        } else if (currentState === WhiteTheme) {
            setState(BlueTheme)
        } else if (currentState === BlueTheme) {
            setState(RedTheme)
        } else {
            setState(DefaultTheme)
        }
    }

    const renderThemeButton = () => {
        if (currentState === DefaultTheme) {
            // white button 
            return (<Button 
                onClick={() => { changeState()} }
                style={{
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    boxShadow: '0 0 2px 2px #ccc',
                    borderRadius: '10px',
                    transition: '0.5s',
                    color: "#000"
                }}>Change Theme</Button>    
            )
        } else if (currentState === WhiteTheme) {
            // blue button 
            return (
                <Button 
                onClick={() => { changeState()} }
                style={{
                    cursor: "pointer",
                    backgroundColor: "#0c2955",
                    borderRadius: '10px',
                    boxShadow: '0 0 2px 2px #333',
                    transition: '0.5s',
                    color: "#fff"
                }}>Change Theme</Button>                
            )  
        } else if (currentState === BlueTheme) {
            // blue button 
            return (
                <Button 
                onClick={() => { changeState()} }
                style={{
                    cursor: "pointer",
                    backgroundColor: "#200303",
                    borderRadius: '10px',
                    boxShadow: '0 0 2px 2px #333',
                    transition: '0.5s',
                    color: "#fff"
                }}>Change Theme</Button>           
            )  
        }
        return (
            <Button 
            onClick={() => { changeState()} }
            style={{
                cursor: "pointer",
                backgroundColor: "#000",
                boxShadow: '0 0 2px 2px #ccc',
                borderRadius: '10px',
                transition: '0.5s',
                color: "#fff"
            }}>Change Theme</Button>    
        )  
    }

    const ButtonStyle = {
        style: {
            backgroundColor: currentState.DefaultBackgroundColor,
            color: currentState.DefaultTextColor,
            boxShadow: `0 0 2px 2px ${currentState.DefaultBoxShadowColor}`,
        }
    }

    const TextFieldStyle = {
        style: {
            margin: '5px',
            backgroundColor: currentState.DefaultBackgroundColor,
            color: currentState.DefaultTextColor,
            border: `1px solid ${currentState.DefaultBorderColor}`
        },
        InputLabelProps: {               
            style:{
                backgroundColor: currentState.DefaultBackgroundColor,
                color: currentState.DefaultTextColor,
            }
        },
        inputProps: {
            style:{
                backgroundColor: currentState.DefaultBackgroundColor,
                color: currentState.DefaultTextColor,
            }
        }
    }


    const RenderColorButton = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '30px', marginBottom: '50px', marginRight: '20px'}}>
            { renderThemeButton() }
        </div>
        )
    }

    const state = {
        ButtonStyle: ButtonStyle,
        TextFieldStyle: TextFieldStyle,
        AppColors: currentState,
        RenderColorButton: RenderColorButton,
    }
    return <ThemeC.Provider value={state}><div style={{ backgroundColor: state.AppColors.SecondaryBackgroundColor}}>{children}</div></ThemeC.Provider>
}


export const DefaultTheme = {
    // black & white 
    DefaultBackgroundColor: "#000",
    DefaultTextColor: "#eee",
    DefaultBorderColor: '#333',
    DefaultBoxShadowColor: '#333',

    // secondary additives
    SecondaryBackgroundColor: "#111",
    SecondaryTextColor: '#eee',
    SecondaryLightText: "#bbb",
    SecondaryBorderColor: '#333',
    SecondaryBoxShadowColor: '#333',

    // button colors
    ButtonSuccessColor: '#0b0',
    ButtonDeleteColor: '#f00',
    ButtonDisabledColor: '#333',
    ButtonWarningColor: '#ff0',

    AppIcon: "/images/key.png",
}

export const WhiteTheme = {
    // black & white 
    DefaultBackgroundColor: "#fff",
    DefaultTextColor: "#000",
    DefaultBorderColor: '#ccc',
    DefaultBoxShadowColor: '#999',

    // secondary additives
    SecondaryBackgroundColor: "#eee",
    SecondaryTextColor: '#000',
    SecondaryLightText: "#111",
    SecondaryBorderColor: '#888',
    SecondaryBoxShadowColor: '#aaa',

    // button colors
    ButtonSuccessColor: '#090',
    ButtonDeleteColor: '#f00',
    ButtonDisabledColor: '#333',
    ButtonWarningColor: "#93730b",

    AppIcon: "/images/keyi.png",
}



export const BlueTheme = {
    // black & white 
    DefaultBackgroundColor: "#0c2955",
    DefaultTextColor: "#fff",
    DefaultBorderColor: '#999',
    DefaultBoxShadowColor: '#444',

    // secondary additives
    SecondaryBackgroundColor: "#001",
    SecondaryTextColor: '#fff',
    SecondaryLightText: "#bbb",
    SecondaryBorderColor: '#555',
    SecondaryBoxShadowColor: '#666',

    // button colors
    ButtonSuccessColor: '#0f0',
    ButtonDeleteColor: '#f00',
    ButtonDisabledColor: '#555',
    ButtonWarningColor: "#93730b",

    AppIcon: "/images/key.png",
}


export const RedTheme = {
    // black & white 
    DefaultBackgroundColor: "#111",
    DefaultTextColor: "#fff",
    DefaultBorderColor: '#999',
    DefaultBoxShadowColor: '#444',

    // secondary additives
    SecondaryBackgroundColor: "#200303",
    SecondaryTextColor: '#fff',
    SecondaryLightText: "#bbb",
    SecondaryBorderColor: '#555',
    SecondaryBoxShadowColor: '#666',

    // button colors
    ButtonSuccessColor: '#0f0',
    ButtonDeleteColor: '#f00',
    ButtonDisabledColor: '#555',
    ButtonWarningColor: "#babb00",

    AppIcon: "/images/key.png",
}

