import { Capacitor } from "@capacitor/core"
import { LocalNotifications } from "@capacitor/local-notifications"
import { Snackbar } from "@mui/material"
import { createContext, useState } from "react"
import { APPNAME } from "../constants/appbase"

export const MessageC = createContext()

export const MsgProv = ({children}) => {
    const [ open, setOpen ] = useState(false)
    const [ msg, setMessage ] = useState('')
    const [ shadowColor, setShadowColor ] = useState()

    const handleClose = () => {
        setOpen(false)
    }

    const showMessage = (msg, color, mobileNotif = false) => {
        // attempt capacitor push notification 
        if (mobileNotif && Capacitor.isNativePlatform()) {
            const genId = Math.random() * 100
            const noti = {
                title: APPNAME,
                body: msg,
                id: genId,
                largeIcon: "icon",
                smallIcon: "icon_small",
            }
            LocalNotifications.schedule({
                notifications: [noti],
            })
        } 
    
        setOpen(false)
        setMessage(msg)
        if (color !== undefined) {
            setShadowColor(color)
        }
        setOpen(true)
    }

    const state = {
        showMessage,
    }

    return (
        <MessageC.Provider value={state}>
            {children}
            <Snackbar 
                ContentProps={{
                    style: {
                        border: `1px inset ${shadowColor}a`,
                    }
                }}
                open={open}
                message={msg}
                onClose={handleClose}
                autoHideDuration={6000} />
        </MessageC.Provider>
    )
}