import { Button, Container, Grid, List, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { AdsClick, ChildCare } from "@mui/icons-material"
import { Fade } from "react-reveal"
import { Link } from "react-router-dom"
import { ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"

export const CatchHits = ({activeProject}) => {

    const [ tempActive, setTempActive ] = useState([])
    const { AppColors } = useContext(ThemeC)
    
    // new
    const dataP = useContext(DataP)

    useEffect(() => {
        if (dataP.state.hits === undefined || activeProject === undefined) return

        // sort results helper
        const sortResults = (filteredResults) => {
            return filteredResults.sort(function(a,b){return parseInt(a.catch_time)-parseInt(b.catch_time);});
        }

        const updateResults = async () => {
            var filteredResults = dataP.state.hits.filter(item => item.projectID === activeProject.id)
            return sortResults(filteredResults) 
        }

        updateResults().then(filtered => {
            setTempActive(filtered)
        })
    }, [dataP.state.hits, activeProject])

    if (tempActive.length === 0 || tempActive === undefined) return (
        <div style={{marginBottom: '150px'}}>
            <Fade>
            <Container>
                <div style={{
                    minHeight: '200px', 
                    display: 'flex', 
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor,
                    justifyContent: 'center', 
                    alignItems: 'center',
                    borderRadius: '5px', 
                    boxShadow: `0px 0px 3px 3px ${AppColors.DefaultBoxShadowColor}`,
                    marginTop: '20px',
                }}>
                Results will display here
                </div>
            </Container>
            </Fade>
        </div>
    )

    return (
        <div>
            <Fade>
            <Container style={{marginTop: '40px', minHeight: '250px', marginBottom: '150px'}}>
                <div style={{minHeight: '300px', 
                    boxShadow: `0px 0px 3px 3px ${AppColors.DefaultBoxShadowColor}`,
                    backgroundColor: AppColors.DefaultBackgroundColor, 
                    color: AppColors.DefaultTextColor, padding: '10px', borderRadius: '5px'}}>
                    <h3>Results</h3>
                    <div>
                        <List>
                            { tempActive.map(item => (
                                <DropInfo item={item} key={item.id} />
                            ))}
                        </List>
                    </div>
                </div>
            </Container>
            </Fade>
        </div>
    )
}


const DropInfo = ({item}) => {
    const { AppColors } = useContext(ThemeC)
    const dataP = useContext(DataP)

    if (item.catchdropID === undefined) return

    return (
        <div style={{display: 'flex', paddingBottom: '10px', marginBottom: '10px', flexDirection: 'column'}} className="lightBottomBord">
            <Grid container style={{paddingTop: '10px', justifyContent: 'center', alignItems: 'center'}}>
                <Grid item xs={12} md={2}>
                <div style={{display: 'flex', justifyContent: 'space-between', flexGrow: '1'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title="Hit Received">
                            <AdsClick style={{color: AppColors.ButtonWarningColor}} />
                        </Tooltip>
                        { dataP.getDropMinorStatus(item.catchdropID) && (
                            <Tooltip title="Drop is minor related">
                                <ChildCare style={{color: '#fff', marginLeft: '10px', marginRight: '10px'}} />
                            </Tooltip>
                        )}
                        <div style={{padding: '5px'}}>
                            { dataP.getDropName(item.catchdropID) }
                        </div>
                    </div>
                </div>
                </Grid>
                <Grid item xs={12} md={3} style={{padding: '5px'}}>
                    <div style={{overflowX: 'hidden'}}>
                        <b>URL:</b> { dataP.getDropRedirectURL(item.catchdropID) }
                    </div>
                </Grid>
                <Grid item xs={12} md={2} style={{padding: '5px'}}>
                    <b>IP:</b> { item.catch_ip }
                </Grid>
                <Grid item xs={12} md={3} style={{padding: '5px'}}>
                    <Tooltip title={`${new Date(item.createdAt)}`} >
                        <div>
                        <b>Time:</b> { item.createdAt }
                        </div>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={2} style={{padding: '5px'}}>
                    <Link to={`/result/${item.catchdropID}/${item.id}`} className="destyle" style={{display: 'flex', flexGrow: '1'}}>
                        <Button style={{
                                flexGrow: '1',
                                border: '1px solid #333', 
                                color: AppColors.DefaultTextColor,
                                backgroundColor: AppColors.DefaultBackgroundColor, 
                                justifyContent: 'center',
                            }}>View Details</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}