import { Button, Chip, Container,  Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { Fade } from "react-reveal"
import { BackToTools } from "../pagecomponents/BackToTools"
import { MessageC } from "../../contexts/MessageContext"
import { ADMINAPI, ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"


export const PortScannerPage = () => {
    const [ loading, setLoading ] = useState(false)
    const [ ip, setIp ] = useState("")
    const [ results, setResults ] = useState([])

    const [ extraPortsParsed, setExtraPortsParsed ] = useState([])
    const [ extraPorts, setExtraPorts ] = useState("")
    const [ portOpen, setPortOpen ] = useState(false)

    const handlePortClose = () => {
        setPortOpen(false)
    }

    const dataP = useContext(DataP)
    const msgC = useContext(MessageC)
    const { AppColors } = useContext(ThemeC)

    const onSubmit = () => {
        setLoading(true)

        // test permissioned
        try {
            dataP.RunPermissionedAPI(ADMINAPI, "/tools/port", {target: ip, ports: extraPortsParsed.join(",")}).then(res => {
                setResults(res.data)
                setLoading(false)
            })
        } catch (err) {
            msgC.showMessage(`${err}`, "#f00")
            setLoading(false)
        }
    }

    const checkKey = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit()
        }
    }

    const onChangeIp = (e) => {
        setIp(e.target.value)
    }

    const handleExtraPorts = (e) => {
        setExtraPorts(e.target.value)
    }

    // parse and add extra ports 
    const handleAddExtra = () => {
        if (extraPorts === "") {
            setExtraPortsParsed([])
        } else {
            const allExtra = extraPorts.split(",")
            setExtraPortsParsed(allExtra)
        }
        handlePortClose()
    }

    const onKeyExtra = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            handleAddExtra()
        }
    }

    return (
        <div>
            <Fade>
                <Container style={{color: AppColors.SecondaryTextColor}}>
                    <BackToTools />

                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems:"center"}}>
                        <h2 className="lightBottomBord" style={{
                            marginTop: '0px', 
                            flexGrow: '1', 
                            minHeight: '80px', 
                            display: 'flex', 
                            alignItems: 'flex-end',
                        }}>Port Scanner</h2>
                        <div style={{minHeight: '80px'}}>
                            { loading && (
                                <div className="lds-ripple"><div></div><div></div></div>
                            )}
                        </div>
                    </div>

                    <div style={{padding: '10px 10px 20px', textAlign: 'center'}}>
                        This tool will scan the Top 1000 TCP port list
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <TextField value={ip} onChange={onChangeIp} 
                            style={{
                                backgroundColor: AppColors.DefaultBackgroundColor,
                                color: AppColors.DefaultTextColor,
                                border: `1px solid ${AppColors.DefaultBorderColor}`,
                                marginRight: '10px',
                            }}
                            InputLabelProps={{               
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}
                            inputProps={{
                                style:{
                                    backgroundColor: AppColors.DefaultBackgroundColor,
                                    color: AppColors.DefaultTextColor,
                                }
                            }}

                            fullWidth label="IP Address or Domain" 
                            onKeyDown={checkKey}
                            variant="filled" />
                        <Button onClick={onSubmit} variant="contained">Submit</Button>
                    </div>


                    <div>
                        <div style={{padding: '20px 10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                            <Button variant="contained" onClick={() => setPortOpen(true)} color="success">Add Ports</Button>
                            { extraPortsParsed.length > 0 && (
                                <div style={{padding: '10px'}}>
                                    <b>Extra Ports: </b>{ extraPortsParsed.join(",") }
                                </div>
                            )}
                        </div>
                        <Dialog
                            open={portOpen}
                            onClose={handlePortClose}>
                            <DialogTitle>Add Ports</DialogTitle>
                            <DialogContent>
                                <div>
                                    <div style={{textAlign: 'center', padding: '10px'}}>
                                        Add ports to the default Top 1000 list.  Comma separated values are supported below.
                                    </div>
                                    <TextField 
                                        value={extraPorts} 
                                        onChange={handleExtraPorts} 
                                        fullWidth 
                                        onKeyDown={onKeyExtra}/>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handlePortClose} variant="outlined">Close</Button>
                                <Button onClick={handleAddExtra} variant="outlined" color="success">Add</Button>
                            </DialogActions>
                        </Dialog>
                    </div>


                    <div  style={{paddingTop: '20px'}}>
                        <h3 className="lightBottomBord">Results</h3>
                        <div style={{backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor, minHeight: '100px', borderRadius: '10px', 
                            boxShadow: `0 0 2px 3px ${AppColors.DefaultBoxShadowColor}`}}>
                            { /* Results Here */ }
                            <List style={{display: 'flex', flexDirection: 'column'}}>
                                { results.map((item, i) => (
                                    <ListItem style={{borderBottom: `1px solid ${AppColors.DefaultBoxShadowColor}`}} key={i}>
                                        <ListItemText style={{textTransform: 'capitalize'}}>
                                            <Chip style={{marginRight: '10px'}} color="success" label={item.State} />
                                            TCP Port: { item.Port }

                                            <div style={{padding: '10px', overflowX: 'scroll'}}>
                                                { item.Banner && (<><span style={{color: '#0f0'}}>Banner:</span> {item.Banner}</>)}
                                            </div>

                                        </ListItemText>
                                    </ListItem>

                                ))}
                            </List>
                        </div>
                    </div>
                </Container>
            </Fade>
        </div>
    )
}