import { useContext, useEffect, useState } from "react"
//import { DataC } from "../contexts/DataContext"
import { Container, Grid, TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { ThemeC } from "../../constants/appbase";
import { DataP } from "../../contexts/DataProv";


export const ShowProjects = () => {
    const [ tempP, setP ] = useState([])
    const [ filter, setFilter ] = useState('')
    const { AppColors } = useContext(ThemeC)

    // new data provider
    const newDataC = useContext(DataP)

    useEffect(() => {
        if (newDataC.state.projects !== undefined && newDataC.state.projects.length > 0) {
            setP(newDataC.state.projects)
        }
    }, [newDataC.state.projects])

    useEffect(() => {
        // blank filter 
        if (newDataC.state.projects === undefined) return
        if (filter === '' || newDataC.state.projects.length === 0) {
            setP(newDataC.state.projects)
            return 
        }

        // else look for projs by ids 
        const allProjs = newDataC.state.projects.filter(
            item => item.project_name.toLowerCase().includes(filter.toLowerCase()) || 
            item.project_name.toLowerCase().includes(filter.toLowerCase())
        )
        setP(allProjs)
    }, [filter, newDataC.state.projects])

    return (
        <div style={{paddingTop: '30px'}}>
            <Container>
                <Tooltip title="Filter Projects by Title">
                <TextField value={filter} onChange={(e) => {setFilter(e.target.value)}} label="Filter" fullWidth 
                    variant="filled" style={{
                        backgroundColor: AppColors.DefaultBackgroundColor, 
                        color: AppColors.DefaultTextColor,
                    }} 
                    inputProps={{style: {
                        boxShadow: `0 0 2px 1px ${AppColors.DefaultBoxShadowColor}`,
                        backgroundColor: AppColors.DefaultBackgroundColor, 
                        color: AppColors.DefaultTextColor,
                    }}} 
                    InputLabelProps={{
                        style: {color: AppColors.DefaultTextColor}
                    }} />
                </Tooltip>
                <div style={{borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`, marginTop: '30px', minHeight: '20px'}}>
                    <h3 style={{color: AppColors.SecondaryTextColor}}>Projects</h3>
                </div>
            </Container>
            
            <Grid container spacing={2} padding={2} style={{paddingBottom: '100px', justifyContent: 'center'}}>
                { tempP.map((proj) => (
                    <Grid item xs={12} md={4} key={proj.id}>
                        <Fade>
                        <Link to={"/projects/" + proj.id}  className="destyle">
                            <Tooltip title={`Select Project ${proj.project_name}`}>
                            <div style={{
                                padding: '20px', margin: '10px', 
                                color: AppColors.DefaultTextColor,
                                backgroundColor: AppColors.DefaultBackgroundColor, 
                                borderRadius: '5px', boxShadow: `0 0 5px 2px ${AppColors.DefaultBorderColor}`}}
                                className="gridHover">
                                <div><b>Name:</b> { proj.project_name }</div>
                                <div><b>Description:</b> { proj.project_description }</div>
                            </div>
                            </Tooltip>
                        </Link>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}