import { Capacitor } from "@capacitor/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { createContext } from "react";
import { APPMOBILEPATH } from "../constants/appbase";

export const ExportC = createContext();

const labelLine = [
    "Project Name",
    "Hit Time", 
    "Hit IP", 
    "Hit User Agent",
    "Hit URL",
    "Minor",
    "Drop ID",
    "Info",
]

export const ExportProv = ({children}) => {
    const getAllDropsAndHits = async (project) => {
        const allDrops = await project.CatchDrops.values
        const allLines = await allDrops.map(async drop => {
            const allDropHits = await drop.CatchHits.values 
            return await allDropHits.map(item => {
                return [
                    project.project_name,
                    item.catch_time, 
                    item.catch_ip, 
                    item.catch_user_agent, 
                    drop.redirect_url,
                    drop.minor,
                    drop.generated_id,
                    ...item.catch_info.flat(),
                ]
            })
        })

        let res = await Promise.all(allLines)
        return res.flat()
    }

    const generateFullCSV = async (allProjects) => {
        //console.log(allProjects)
        const res = await Promise.all(allProjects.map(item => getAllDropsAndHits(item)))
        const combined = [labelLine, ...res.flat()]
        exportToCsv(`${APPMOBILEPATH}-reports-all`, combined)
    }

    const generateDataLine = async (project) => {
        const res = await getAllDropsAndHits(project)
        const combined = [labelLine, ...res]
        exportToCsv(`${APPMOBILEPATH}-report`, combined)
    }

    const generateLogCSV = async (logName, dataToParse) => {
        var allRows = [];
        for (let i = 0; i < dataToParse.length; i++) {
            const curItem = dataToParse[i];
            const headerVals = Object.keys(curItem.headers).map(item => {
                return `${item} - ${curItem.headers[item]}`
            })
            const newRow = [curItem.method, curItem.path, curItem.time, curItem.streamName, ...headerVals];
            allRows.push(newRow)
        }
        exportToCsv(logName, allRows)
    }

    const state = {
        generateDataLine,
        generateFullCSV,
        generateLogCSV,
    }

    return (
        <ExportC.Provider value={state}>{ children }</ExportC.Provider>
    )
}

function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    // cap / cordova write
    if (Capacitor.isNativePlatform()) {
        writeReport(csvFile, `${filename}.csv`)
    }
}

// attempt to download in filesystem via capacitor
const writeReport = async (dataToWrite, filename) => {
    await Filesystem.writeFile({
        path: `${APPMOBILEPATH}/${filename}`,
        data: dataToWrite,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
    })
}



    