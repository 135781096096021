import { DeleteForever, EmailOutlined, NotificationsActive, NotificationsOff } from "@mui/icons-material"
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, List, ListItem,ListItemIcon, ListItemText,TextField,Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MessageC } from "../../contexts/MessageContext"
import { Fade } from "react-reveal"
import { ThemeC } from "../../constants/appbase"
import { DataP } from "../../contexts/DataProv"

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const EmailNotis = ({activeProject}) => {
    const [ emailDialogOpen, setEmailDialogOpen ] = useState(false)
    const [ emailText, setEmailText ] = useState('')
    const [ errorText, setErrorText ] = useState('')
    const [ notificationsEnabled, setNotificationsEnabled ] = useState(undefined)
    const [ allEmails, setAllEmails ] = useState([])
    const { AppColors } = useContext(ThemeC)

    const handleEmailDiagClose = () => {
        setEmailDialogOpen(false)
    }
    const handleEmailDiagOpen = () => {
        setEmailDialogOpen(true)
    }

    // New
    const msgC = useContext(MessageC)
    const newData = useContext(DataP)

    useEffect(() => {
        if (activeProject.project_emails === undefined) return
        setAllEmails(activeProject.project_emails)
    }, [activeProject.project_emails])

    useEffect(() => {
        // set if notifications are enabled or disabled
        if (activeProject.notifications_enabled === undefined) return
        setNotificationsEnabled(activeProject.notifications_enabled)
    }, [activeProject.notifications_enabled])

    const deleteEmailFromProject = async (email) => {
        await newData.removeEmailFromProject(activeProject.id, email).then(() => {
            msgC.showMessage("Removed email", "#0f0")
        })
    }

    const addEmailToProject = async () => {
        if (!validateEmail(emailText)) {
            console.log("incorrect email address")
            setErrorText("Incorrect email address format")
            return 
        } else if (activeProject.project_emails.includes(emailText)) {
            setErrorText("Email already added")
            return 
        }

        await newData.addEmailToProject(activeProject.id, emailText).then(() => {
            msgC.showMessage("Added email", "#0f0")
            setEmailDialogOpen(false)
            setErrorText('')
            setEmailText('')
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            addEmailToProject()
        }
    }

    // update notification settings on project 
    const updateNotifications = async () => {
        if (activeProject === undefined) return
        const res = await newData.updateProjectNotifications(activeProject.id, !notificationsEnabled)

        // show message
        if (res.notifications_enabled) {
            msgC.showMessage("Email notifications enabled", "#0f0")
        } else {
            msgC.showMessage("Email notifications disabled", "#f00")
        }
    }


    if (activeProject === undefined) return <></>

    return (
        <Container style={{minHeight: '106px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <div style={{marginRight: '10px', minWidth: '42px'}}>
                    { notificationsEnabled !== undefined && (
                        <Fade>
                            <div>
                            { notificationsEnabled ? (
                                <Tooltip title="Enabled. Click to disable.">
                                    <IconButton style={{color: AppColors.ButtonSuccessColor, border: `1px solid ${AppColors.ButtonSuccessColor}`, backgroundColor: AppColors.DefaultBackgroundColor, transition: '0.5s'}} onClick={() => updateNotifications()} >
                                        <NotificationsActive />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Disabled. Click to enable." onClick={() => updateNotifications()}>
                                    <IconButton style={{color: AppColors.ButtonDeleteColor, border: `1px solid ${AppColors.ButtonDeleteColor}`, backgroundColor: AppColors.DefaultBackgroundColor, transition: '0.5s'}}>
                                        <NotificationsOff />
                                    </IconButton>
                                </Tooltip>
                            )}
                            </div>
                        </Fade>
                    )}
                </div>
                <div style={{borderBottom: `1px solid ${AppColors.SecondaryBorderColor}`, flexGrow: '1', marginRight: '10px', flexDirection: 'row'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{fontWeight: 'bold', fontSize: '1.2rem', color: AppColors.SecondaryTextColor}}>Email Notifications</div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Tooltip title="Add Email For Notifications">
                        <Button 
                            onClick={handleEmailDiagOpen}
                            style={{backgroundColor: AppColors.DefaultBackgroundColor, color: AppColors.DefaultTextColor, 
                                boxShadow: `0 0 2px 2px ${AppColors.ButtonSuccessColor}`, minWidth: '120px'}}
                            >Add Email</Button>
                    </Tooltip>
                </div>
            </div>
            <List>
                { allEmails.map((email) => (
                    <ListItem key={email}>
                        { email !== newData.state.user.attributes.email ? (
                            <ListItemIcon style={{cursor: 'pointer'}}>
                                <Tooltip title="Delete email from project">
                                    <DeleteForever style={{color: '#f00'}} onClick={() => deleteEmailFromProject(email)} />
                                </Tooltip>
                            </ListItemIcon>
                        ) : (
                            <ListItemIcon>
                                <EmailOutlined style={{color: AppColors.SecondaryTextColor}} />
                            </ListItemIcon>
                        )}
                        <ListItemText style={{color: AppColors.SecondaryTextColor}}>
                            <Fade>{ email }</Fade>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>


            <Dialog
                open={emailDialogOpen}
                onClose={handleEmailDiagClose}
                >
                <DialogTitle>Add Email</DialogTitle>
                <DialogContent>
                    <div style={{textAlign: 'center'}}>
                        <p>Adding an email to the project will send all hit report notifications realtime as they are received.</p>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                        <TextField 
                            label="Email Address"
                            onKeyDown={handleKeyPress}
                            value={emailText} onChange={(e) => {setEmailText(e.target.value)}} fullWidth />
                        <FormHelperText>Email addresses added will receive ALL project notifications</FormHelperText>
                    </div>
                    <div style={{textAlign: 'center', color: '#f00', paddingTop: '5px'}}>
                            { errorText !== '' ? (
                                errorText
                            ) : <></>}
                    </div>
                </DialogContent>
                <DialogActions>
                        <Button 
                            onClick={addEmailToProject}
                            color="success" 
                            variant="contained" 
                            style={{minWidth: '100px'}}
                            >Add</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}


