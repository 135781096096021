// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ToolResult, Tool, GeneratedID, Domains, Project, CatchHit, CatchDrop } = initSchema(schema);

export {
  ToolResult,
  Tool,
  GeneratedID,
  Domains,
  Project,
  CatchHit,
  CatchDrop
};